import { rgba } from 'polished'
import * as PropTypes from 'prop-types'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import NoSearchResultsWidget from './NoSearchResultsWidget.jsx'
import SearchResultsList from './SearchResultsList.js'
import SearchSuggestionsList from './SearchSuggestionsList.js'

const Wrapper = styled.div`
  padding-bottom: ${({ hasBottomPadding }) => hasBottomPadding ? '40px' : '0'};
  position: relative;
`

const SearchResultsTitle = styled.li`
  padding: 4px 14px 4px 62px;
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ themeColors }) => rgba(themeColors.primary, 0.05)};
  border-top: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB;
  font-weight: 500;
  font-size: 14px;
`
const StyledUL = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`
const SearchResults = withTheme(({
  className,
  isDesktop,
  defaultSearch,
  searchSuggestions,
  placesNearby,
  locations,
  onSuggestionClicked,
  onChooseMapLocationClicked,
  onPoiClicked,
  placesNearbyTitle,
  placesTitle,
  theme,
  hasBottomPadding,
  handleEscapeKey,
  T
}) => {
  const DefaultSearch = () =>
    <StyledUL role='group' aria-label={T('homeView:Suggestions')} id='SuggestedLocations'>
      <SearchResultsList
        className={className}
        isDefault
        results={defaultSearch}
        onResultClicked={onPoiClicked}
        onChooseMapLocationClicked={onChooseMapLocationClicked}
        isDesktop={isDesktop}
        handleEscapeKey={handleEscapeKey}
        T={T}
      />
    </StyledUL>

  const Suggestions = () => {
    if (!searchSuggestions || !searchSuggestions.length) return null
    return (<SearchSuggestionsList
      suggestions={searchSuggestions}
      onSuggestionClicked={onSuggestionClicked}
      handleEscapeKey={handleEscapeKey}
      T={T}
    />)
  }

  const Nearby = () => SuggestedLocations(placesNearbyTitle, placesNearby)
  const Locations = () => SuggestedLocations(placesTitle, locations)

  const SuggestedLocations = (title, pois) => {
    if (!pois || !pois.length) return null
    return (
      <StyledUL role="group" aria-label={title || 'Suggested locations'} id='SuggestedLocations'>
        {!!title && (
          <SearchResultsTitle themeColors={theme.colors} role='none'>
            {title}
          </SearchResultsTitle>
        )}
        <SearchResultsList
          results={pois}
          onResultClicked={onPoiClicked}
          handleEscapeKey={handleEscapeKey}
          T={T}
        />
      </StyledUL>
    )
  }

  if (!searchSuggestions && !placesNearby && !locations && defaultSearch)
    return <div data-cy={'DefaultSearchResults'}><DefaultSearch /></div>

  if ((!searchSuggestions || !searchSuggestions.length) &&
    (!placesNearby || !placesNearby.length) &&
    (!locations || !locations.length))
    return <NoSearchResultsWidget isDesktop={isDesktop} T={T} />

  return (
    <Wrapper data-cy={'SearchResults'} hasBottomPadding={hasBottomPadding} className={className}>
      <Suggestions />
      <Nearby />
      <Locations />
    </Wrapper>
  )
})

SearchResults.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  defaultSearch: PropTypes.array,
  searchSuggestions: PropTypes.array,
  placesNearby: PropTypes.array,
  placesNearbyTitle: PropTypes.string,
  locations: PropTypes.array,
  onSuggestionClicked: PropTypes.func,
  onChooseMapLocationClicked: PropTypes.func,
  onPoiClicked: PropTypes.func,
  handleEscapeKey: PropTypes.func
}

SearchResults.defaultProps = {
  isDesktop: true,
  placesNearbyTitle: '',
  onSuggestionClicked: () => { },
  onChooseMapLocationClicked: () => { },
  onPoiClicked: () => { },
  handleEscapeKey: () => {}
}

export default SearchResults
