import { pipe, values, chain, prop, filter, pluck } from 'ramda'

import { getFlexSearchInstance } from './utils.js'

export default function createSearchTypeahead (pois, poiSearch, lang) {
  const suggestedKeywordsSearch = createSuggestedKeywordsSearch(pois, lang)

  function queryPois (query, limit) {
    const matches = poiSearch({ query, limit })
    const withSuggestions = poiSearch({ query, suggest: true, limit })
    const matchedIds = matches.map(poi => poi.poiId)
    const filteredSuggestions = withSuggestions.filter(poi => matchedIds.indexOf(poi.poiId) === -1)
    return matches.concat(filteredSuggestions)
  }

  const query = (query, limit) => {
    const suggestedKeywords = suggestedKeywordsSearch.search({ query, limit })
    const queryLengthUnderLimit = query.length < 3
    const shouldQueryPois = !queryLengthUnderLimit && suggestedKeywords.length
    const poisLimit = limit - suggestedKeywords.length
    const pois = shouldQueryPois ? queryPois(query, poisLimit) : []
    return { keywords: suggestedKeywords, pois }
  }

  const addKeyword = (keyword) => {
    suggestedKeywordsSearch.add(keyword)
  }

  return { query, addKeyword }
}

function createSuggestedKeywordsSearch (pois, lang) {
  const categories = extractParentCategories(pois)
  const poisKeywords = pipe(
    values,
    chain(prop('keywords')),
    filter(prop('isUserSearchable')),
    pluck('name')
  )(pois)
  const allPotentialKeywords = [...categories, ...poisKeywords]
  const keywords = Array.from(new Set([...allPotentialKeywords]))
  const index = getFlexSearchInstance({ lang, type: 'typeahead' })

  keywords.forEach((keyword, i) => index.add(i, keyword))

  const search = (queryParams) => {
    const ids = index.search(queryParams)
    return ids.map(index => keywords[index])
  }
  const add = (newKeyword) => {
    keywords.push(newKeyword)
    index.add(keywords.length - 1, newKeyword)
  }
  return { search, add }
}

function extractParentCategories (pois) {
  return Object.values(pois)
    .map(poi => poi.category)
    .map(fullCategory => fullCategory.split('.'))
    .map(subcategories => subcategories[0])
}
