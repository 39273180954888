import React from 'react'
import styled from 'styled-components'

import ExpandableContent from '../../../../src/ui/expandableContent.jsx'
import { $ } from '../../../../src/utils/dom.js'

import PoiHeader from './components/PoiHeader.jsx'
import { getSectionWidget } from './poiSections.js'

const InnerLayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`

const ScrollableContent = styled.div`
  padding: 0 12px;
  overflow-y: ${props => props.canScroll ? 'auto' : 'hidden'};
  position: relative;
`

const PoiHeaderStyled = styled(PoiHeader)`
  flex: 1 0 auto;
  padding: 2px 12px 0 12px;
`

export class InnerLayout extends React.Component {
  componentDidMount () {
    this.props.notifyAboutChange()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.poi !== this.props.poi) {
      this.props.notifyAboutChange()
    }
  }

  shouldComponentUpdate (nextProps) {
    if (nextProps.poi !== this.props.poi || nextProps.layout !== this.props.layout || nextProps.canScroll !== this.props.canScroll) {
      return true
    }
    return false
  }

  // stop propagation so it won't fire expandableContent resizing
  stopPropagation = (e) => {
    this.props.isOpen && e.stopPropagation()
  }

  render () {
    const { poi, venueCategory, venueTimezone, layout, className, T, lang, canScroll, structures, submitAnalyticsEvent, submitAnalyticsEventWithAppInsights, multipointRouting, searchInputsLength, hidePoiFeedback, venueId } = this.props

    return (
      <InnerLayoutStyled data-cy='PoiViewWidgetMobile' className={className}>
        <PoiHeaderStyled poi={poi} T={T} />
        <ScrollableContent
          canScroll={canScroll}
          onMouseMove={this.stopPropagation}
          onTouchMove={this.stopPropagation}
        >
          {layout.map(sectionName => {
            const $Component = getSectionWidget(sectionName)
            return $Component
              ? <$Component key={sectionName} poi={poi} T={T} venueCategory={venueCategory} venueTimezone={venueTimezone} structures={structures} lang={lang} submitAnalyticsEvent={submitAnalyticsEvent} submitAnalyticsEventWithAppInsights={submitAnalyticsEventWithAppInsights} multipointRouting={multipointRouting} searchInputsLength={searchInputsLength} hidePoiFeedback={hidePoiFeedback} venueId={venueId} mobile={true} />
              : null
          })
          }
        </ScrollableContent>
      </InnerLayoutStyled>
    )
  }
}

const NavigationViewMobile = ({ bus, id, ...rest }) => {
  return <ExpandableContent
    id={id}
    initialHeight={ ($('.atrius-map')?.clientHeight / 2) + 'px' }
    maxHeight={ ($('.atrius-map')?.clientHeight) + 'px' }
    bus={bus}
    hasInnerScrollingElement={true}
    T={rest.T}
    poi={rest.poi}
  >
    <InnerLayout {...rest} />
  </ExpandableContent>
}

export default NavigationViewMobile
