import React from 'react'
import { debounce } from 'throttle-debounce'

import { StateObjMultiple } from '../../../../src/utils/stateObj.js'

import { Header } from './Header.jsx'
import HeaderMobile from './HeaderMobile.jsx'
import { initRecentSearches } from './initRecentSearches.js'

export const SEARCH_VIEW_HEADER_ID = 'SearchResultsHeader'
export const HEADER_VIEW_ID = 'headerOnlineHome'
const SEARCH_VIEW_CATEGORY_LIST_ID = 'SearchResultsCategoryList'
const DEBOUNCE_TIME = 300

function create (app, config) {
  const defaultState = {
    term: '',
    showMap: false
  }

  let initialTerm = ''
  let customActionName = null
  let customActionCategory = null
  const customActionSVGId = null

  const dlp = config.deepLinkProps
  if (dlp) {
    if (dlp.search)
      initialTerm = dlp.search
    if (dlp.customActionName && (dlp.customActionCategory || dlp.customActionSearchTerm)) {
      customActionName = dlp.customActionName
      customActionCategory = dlp.customActionCategory
      initialTerm = dlp.customActionCategory || dlp.customActionSearchTerm
    }
  }

  const widgetState = new StateObjMultiple({
    ...defaultState,
    term: initialTerm,
    customActionCategory: customActionCategory,
    customActionName: customActionName,
    customActionSVGId: customActionSVGId,
    logoUrl: null,
    venueName: null,
    isSearchConfirmed: dlp && Boolean(dlp.isSearchConfirmed || dlp.customActionCategory || dlp.customActionSearchTerm)
  })

  function init () {
    showHeader({ term: widgetState.getState().term, isSearchConfirmed: widgetState.getState().isSearchConfirmed, referrer: 'prog' })

    app.bus.get('theme/getVenueLogo')
      .then(logoUrl => widgetState.update({ logoUrl }))

    const addIcon = (path, name, type, rel = 'icon') => {
      const link = document.createElement('link')
      link.type = type
      link.rel = rel
      link.href = path + `${name}`
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    app.bus.get('venueData/getContentUrl', { type: 'spritesheet-svg' })
      .then(path => {
        // For info, see https://github.com/audreyr/favicon-cheat-sheet
        addIcon(path, `favicon.svg`, 'image/xml+svg') // For browsers that support SVG
        addIcon(path, `favicon.png`, 'image/png') // For most other desktop browsers
        addIcon(path, `favicon-152.png`, 'image/png', 'apple-touch-icon-precomposed') // Touch icon for iOS 2.0+ and Android 2.1+
      })

    app.bus.get('venueData/getVenueName').then((venueName) => {
      widgetState.update({ venueName })
    })
    initRecentSearches(app.bus)
  }

  widgetState.addCallback(state => {
    const dlState = { id: 'online/headerOnline' }
    if (state.term) {
      dlState.search = state.term
      dlState.isSearchConfirmed = state.isSearchConfirmed
    }
    if (state.customActionName && state.customActionCategory) {
      dlState.customActionName = state.customActionName
      dlState.customActionCategory = state.customActionCategory
    }
    app.bus.send('deepLinking/notifyState', dlState)
  })

  app.bus.monitor('poiDetails/showPoi', () => widgetState.update({ term: '' }))
  app.bus.monitor('headerOnline/updateSearchConfirmed', ({ isSearchConfirmed }) => widgetState.update({ isSearchConfirmed }))
  app.bus.monitor('search/customAction', (customActionObject) => {
    widgetState.update({
      customActionName: customActionObject.name,
      customActionCategory: customActionObject.category || customActionObject.searchTerm,
      customActionSVGId: customActionObject.svgId
    })
  })

  const T = app.gt()

  app.bus.send('layers/register', {
    id: SEARCH_VIEW_HEADER_ID,
    widget: () => <HeaderMobile
      widgetState={widgetState}
      handleInputChange={handleInputChange}
      searchPlaceholder={config.searchPlaceholder || T('headerOnline:Search _name_', { name: app.config.name })}
      onBackButtonClicked={onBackButtonClicked}
      handleKeyPress={mobileKeyPress}
      T={T}
    />,
    layoutId: 'headerOnline'
  })

  const mobileKeyPress = event => {
    handleKeyPress(event)
    onFocus()
  }

  function onBackButtonClicked () {
    widgetState.update({ customActionName: null, customActionCategory: null, customActionSVGId: null })
    app.bus.send('headerOnline/show')
  }

  app.bus.send('layers/register', {
    id: HEADER_VIEW_ID,
    widget: () => <Header
      isDesktop={app.env.isDesktop()}
      menuOptions={app.menuOptions}
      clientName={app.config.name}
      searchPlaceholder={config.searchPlaceholder || T('headerOnline:Search _name_', { name: app.config.name })}
      logoLinkUrl={app.config.logoLinkUrl}
      widgetState={widgetState}
      onSearchTermChange={handleInputChange}
      onFocus={onFocus}
      handleToggleSearchButtonClick={handleToggleSearchButtonClick}
      handleOpenDirectionsSearchButtonClick={handleOpenDirectionsSearchButtonClick}
      handleKeyPress={handleKeyPress}
      title={config.title || app.config.name}
      onUserMenuButtonClick={handleUserMenuButtonClick}
      T={T}
      onBackButtonClicked={onBackButtonClicked}
    />,
    layoutId: 'headerOnline'
  })

  function onFocus () {
    if (!widgetState.getState().term)
      app.bus.send('search/recentSearches', { limit: 3 })
  }

  function handleUserMenuButtonClick () {
    app.bus.send('userMenu/toggle')
  }

  function handleToggleSearchButtonClick () {
    app.bus.send('layers/hide', { id: HEADER_VIEW_ID })
    app.bus.send('searchResultsView/show')
  }

  function handleOpenDirectionsSearchButtonClick () {
    app.bus.send('navigation/show', { reset: true, referrer: 'directionsButton' })
    widgetState.update({ term: '' })
  }

  const setConfirmedSearch = term => {
    widgetState.update({ isSearchConfirmed: true })
    app.bus.send('homeview/performConfirmedSearch', { term, searchMethod: 'typing', referrer: 'searchInput' })
    registerSearchChange(term, false)
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      const { term } = widgetState.getState()
      if (term.startsWith('debug:')) {
        const debugFunc = term.substring(6)
        if (app.debug[debugFunc])
          app.debug[debugFunc]()
      }
      setConfirmedSearch(term)
    } else {
      widgetState.update({ isSearchConfirmed: false })
    }
  }

  function handleInputChange (term) {
    widgetState.update({ term })
    searchDebounced(term, false)
  }

  const searchDebounced = debounce(DEBOUNCE_TIME, search)

  function search (term, isSearchConfirmed) {
    app.bus.send('homeview/performSearch', { term, searchMethod: 'typing', referrer: 'searchInput' })
    registerSearchChange(term, isSearchConfirmed)
  }

  // const refreshSearch = () => {
  //   const { term, isSearchConfirmed } = widgetState.getState()
  //   if (isSearchConfirmed)
  //     setConfirmedSearch(term)
  //   else
  //     search(term)
  // }

  // if there have been changes to the POI database, we should re-trigger search...
  // see https://gitlab.com/locuslabs/webengine/webengine/-/issues/506
  // app.bus.monitor('poi/setDynamicData', () => {
  //   const { term } = widgetState.getState()
  //   if (term)
  //     setTimeout(refreshSearch, 0)
  // })

  app.bus.on('headerOnline/updateTerm', ({ term, isSearchConfirmed } = {}) => {
    widgetState.update({ term, isSearchConfirmed })
    registerSearchChange(term, isSearchConfirmed)
  })

  function registerSearchChange (term, isSearchConfirmed) {
    app.bus.send('history/register', {
      viewId: 'headerOnline',
      event: 'headerOnline/show',
      params: { term, isSearchConfirmed }
    })
  }

  app.bus.on('deepLinking/setState', async ({ id, search: term, isSearchConfirmed }) => {
    if (id === 'online/headerOnline') {
      await reset()
      widgetState.update({ term, isSearchConfirmed })
      app.bus.send('homeview/performConfirmedSearch', { term, searchMethod: 'prog', referrer: 'setState' })
      registerSearchChange(term, isSearchConfirmed)
    }
  })

  app.bus.on('searchResultsView/show', ({ resetState = true } = {}) => {
    if (resetState)
      widgetState.update({ ...defaultState })

    if (app.env.isMobile())
      app.bus.send('layers/show', { id: SEARCH_VIEW_HEADER_ID })
  })

  async function reset () {
    return await app.bus.send('headerOnline/show')
  }

  app.bus.on('app/reset', reset)

  app.bus.on('headerOnline/show', showHeader)

  function showHeader ({ term, isSearchConfirmed = false, searchMethod, referrer } = {}) {
    widgetState.update({ ...defaultState, isSearchConfirmed })
    app.bus.send('layers/show', { id: HEADER_VIEW_ID })
    showContentIfNeeded(term, isSearchConfirmed, searchMethod, referrer)
    app.bus.send('history/clean')
    app.bus.send('history/register', { viewId: 'headerOnline', event: 'headerOnline/show', params: { term, isSearchConfirmed } })
    app.bus.send('map/cleanMap')
  }

  function showContentIfNeeded (term, isSearchConfirmed, searchMethod, referrer) {
    showHeaderIfNeeded(term)
    if (term) {
      app.bus.send('headerOnline/updateTerm', { term, isSearchConfirmed })
      app.bus.send('homeview/performSearch', { term, isSearchConfirmed, searchMethod, referrer })
    } else
      showCategoryListIfDesktop()
  }

  function showHeaderIfNeeded (term) {
    if (term) {
      showHeaderWidget()
      widgetState.update({ term })
    }
  }

  function showCategoryListIfDesktop () {
    if (app.env.isDesktop())
      app.bus.send('layers/show', { id: SEARCH_VIEW_CATEGORY_LIST_ID })
    else
      app.bus.send('layers/hideWithLayoutId', 'content')
  }

  // todo change
  app.bus.on('searchResults/searchForTag', showHeaderWidget)

  function showHeaderWidget () {
    const headerViewId = app.env.isMobile()
      ? SEARCH_VIEW_HEADER_ID
      : HEADER_VIEW_ID
    app.bus.send('layers/show', { id: headerViewId })
  }

  const runTest = async (initialState, testRoutine) => {
    widgetState.update({ ...initialState })
    await testRoutine()
    return widgetState.getState()
  }

  return {
    init,
    runTest,
    internal: {
      onFocus,
      handleInputChange,
      handleUserMenuButtonClick
    },
    getWidgetState: () => widgetState.getState()
  }
}

export {
  create
}
