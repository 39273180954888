import { path, identity, prop } from 'ramda'

/**
 * @typedef Bounds
 * @property {number} s
 * @property {number} e
 * @property {number} n
 * @property {number} w
 */
export {
  findBoundsOfPoisAndKiosk,
  findBoundsOfPoiAndKiosk,
  findBoundsOfPois,
  findBoundsOfSegments,
  findBoundsOfCoordinates,
  findBoundsOfWaypoints
}

function findBoundsOfPoiAndKiosk (poi, kiosk) {
  const latitudes = [poi.position.latitude, kiosk.lat]
  const longitudes = [poi.position.longitude, kiosk.lng]
  return findBounds(latitudes, longitudes)
}

function findBoundsOfPoisAndKiosk (pois, kiosk) {
  const { lat, lng } = kiosk
  const [latitudes, longitudes] = getPoisCoordinates(pois)
  return findBounds([...latitudes, lat], [...longitudes, lng])
}

function findBoundsOfPois (pois) {
  const [latitudes, longitudes] = getPoisCoordinates(pois)
  return findBounds(latitudes, longitudes)
}

function getPoisCoordinates (pois) {
  const latitudes = pois.map(path(['position', 'latitude'])).filter(identity)
  const longitudes = pois.map(path(['position', 'longitude'])).filter(identity)
  return [latitudes, longitudes]
}

function findBoundsOfWaypoints (waypoints) {
  const latitudes = waypoints.map(path(['position', 'lat'])).filter(identity)
  const longitudes = waypoints.map(path(['position', 'lng'])).filter(identity)
  return findBounds(latitudes, longitudes)
}

function findBoundsOfCoordinates (coordinates) {
  const latitudes = coordinates.map(prop(0)).filter(identity)
  const longitudes = coordinates.map(prop(1)).filter(identity)
  return findBounds(latitudes, longitudes)
}

function findBoundsOfSegments (navline) {
  const coordinates = navline.flatMap(getNavlineCoordinates)
  const latitudes = coordinates.map(prop(1))
  const longitudes = coordinates.map(prop(0))
  return findBounds(latitudes, longitudes)
}

function getNavlineCoordinates (navlineSegment) {
  const coordinates = navlineSegment.coordinates
  if (coordinates[0].constructor === Array) return coordinates
  else return [coordinates]
}

function findBounds (latitudes, longitudes) {
  const n = Math.max(...latitudes)
  const s = Math.min(...latitudes)
  const e = Math.max(...longitudes)
  const w = Math.min(...longitudes)
  return { n, s, e, w }
}
