import { path } from 'ramda'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import { Icon, ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'
import { SecurityLabel } from '../../../../../src/ui/labels/SecurityLabel.jsx'
import ToolTipComponent from '../../../../../src/ui/tooltips/ToolTipComponent.jsx'
import { getPoiIconId } from '../../../../../src/utils/poi.js'
import { getThemeColor } from '../../../../../src/utils/styled-component-tools.js'
import { handleKeyboardSelect } from '../../../../utils/keyboardUtils.js'
import HandlerContext from '../handlerContext.js'

const Wrapper = styled.div`
  .-mobile & {
    margin-bottom: 10px;
  }
`

const TopItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const CloseButtonWrapper = styled.div`
  align-self: flex-start;
`

const CloseButton = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;

   &:hover > .tooltip {
    top: 31px;
    display: block;
    position: absolute;
  }
`

const PoiIcon = styled.div`
  margin-right: 14px;
  width: 36px;
  height: 36px;

  &:lang(ar) { margin-right: 0px; margin-left: 14px; }
`

const PoiTitle = styled.h1`
  && {
    ${({ theme }) => theme.fontSize('20px')};
    margin: 0px;
    color: ${getThemeColor('primaryText', '#333333')};
    font-weight: 500;
    line-height: 26px;
    flex: 1 1 auto;
  }
`

const SecurityWaitLabelStyled = styled(SecurityLabel)`
  margin: 2px 0 0 52px;
`

const PoiHeader = withTheme(({ theme, poi, T, className }) => {
  if (!poi) return null

  const dynamicData = path(['dynamicData', 'security'], poi)

  const statusText = dynamicData?.isTemporarilyClosed
    ? T('ui:Closed')
    : T('ui:_xx_ minute wait', { count: dynamicData?.queueTime })

  return <HandlerContext.Consumer>
    {ctx => <Wrapper className={className}>
      <TopItemsWrapper>
        <PoiIcon>
          <Icon id={getPoiIconId(poi)} width={36} height={36} />
        </PoiIcon>
        <PoiTitle data-cy='PoiHeader'>
          {poi.name}
        </PoiTitle>
        <CloseButtonWrapper>
          <ToolTipComponent tooltipText={T('poiView:Close')} arrowPosition="left">
            <CloseButton
              onClick={ctx.onCloseClicked}
              data-cy='closeButton'
              aria-label={T('poiView:Close')}
              role="button"
              tabIndex='0'
              onKeyDown={handleKeyboardSelect(() => ctx.onCloseClicked())}>
              <ColoredIcon id="close" width={32} height={32} fillColor={theme.colors.secondary} />
            </CloseButton>
          </ToolTipComponent>
        </CloseButtonWrapper>
      </TopItemsWrapper>
      <SecurityWaitLabelStyled poi={poi} T={T} statusText={statusText} />
    </Wrapper>}
  </HandlerContext.Consumer>
})

export default PoiHeader
