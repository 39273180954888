/*
  This is an HOC used to wrap components that you wish to "trap focus" such that tabbing and alt-tabbing
  repeatedly will remain within the component. It will also grab focus when first mounted, and focus
  on either a specified element (3rd argument) or by default the 2nd focusable descendent. (skipping the usual close widget).
*/
import React, { useEffect, useRef } from 'react'

import { listenToShiftTab, listenToTab } from '../../../plugins/utils/keyboardUtils.js'

const RETRY_LIMIT = 5

// wrap any function to delay any invocations by a set amount.
const tryLater = (fn, ms = 100) => (...args) => new Promise((resolve, reject) => {
  setTimeout(() => fn(...args).then(resolve, reject), ms)
})

const findFocusableDescendents = async (id, tryNum = 1) => {
  // https://hidde.blog/using-javascript-to-trap-focus-in-an-element/
  const element = document.getElementById(id)
  if (!element)
    if (tryNum <= RETRY_LIMIT) // make this a bit forgiving of when internal renders happen
      return tryLater(findFocusableDescendents, 200)(id, tryNum + 1)
    else
      throw Error(`No element found with id: '${id}'`)
  return Array.from(element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'))
}

async function limitTabFocusToDescendentsOf (id) {
  const descendents = await findFocusableDescendents(id)
  const firstDescendent = descendents[0]
  const lastDescendent = descendents[descendents.length - 1]

  const removeTabListen = listenToTab(onKeyPressMoveFocusFromTo(lastDescendent, firstDescendent), id)
  const removeShiftTabListen = listenToShiftTab(onKeyPressMoveFocusFromTo(firstDescendent, lastDescendent), id)

  if (descendents.length)
    if (!descendents.includes(document.activeElement))
      (descendents.length > 1
        ? descendents[1]
        : descendents[0]).focus()

  return {
    removeTabListen,
    removeShiftTabListen
  }
}

const onKeyPressMoveFocusFromTo = (targetElem, nextElem) => (e) => {
  if (document.activeElement === targetElem) {
    nextElem.focus()
    e.preventDefault()
  }
}

const FocusTrap = (WrappedComponent, id, focusFirst) => (props) => {
  useEffect(() => {
    let removeEventsOb = null
    setTimeout(() => {
      limitTabFocusToDescendentsOf(id)
        .then(ret => { removeEventsOb = ret })
    }, 0)
    return () => {
      removeEventsOb?.removeTabListen()
      removeEventsOb?.removeShiftTabListen()
    }
  })

  useEffect(() => {
    if (focusFirst) {
      // let the micro-task queue finish out in case the component needs to
      // be shown by the layer manager.
      setTimeout(() => document.querySelector(focusFirst)?.focus(), 0)
    }
  }, [])

  return <WrappedComponent {...props}/>
}

export default FocusTrap
