import { zipObj } from 'ramda'

const identity = x => x

// returns a dup object with a single property stripped from it
const strip = name => ob => { const ob2 = { ...ob }; delete ob2[name]; return ob2 }

// converts an imported module into a object literal
const mod2Ob = mod => strip('default')(mod)

// takes a function and a list of strings, and returns an object pairing the strings with the results of the function on the string
// const map2Obj = fn => ar => { const ob = { }; ar.forEach(name => ob[name] = fn(name)); return ob }

function collect (promArray) {
  return Promise.all(promArray
    .map(p => p
      .then(identity).catch(e => { }))) // convert rejections into empty objects
    .then(res => res
      .map(mod2Ob) // convert modules into object literals
    )
}

async function create (app, config) {
  // Loads the language bundles
  function loadLang (lang) {
    return loadLangResourceBundles(lang)
      .then(bundles => {
        for (const ns in bundles) {
          app.i18n().addResourceBundle(lang, ns, bundles[ns])
        }
      })
  }

  // call to change languages
  function setLanguage (lang) {
    if (typeof document !== 'undefined') {
      if (lang === 'ar') // expand this with other RTL languages (Hebrew, Syriac, and Thaana)
        document.documentElement.setAttribute('dir', 'rtl')
      else
        document.documentElement.setAttribute('dir', 'ltr')

      document.documentElement.setAttribute('lang', lang)
    }

    return loadLang(lang)
      .then(() => app.i18n().changeLanguage(lang))
  }

  /**
   * Loads all namespaces for a given language
   * @param {string} lang 2 letter language code
   */
  async function loadLangResourceBundles (lang) {
    const additionalNamespaces = ['ui', 'fullscreenOnlyLayout']

    const pnames = Object.keys(app.config.plugins) // Get namespaces names from plugin names
      .map(pname => pname.indexOf('/') >= 0 // only use final path element for plugin name
        ? pname.match(/.*\/(.*)/)[1]
        : pname)

    const allNamespaces = pnames.concat(additionalNamespaces)
    const transProms = allNamespaces.map(namespace =>
      (typeof window !== 'undefined'
        ? import(`../../../src/i18n/${lang}/${namespace}.json`)
        : import(`../../../src/i18n/${lang}/${namespace}.json`, { assert: { type: 'json' }}))
      .then(m => m.default).catch(err => { })) // report errors , but recover
    return collect(transProms)
      .then(res => zipObj(allNamespaces)(res)) // match up plugin names and additional namespaces with associated translations
  }

  app.bus.on('i18n/setUILang', ({ lang }) =>
    setLanguage(lang) // note: this does not load the approp. map!
      .then(() => app.bus.send('i18n/langChanged')))

  const lang = app.i18n().language
  if (lang !== 'en')
    await loadLang('en') // always load English, as its our fallback
  await setLanguage(lang)

  return {
    init: () => { }
  }
}

export {
  create
}
