import React from 'react'

import { StateObj } from '../../../src/utils/stateObj.js'

import LevelIndicator from './levelIndicator.jsx'

function create (app) {
  const init = () => {}
  const widgetState = new StateObj({
    showLevelIndicator: true,
    isInitialLoad: true,
    structure: null,
    floor: null
  })

  app.bus.send('layers/register', {
    id: 'levelIndicator',
    widget: () => (
      <LevelIndicator
        hideLevelIndicator={() => setShowLevelIndicator(false)}
        widgetState={widgetState}
        T={app.gt()}
        isMobile={app.env.isMobile()}
      />
    ),
    isOverlay: true,
    layoutId: 'floatingBanner',
    show: true
  })

  const setShowLevelIndicator = (showLevelIndicator = true) => {
    widgetState.update({ showLevelIndicator })
    if (!showLevelIndicator) widgetState.update({ isInitialLoad: false })
  }

  app.bus.on('map/floorChanged', ({ structure, floor }) => {
    if (structure) {
      widgetState.update({ structure, floor, showLevelIndicator: true })
    }
  })

  app.bus.on('poiDetails/showPoi', () => setShowLevelIndicator())

  return {
    init,
    getWidgetState: () => widgetState.getState()
  }
}

export { create }
