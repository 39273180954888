import * as R from 'ramda'
import Zousan from 'zousan'

import { shallowEqual } from './funcs.js'

const noOpCallback = () => { }

export class StateObj {
  constructor (initial = {}) {
    this.init(initial)
    this.callback = noOpCallback
  }

  init = (initial) => {
    this.state = { ...initial }
  }

  setCallback = (fn) => {
    this.callback = fn
    return () => this.unsetCallback()
  }

  unsetCallback = () => { this.callback = noOpCallback }

  update = (vals) => {
    this.state = { ...this.state, ...vals }
    this.callback(this.getState())
  }

  getState = () => ({ ...this.state })
}

export class StateObjMultiple {
  constructor (initial = {}, traceFlag) {
    this.state = { ...initial }
    this.traceFlag = traceFlag
    this.lastState = { }
    this.callbacks = new Set()
    this.callbacksGivenImmutableUpdates = new Set()
  }

  addCallback = fn => {
    this.callbacks.add(fn)
    return () => this.rmCallback(fn)
  }

  addCallbackImmutableUpdates = (callbackParams) => {
    this.callbacksGivenImmutableUpdates.add(callbackParams)
  }

  rmCallback = fn => this.callbacks.delete(fn)

  getChanges = vals => {
    const changes = { }
    for (const key in vals)
      if (vals[key] !== this.state[key])
        changes[key] = { from: this.state[key], to: vals[key] }
    const { stack } = Error('stack')
    return { changes, stack }
  }

  update = (vals, opts = {}) => {
    if (this.traceFlag)
      console.log('STATE: ', this.getChanges(vals))

    const newState = { ...this.state, ...vals }
    this.state = newState
    Zousan.soon(this.doCallbacks(R.clone(newState), opts))
    return this.state
  }

  doCallbacks = (newState, opts) => () => {
    if (shallowEqual(this.lastState, this.state))
      return
    this.callbacks.forEach(callback => callback(this.state)) // maintaining reference directly to the state for current callbacks
    this.callbacksGivenImmutableUpdates.forEach(({ fn }) => {
      fn(newState, opts)
    }) // passes reference from immutable object not the this.state reference
    this.lastState = this.state
  }

  getState = () => ({ ...this.state })
}
