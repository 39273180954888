import React from 'react'
import styled from 'styled-components'

import { handleKeyboardSelect } from '../../../plugins/utils/keyboardUtils.js'
import { ColoredIcon } from '../icons/Icon.jsx'
import ToolTipComponent from '../tooltips/ToolTipComponent.jsx'

const CloseButtonWrapper = styled.button`
  cursor: pointer;
  border: none;
  background-color: ${({ theme }) => theme.colors.background};
  && {
    margin: 0 0 0 auto;
  }
`

const BackButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.secondary};
`

const StyledTooltip = styled(ToolTipComponent)`
  margin-left: auto
`

const CloseButton = ({ onClick, color, tooltipText }) => {
  return <StyledTooltip tooltipText={tooltipText} arrowPosition="left">
    <CloseButtonWrapper
      type='button'
      onClick={onClick}
      aria-label={tooltipText}
      tabIndex={'0'}
      onKeyDown={handleKeyboardSelect(() => {
        onClick()
        document.getElementById('tooltip').setAttribute('style', 'display: none;')
      })}>
      <ColoredIcon id="close" width={32} height={32} fillColor={color} />
    </CloseButtonWrapper>
  </StyledTooltip>
}

const BackButton = ({ onClick, color, text }) => {
  return <BackButtonWrapper onClick={onClick}>
    <ColoredIcon id="arrow-left" width={32} height={32} fillColor={color} />
    {text}
  </BackButtonWrapper>
}

export { CloseButton, BackButton }
