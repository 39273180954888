import queryString from 'query-string'
import * as R from 'ramda'
import { debounce } from 'throttle-debounce'

import { b64DecodeUnicode, b64EncodeUnicode } from '../../../src/utils/funcs.js'

const ONE_SECOND = 1000 // 1000ms = 1 second

/*
  Track latest state update here. Format is pluginId -> pluginState, e.g.:
    latestState = {
        "mapRenderer": { <mapRendererState> }
        "mapLevelSelector": { <mapLevelSelectorState> }
      }
*/
let latestState = {}

/**
 * Given a state object (in the form of latestState above), update the URL to reflect it.
 * @param  {Object} state app state in form of latestState above
 * @parm   {any} allows preservation of lldebug URL parameter (which becomes app.config.debug)
 */
function updateURLActual (state, lldebug, lastPoppedStateHash, lastPushedStateHash) {
  const encodedState = base64Encode(state)
  const stateHash = simpleHash(encodedState)
  if (stateHash !== lastPoppedStateHash && stateHash !== lastPushedStateHash) {
    const url = location.origin + location.pathname + '?' + getStateParms(state, lldebug)
    history.pushState(null, document.title, url)
  }
}

const updateURL = debounce(ONE_SECOND, updateURLActual) // wait until the user rests for 1 second before storing URL in history

// "State Parms" are parameters that are persisted explicitly in the URL
function getStateParms (state, lldebug) {
  const queryParms = R.pipe(
    R.propOr({}, 'venueDataLoader'),
    R.pick(['vid', 'stage', 'lang'])
  )(state)

  if (lldebug !== undefined)
    queryParms.lldebug = lldebug

  queryParms.s = base64Encode(clean(state)) // plugin state as array of objects, Base64-encoded

  return queryString.stringify(queryParms)
}

// strip an object of all its nullish (null or undefined) values
const removeNullishValues = ob => {
  const ret = { }
  for (const key of Object.keys(ob))
    if (ob[key] != null)
      ret[key] = ob[key]
  return ret
}

const clean = state => {
// This section removes the 'id' property from each value (as its in the key), and
  // removes vid, state, lang from venueDataLoader values
  const cleanState = R.map(o => {
    const { id, ...o2 } = o
    if (id === 'venueDataLoader') {
      delete o2.vid
      delete o2.lang
      delete o2.stage
    }
    return o2
  }, state)

  // this section removes any properties that are null or undefined
  for (const pname of Object.keys(cleanState))
    cleanState[pname] = removeNullishValues(cleanState[pname])

  // And finally we remove any empty plugin property objects
  const nonEmptyState = R.filter(o => !R.isEmpty(o), cleanState)

  return nonEmptyState
}

// consider normalizing JSON using something like this: https://github.com/substack/json-stable-stringify
const base64Encode = state => b64EncodeUnicode(JSON.stringify(state))

/**
 * Given a state string (JSON representation of latestState above) this will
 * update the app state (via calling all plugins with their state)
 * @param  {Object} app App object created in App.js
 * @param  {string} stateString stringified JSON object representing state
 */
function setStateFromStateString (app, stateString) {
  latestState = JSON.parse(stateString)
  // Iterate through each state object, which is a plugin id and an
  Object.keys(latestState).forEach(id => {
    const so = { id, ...latestState[id] }
    // call plugin if it was recently visible
    // if (latestState[LAYER_MANAGER_ID].includes(so.id)) // Glenn: hmmm, I think we need to set state in non-visible plugins as well..
    app.bus.send('deepLinking/setState', so)
  })
}

// This is a simple, *insecure* hash that's short, fast, and cryptographically insecure.
// See https://gist.github.com/jlevy/c246006675becc446360a798e2b2d781
const simpleHash = str => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash &= hash // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36)
}

function create (app, config) {
  const init = async () => { }

  let lastPoppedStateHash = null
  const lastPushedStateHash = null

  app.bus.on('deepLinking/getLatestState', () => latestState)

  app.bus.on('deepLinking/getEncodedState', () => base64Encode(clean(latestState)))
  app.bus.on('deepLinking/getStateParms', () => getStateParms(latestState))

  // app.bus.on('app/reset', () => {
  //   app.bus.send('deepLinking/notifyState', { id: 'directionsSearchView', widgetState: undefined })
  //   app.bus.send('deepLinking/notifyState', { id: 'getDirectionsFromTo', widgetState: undefined })
  //   app.bus.send('deepLinking/notifyState', { id: 'mapLevelSelectorOnline', selectedBuildingId: null, selectedLevel: null })
  //   app.bus.send('deepLinking/notifyState', { id: 'poiView', poiId: null })
  // })

  app.bus.on('deepLinking/setEncodedState', ({ state }) => setStateFromStateString(app, b64DecodeUnicode(state)))

  app.bus.on('deepLinking/notifyState', async (state) => {
    if (!state.id)
      throw Error('State notified in deepLinking/notifyState with no \'id\' field present: ' + JSON.stringify(state))
    latestState[state.id] = state

    // save info about currently visible layers
    // latestState[LAYER_MANAGER_ID] = (await app.bus.get('layers/getVisibleLayersIds'))
    const gdft = latestState['online/getDirectionsFromTo']
    if (gdft && gdft.navTo)
      delete latestState['online/poiView']

    if (config.trackURL)
      updateURL(latestState, JSON.stringify(app.config.debug), lastPoppedStateHash, lastPushedStateHash)
  })

  if (typeof window !== 'undefined')
    window.addEventListener('popstate', e => {
      const parsed = queryString.parse(window.location.search)
      if (parsed.s) {
        const state = b64DecodeUnicode(parsed.s)
        lastPoppedStateHash = simpleHash(parsed.s)
        setStateFromStateString(app, state)
      }
    })

  return {
    init
  }
}

export {
  create
}
