import React, { useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'

import { handleKeyboardSelect, listenToEscape } from '../../../plugins/utils/keyboardUtils.js'
import useComponentFocused from '../../utils/hooks/useComponentFocused.js'

import FocusTrap from './FocusTrap.jsx'
import { CloseButton, BackButton } from './ModalControls.jsx'

const BackgroundMask = styled.div`
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: grid;
  position: absolute;
  left: 0;
  top: 0;
`

const ModalBox = styled.div`
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.background};
  justify-self: center;
  align-self: center;
  position: relative;
  height: auto;

  display: flex;
  flex-direction: column;
  overflow: auto;

  .-desktop & {
    min-height: 300px;
    width: auto;
    min-width: 300px;
    max-width: 74%;
    ${({ theme }) => theme.borderRadius(4)};
    ${({ theme }) => theme.boxShadow()};
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 auto;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 14px 4px;
  width: 100%;

  .-desktop & {
    position: relative;
    padding: 24px 16px 4px;
  }
`

const ModalContent = styled.div`
  padding: 0 12px 16px;

  .-desktop & {
    padding: 0 32px 32px;
  }
`

const withModalWindow = (WrappedComponent, focusFirst) => {
  const ModalWindow = (props) => {
    const { ref, isComponentFocused, setIsComponentFocused } = useComponentFocused(true)
    const [backButtonAction, setBackButtonAction] = useState(null)
    const [onModalDismissAction, setOnModalDismissAction] = useState(null)

    const { theme, T } = props

    useEffect(() => {
      // if the outside click was detected hide the modal and call side effects
      if (!isComponentFocused && typeof onModalDismissAction === 'function') { // check if onModalDismissAction was initialized before calling it
        onModalDismissAction()
      }
    }, [isComponentFocused])

    const setBackButton = (clickAction) => {
      setBackButtonAction(() => clickAction)
    }

    const setModalDismissAction = (dismissAction) => {
      setOnModalDismissAction(() => dismissAction)
    }

    useEffect(() => {
      listenToEscape((e) => {
        setIsComponentFocused(false)
        e.preventDefault()
      }, 'ModalBox')
    }, [])
    if (!isComponentFocused) return null // do not render modal if it was unfocused

    return <BackgroundMask>
      <ModalBox ref={ref} data-cy='ModalBox' role='dialog' id='ModalBox' aria-modal={true} aria-labelledby='ModalBoxTitle'>
        <ModalHeader>
          {!!backButtonAction && <BackButton onClick={backButtonAction} text={T('ui:Back')} color={theme.colors.secondary} />}
          <CloseButton
            onClick={() => setIsComponentFocused(false)}
            tooltipText={T('ui:Close')}
            aria-label={T('ui:Close')}
            color={theme.colors.secondary}
            tabIndex={'0'}
            onKeyDown={handleKeyboardSelect(() => setIsComponentFocused(false))}/>
        </ModalHeader>
        <ContentWrapper>
          <ModalContent>
            <WrappedComponent {...props} setModalBackButtonAction={setBackButton} setModalDismissAction={setModalDismissAction} />
          </ModalContent>
        </ContentWrapper>
      </ModalBox>
    </BackgroundMask>
  }

  return withTheme(FocusTrap(ModalWindow, 'ModalBox', focusFirst))
}

export { withModalWindow }
