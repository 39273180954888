const jsRandom = () => ({
  nextInt: max => Math.floor(Math.random() * max)
})

const defaultRandom = jsRandom()

/**
 * Returns a random list of length size of numbers from 0 to max-1, which represents a set of
 * those numbers.  i.e. no number will repeat.  (size is forced to not be bigger than max)
 *
 * Can be used to choose a random selection from a list of selections.  Or if
 * size = max, it becomes a random ordering of a List.
 *
 *  NOTE: if the setSize is 0, max will set to 0 and you get an empty List object
 *
 * ForExample:
 *  randomSet(3, 5) = [3, 2, 0]
 *  randomSet(3, 5) = [1, 0, 3]
 *  randomSet(3, 5) = [1, 4, 2]
 *
 * @param size the number of values to return
 * @param max a positive integer
 * @param r an optional Random instance (else one is generated)
 * @return a List of random values from 0 to max-1 with no number repeating
 */
function randomSet (size, max, r) {
  r = r || defaultRandom

  let i

  if (!max)
    max = size

  if (size > max)
    size = max

  const list = []

  if (((max / size) >= 2.0) || (max < 40)) {
    // if the set we are choosing from is considerably bigger than the number we are selecting
    // then let's just grab random values and check to see if they are in our new list yet.
    while (list.length < size) {
      i = r.nextInt(max)
      if (list.indexOf(i) < 0)
        list.push(i)
    }
  } else {
    // if the list is to be nearly same size as what we are choosing from, let's build a source
    // list of the numbers and grab from them one at a time.
    const source = []
    for (i = 0; i < max; i++)
      source.push(i)

    for (i = 0; i < size; i++) {
      const index = r.nextInt(source.length)
      list.push(source[index])
      source.splice(index, 1)
    }
  }

  return list
}

// Pass in a source array and a map array of indices, and this returns a new
// array of the members of the first based on the indices of the second. Useful when
// used along with randomset above.
// i.e.
//  a1 = [ "a", "b", "c", "d", "e", "f" ]
//  a2 = [ 2, 3, 5, 4, 0, 1]
//  a3 = getMappedArray(a1, a2) = [ "c", "d", "f", "e", "a", "b" ]
const getMappedArray = (array, map) => map.map(i => array[i])

// return a randomized copy of the specified array:
//  a1 = [ 3, 4, 5, 6, 7]
//  a2 = randomizeArray(a1) = [ 4, 7, 3, 5, 6]
const randomizeArray = ar => getMappedArray(ar, randomSet(ar.length))

// Randomly pick a number of items from an array and return them in an array
// leaving the original array untouched. If no number is specified, a length
// of 1 is returned.
const arrayPick = (array, num) => {
  num = num || 1
  const map = randomSet(num, array.length)
  return getMappedArray(array, map)
}

export {
  arrayPick,
  randomSet,
  randomizeArray
}
