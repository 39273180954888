import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Icon } from '../../../src/ui/icons/Icon.jsx'

const ANIMATION_DURATION_MS = 500
const HIDE_DELAY_MS = 500

// todo bearing is reversed in mobile, so no bearing * -1 in rotate
const MapResetButton = styled.button`
  padding: 2px;
  background: rgba(0, 0, 0, 0.1);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  transition-duration: ${ANIMATION_DURATION_MS}ms;
  outline: none;
  border: none;
  border-radius: 4px 0 0 4px;

  ${Icon} {
    display: inline-block;
    transform: ${({ bearing }) => `rotate(${bearing}deg)`};
  }
`

const IconWrapper = styled.div`
  background: ${({ theme }) => theme.colors.mapNavBackground};
  border-radius: 100%;
`

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transition: .5s ease-in-out transform;

  &.hiding {
    transform: translateX(-100%);
  }
`

const STATE_HIDING = 'hiding'
const STATE_HIDDEN = 'hidden'
const STATE_VISIBLE = ''

export const CompassView = ({ resetBearingValue, onCompassClick, bearing }) => {
  const [displayState, setDisplayState] = useState(STATE_HIDDEN)
  useEffect(() => {
    let timerTaskId
    if (bearing !== resetBearingValue)
      setDisplayState(STATE_VISIBLE)
    else if (displayState !== STATE_HIDDEN)
      timerTaskId = setTimeout(() => setDisplayState(STATE_HIDING), HIDE_DELAY_MS)
    return function cleanup () {
      clearTimeout(timerTaskId)
    }
  }, [bearing])
  return displayState === STATE_HIDDEN
    ? null
    : <Wrapper className={displayState}>
      <MapResetButton onClick={onCompassClick} bearing={bearing}>
        <IconWrapper>
          <Icon id='compass' width={30} height={30} />
        </IconWrapper>
      </MapResetButton>
    </Wrapper>
}
