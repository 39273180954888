import React, { useEffect, useState } from 'react'

import DirectionsResultView from './DirectionsResultView.js'
import DirectionsResultViewMobile from './DirectionsResultViewMobile.js'

if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
  import('smoothscroll-polyfill')
    .then(mod => mod.default.polyfill())

const DirectionsResult = ({ bus, toggleIsNavigating, onNextStepClick, onPrevStepClick, onStepClickHandler, toggleNavigationHeader, widgetState, isDesktop, Loading, T, multipointRouting, onEndRouteClick, onEditRouteClick }) => {
  const [state, setWidgetState] = useState(widgetState.getState())

  useEffect(() => widgetState.addCallback(setWidgetState))

  const preferredUnits = state.preferredUnits

  const view = isDesktop()
    ? <DirectionsResultView
      {...state}
      isNavigating={true}
      onPrevStepClick={onPrevStepClick}
      onNextStepClick={onNextStepClick}
      onStepClicked={onStepClickHandler}
      currentRouteType={state.currentRouteType}
      preferredUnits={preferredUnits}
      T={T}
      isDesktop={isDesktop()} />
    : <DirectionsResultViewMobile
      {...state}
      bus={bus}
      onPrevStepClick={onPrevStepClick}
      onNextStepClick={onNextStepClick}
      onStepClicked={onStepClickHandler}
      toggleIsNavigating={toggleIsNavigating}
      toggleNavigationHeader={toggleNavigationHeader}
      multipointRouting={multipointRouting}
      onEditRouteClick={onEditRouteClick}
      onEndRouteClick={onEndRouteClick}
      preferredUnits={preferredUnits}
      T={T} />

  return state.hasLoaded ? view : <Loading>{view}</Loading>
}

export default DirectionsResult
