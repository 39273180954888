import * as R from 'ramda'

import { orphanTest } from '../plugins/wayfinder/src/navGraphDebug.js'

import { $, $$, ad } from './utils/dom.js'
import { singleFile } from './utils/funcs.js'

if (typeof window !== 'undefined')
  window.R = R // helps use Rambda in console... probably don't want to do this once we integrate with customer content

// NOTE:
// The exported functions from this module all get placed into the app.debug (accessable from console via _app.debug) and bound to
// the app instance. So the `this` context will contain the app instance when these are run. This allows you to access objects like
// the bus via `this.bus` or configuration via `this.config`
// Update: these are now also available in MoL by searching for `debug:<funcname>` - such as `debug:showIcons`

const showIcons = () => { $('#mapRenderDiv').innerHTML = '<style> div { display: inline-block; text-align: center; border: 1px solid lightblue; }</style>' + $$('svg symbol').map(e => `<div><svg><use xlink:href="#${e.id}"/></svg><br/>${e.id}</div>`).join('') }

function poisByCategory () {
  return this.bus.send('poi/getAll')
    .then(r => r[0])
    .then(p => Object.values(p))
    .then(R.groupBy(o => o.category))
}

const highlightNodes = () => ad({ tag: 'style', html: '* { background-color: rgba(255,0,0,.2); } * * { background-color: rgba(0,255,0,.2); } * * * { background-color: rgba(0,0,255,.2); } * * * * { background-color: rgba(255,0,255,.2); } * * * * * { background-color: rgba(0,255,255,.2); } * * * * * * { background-color: rgba(255,255,0,.2); } * * * * * * * { background-color: rgba(255,0,0,.2); } * * * * * * * * { background-color: rgba(0,255,0,.2); } * * * * * * * * * { background-color: rgba(0,0,255,.2); }' }, $('head'))

function getPoiById (id) {
  return this.bus.send('poi/getById', { id })
    .then(r => r[0])
}

async function showOrphaned () {
  const navGraph = await this.bus.get('wayfinder/_getNavGraph')
  const oob = orphanTest(navGraph._nodes)
  this.bus.send('map/showOrphanedGraphNodes', { orphanedNodes: oob.orphaned })
}

async function showgraph () {
  const navGraph = await this.bus.get('wayfinder/getNavGraphFeatures')
  this.bus.send('map/showNavGraphFeatures', { navGraph })
  monitorDebugFeaturesReset('map/resetNavGraphFeatures', this.bus)
}

async function bounds () {
  const venueCenter = await this.bus.get('venueData/getVenueCenter')
  const { venueRadius, bounds } = await this.bus.get('venueData/getVenueData')
  this.bus.send('map/showVenueBounds', { venueCenter, venueRadius, bounds })
  monitorDebugFeaturesReset('map/resetVenueBounds', this.bus)
}

async function buildingBounds (nameFilter) {
  this.bus.send('map/showBuildingBounds', { nameFilter })
  monitorDebugFeaturesReset('map/resetVenueBounds', this.bus)
}

async function padding () {
  this.bus.send('map/togglePadding')
  monitorDebugFeaturesReset('map/togglePadding', this.bus)
}

const monitorDebugFeaturesReset = (resetEventName, bus) => {
  const unsubscribe = bus.monitor('homeview/performSearch', ({ term }) => {
    if (!term) {
      bus.send(resetEventName)
      unsubscribe()
    }
  })
}

function dndGo () {
  const bus = this.bus
  const toggleActive = (e, indicateDragVisual) => {
    e.preventDefault()
    if (indicateDragVisual)
      e.target.classList.add('dragover')
    else
      e.target.classList.remove('dragover')
  }

  const handlers = {

    drop: async function (e) {
      toggleActive(e, false)

      // Load the file into the player
      for (const file of e.dataTransfer.files) {
        const filename = file.name
        if (file.type === 'application/json' || (file.type.startsWith('text/'))) {
          const reader = new FileReader()
          reader.onload = singleFile(async e =>
            bus.send(`debugTools/fileDrop`, { file, filename, content: e.target.result }))
          reader.readAsText(file)
        }
      }
    },

    // Drag-over event
    dragover: function (e) { toggleActive(e, true) },

    // Drag-leave event
    dragleave: function (e) { toggleActive(e, false) }

  } // end of handlers def

  Object.keys(handlers).forEach(function (event) {
    document.body.addEventListener(event, handlers[event])
  })

  console.log('DnD Listeners installed')
} // end of function installDragNDrop

export default { showIcons, poisByCategory, highlightNodes, getPoiById, orphanTest, showOrphaned, showgraph, dndGo, bounds, buildingBounds, padding }
