import React from 'react'
import styled from 'styled-components'

import { Icon } from '../../../../src/ui/icons/Icon.jsx'

import { PrimaryLabel, SecondaryLabel } from './styles.js'

const Wrapper = styled.div`
  text-align: center;

  .-mobile & {
    padding: 32px 56px;
  }

  .-desktop & {
    padding: 16px 28px;
  }
`

const RouteUnavailableWidget = ({ className, T }) => {
  return <Wrapper className={className}>
    <Icon className="icon" width={47} height={63} id="route-unavailable" />
    <PrimaryLabel>{T('getDirectionsFromTo:Route Unavailable')}</PrimaryLabel>
    <SecondaryLabel>
      {T('getDirectionsFromTo:Sorry, we couldn’t find a navigation path because the from or to point is not accessible')}
    </SecondaryLabel>
  </Wrapper>
}

export default RouteUnavailableWidget
