import { path } from 'ramda'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import PoiStatusPill from '../../../../../src/ui/labels/PoiStatusPill.jsx'
import { ParkingLabelStatus } from '../../../../../utils/constants.js'

const PoiStatusPillStyled = styled(PoiStatusPill)`
  margin: 2px 0 0 52px;
`

const OccupancyReport = styled.span`
  margin: 2px 0 0 52px;
`

const OpenClosedStatus = withTheme(({ theme, poi, T }) => {
  const occupancyOb = path(['dynamicData', 'occupancy'], poi)

  const parkingDD = path(['dynamicData', 'parking'], poi)
  const openCloseDD = path(['dynamicData', 'open-closed-status'], poi)

  let poiStatus

  if (parkingDD) {
    if (typeof parkingDD.lotStatus !== 'undefined' && parkingDD.lotStatus !== 'Unknown') {
      poiStatus = parkingDD.lotStatus
    }
  } else if (openCloseDD) {
    poiStatus = openCloseDD.isOpen ? ParkingLabelStatus.OPEN : ParkingLabelStatus.CLOSED
  }

  return (
    <div>
      {poiStatus && <PoiStatusPillStyled status={poiStatus} T={T} />}
      { (occupancyOb && occupancyOb.expiration > Date.now())
        ? (<OccupancyReport>
          { occupancyOb.occupancy } Occupants
        </OccupancyReport>)
        : null }
    </div>
  )
})

export default OpenClosedStatus
