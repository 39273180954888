import * as PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ScrollableDropShadow from '../../../../src/ui/ScrollableDropShadow.jsx'
import SearchResults from '../../../../src/ui/SearchResults/SearchResults.js'

const SearchResultsStyled = styled(SearchResults)`
  overflow: auto;
`

const SearchResultView = ({ isDesktopFn, widgetState, handleSuggestionClicked, handlePoiClicked, handleEscapeKey, T }) => {
  const [placesNearby, setPlacesNearby] = useState(widgetState.getState().nearby)
  const [locations, setLocations] = useState(widgetState.getState().locations)
  const [searchSuggestions, setSearchSuggestions] = useState(widgetState.getState().keywords)
  const [isSearchConfirmed, setIsSearchConfirmed] = useState(widgetState.getState().isSearchConfirmed)

  useEffect(() => widgetState.addCallback(({ nearby, locations, isSearchConfirmed }) => {
    setPlacesNearby(nearby)
    setLocations(locations)
    setSearchSuggestions(widgetState.getState().keywords)
    setIsSearchConfirmed(isSearchConfirmed)
  }), [widgetState])

  const isDesktop = isDesktopFn()

  return (
    <ScrollableDropShadow T={T} isDesktop={isDesktop}>
      <SearchResultsStyled
        placesNearby={placesNearby}
        placesNearbyTitle={T('homeView:NEARBY LOCATIONS')}
        placesTitle={T('homeView:LOCATIONS')}
        locations={locations}
        searchSuggestions={!isSearchConfirmed ? searchSuggestions : null}
        onSuggestionClicked={handleSuggestionClicked}
        onPoiClicked={handlePoiClicked}
        isDesktop={isDesktop}
        hasBottomPadding={!isDesktop && isSearchConfirmed}
        handleEscapeKey={handleEscapeKey}
        T={T}
      />
    </ScrollableDropShadow>
  )
}

SearchResultView.propTypes = {
  handleSuggestionClicked: PropTypes.func,
  handlePoiClicked: PropTypes.func,
  widgetState: PropTypes.shape({
    getState: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired
  }).isRequired,
  isDesktopFn: PropTypes.func.isRequired,
  handleEscapeKey: PropTypes.func
}

SearchResultView.defaultProps = {
  handleSuggestionClicked: () => { },
  handlePoiClicked: () => { },
  handleEscapeKey: () => {}
}

export default SearchResultView
