import { arrayMove } from '@dnd-kit/sortable'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'

import { MAX_SEARCH_INPUT_LENGTH } from '../constants.js'

export const handleAddStop = (searchInputs, setSearchInputs, onInputSelect) => () => {
  if (searchInputs?.length < MAX_SEARCH_INPUT_LENGTH) {
    const nextSearchInputs = [...searchInputs, { id: uuid() }]
    setSearchInputs(nextSearchInputs)
    onInputSelect(nextSearchInputs.length - 1)
  }
}

export const handleDragStart = (setIsDragging) => () => {
  setIsDragging(true)
}

export const handleDragOver = (internalSearchInputs, setDraggingSearchInputs) => (e) => {
  const { active, over } = e
  setDraggingSearchInputs((draggingItems) => {
    const oldIndex = draggingItems.findIndex((user) => user.id === active.id)
    // Find the 'slot' that the active item is hovering over, this remains constant during drag
    const newIndex = internalSearchInputs.findIndex((user) => user.id === over.id)
    return arrayMove(draggingItems, oldIndex, newIndex)
  })
}

export const handleDragEnd = (setSearchInputs, setIsDragging) => (e) => {
  const { active, over } = e
  if (active.id !== over.id)
    setSearchInputs((items) => {
      const oldIndex = items.findIndex((user) => user.id === active.id)
      const newIndex = items.findIndex((user) => user.id === over.id)
      return arrayMove(items, oldIndex, newIndex)
    })
  setIsDragging(false)
}

export const handleRemoveClick = (searchInputs, setSearchInputs, index, widgetState) => () => {
  if (searchInputs.length > 2) {
    setSearchInputs(R.remove(index, 1, searchInputs))
    widgetState.update({ currentStop: 0, currentStep: 0 })
  }
}

export const handleMoveClick = (searchInputs, setSearchInputs, index, widgetState) => (move) => {
  if (searchInputs.length > 2) {
    switch (move) {
      case 'Move to start':
        setSearchInputs(R.move(index, 0, searchInputs))
        widgetState.update({ currentStop: 0, currentStep: 0 })
        break
      case 'Move up':
        setSearchInputs(R.move(index, (index - 1 > -1 ? index - 1 : 0), searchInputs))
        widgetState.update({ currentStop: 0, currentStep: 0 })
        break
      case 'Move down':
        setSearchInputs(R.move(index, index + 1, searchInputs))
        widgetState.update({ currentStop: 0, currentStep: 0 })
        break
      case 'Move to end':
        setSearchInputs(R.move(index, searchInputs.length - 1, searchInputs))
        widgetState.update({ currentStop: 0, currentStep: 0 })
        break
    }
  }
}

export const reverseDirections = (searchInputs, setSearchInputs) => () => {
  setSearchInputs([...searchInputs].reverse())
}

export const handleClearButtonClick = (searchInputs, setSearchInputs, onClearButtonClick, index) => () => {
  onClearButtonClick()
  setSearchInputs(R.update(index, R.pick(['id'], searchInputs[index]), searchInputs))
}
