import Zousan from 'zousan'

import { getHelp, getHelpHeader, getHelpList } from './help.js'
import { validate, getSigMatch } from './validate.js'

const COMMAND_VALIDATION_REGEX = /^[-_.0-9a-zA-Z]+$/
const JS = JSON.stringify

export default function create () {
  const commandDefsList = []
  const commands = () => commandDefsList.map(cob => cob.sig)
  const customTypes = { }
  const library = { customTypes, commandDefsList }
  const getCommandJSON = () => ({ commands: commands(), customTypes })

  function registerCommand (sig, fn) {
    const command = sig.command
    if (command === undefined) { throw Error(`Invalid command specification in registerCommand: ${JS(sig)}. No 'command' property specified.`) }

    if (!COMMAND_VALIDATION_REGEX.test(command)) {
      const er = Error(`Invalid command specification in registerCommand: ${JS(sig)}. Command name '${command}' not valid.`)
      throw er
    }

    commandDefsList.push({ sig, fn })

    return execute
  }

  function registerCustomType (name, spec) {
    customTypes[name] = spec
  }

  function execute (cob) {
    return new Zousan((resolve, reject) => {
      if (!cob) { return reject(new Error(`No command specified in command object ${JS(cob)}`)) }

      const commandMatches = library.commandDefsList
        .filter(com => com.sig.command === cob.command)

      if (commandMatches.length === 0) { return reject(new Error(`No API command '${cob.command}' found.\n${getHelpList(library.commandDefsList.map(o => o.sig))}`)) }

      const sigMatch = getSigMatch(library, cob)

      if (!sigMatch) {
        if (commandMatches.length === 1) { return reject(new Error(`Required fields not present in ${JS(cob)}\n${getHelpHeader()}${getHelp(commandMatches[0].sig)}`)) } else { return reject(new Error(`Command arguments did not match any required signatures: ${JS(cob)}\n${getHelpList(library.commandDefsList.map(o => o.sig))}`)) }
      }

      // console.log(`command ${JS(cob)} is ${JS(sigMatch.sig)}`)

      try {
        validate(library, sigMatch.sig, cob) // this will throw an error if invalid
      } catch (e) { return reject(e) }

      resolve(sigMatch.fn(cob))
    })
  }

  registerCommand({ command: 'help', args: [] }, () => getHelpList(commands()))
  registerCommand({ command: 'getCommandJSON', args: [] }, getCommandJSON)

  return {
    registerCommand,
    registerCustomType,
    execute
  }
}
