import Logo from '@locus-labs/mol-desktop-logo'
import * as PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import styled, { withTheme } from 'styled-components'

import SearchNav from './SearchNav.jsx'
import SearchNavMobile from './SearchNavMobile.jsx'
import { StyledHeader } from './StyledHeader.js'
import { Title } from './styles.js'

const LogoWrapper = styled.a`
  padding: 0 0 ${prop => prop.isDesktop ? '20px' : '0'} 0;
  display: block;
`

export const Header = withTheme(({
  theme,
  clientName,
  logoLinkUrl,
  searchPlaceholder,
  isDesktop,
  title,
  onSearchTermChange,
  onFocus,
  widgetState,
  handleToggleSearchButtonClick,
  handleOpenDirectionsSearchButtonClick,
  handleKeyPress,
  T,
  onUserMenuButtonClick,
  onBackButtonClicked,
  menuOptions
}) => {
  const [state, setWidgetState] = useState(widgetState.getState())

  useEffect(() => widgetState.addCallback(setWidgetState))

  const renderSearchNav = () => {
    if (isDesktop) return (
      <SearchNav
        searchPlaceholder={searchPlaceholder}
        onOpenDirectionsSearchButtonClick={handleOpenDirectionsSearchButtonClick}
        widgetState={widgetState}
        onSearchTermChange={onSearchTermChange}
        onFocus={onFocus}
        handleKeyPress={handleKeyPress}
        onBackButtonClicked={onBackButtonClicked}
        T={T}
      />
    )
    else return (
      <SearchNavMobile
        searchPlaceholder={searchPlaceholder}
        logoUrl={state.logoUrl}
        onOpenDirectionsSearchButtonClick={handleOpenDirectionsSearchButtonClick}
        onToggleSearchButtonClick={handleToggleSearchButtonClick}
        handleKeyPress={handleKeyPress}
        onUserMenuButtonClick={onUserMenuButtonClick}
        T={T}
        widgetState={widgetState}
        menuOptions={menuOptions}
      />
    )
  }

  const getLogo = () => state.logoUrl
    // ? <StyledLogo linkUrl={logoLinkUrl} url={state.logoUrl} name={clientName} />
    ? <LogoWrapper isDesktop={ isDesktop } href={logoLinkUrl}><Logo logoHeight={ theme.logo.height } alt={ clientName } url={ state.logoUrl } width="auto" position={ theme.logo.centered ? 'center' : 'left' } padding={ theme.logo.padding }/></LogoWrapper>
    : <Title>{title}</Title>

  return (
    <StyledHeader
      className={!isDesktop ? '-mobile' : '-desktop'}
      hasLogoUrl={!!state.logoUrl}
      data-cy='homeViewHeader'>
      {theme.header?.hasLogo || !isDesktop ? getLogo() : null}
      {renderSearchNav()}
    </StyledHeader>
  )
})

Header.propTypes = {
  clientName: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  onSearchTermChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  handleToggleSearchButtonClick: PropTypes.func.isRequired,
  onUserMenuButtonClick: PropTypes.func.isRequired,
  handleOpenDirectionsSearchButtonClick: PropTypes.func.isRequired
}

Header.defaultProps = {}
