import React from 'react'
import styled, { withTheme } from 'styled-components'

import { ColoredIcon } from '../../../../src/ui/icons/Icon.jsx'
import { ToggleViewState } from '../constants.js'

const Wrapper = styled.button`
  z-index: 152;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 4px 16px;
  box-shadow: inset 0px 1px 0px #EBEBEB;
  display: flex;
  align-items: center;
  flex-direction: row;
  ${({ theme }) => theme.fontSize('14px')};
  line-height: 18px;
  color: ${({ theme }) => theme.colors.primaryText};
  cursor: pointer;
  border: none;
  position: relative;

  svg {
    margin-right: 16px;
  }
`

const SearchViewToggler = withTheme(({ theme, onClickHandler, widgetState, isTogglerVisible }) => {
  const { toggleState } = widgetState.getState()

  if (isTogglerVisible()) {
    return <Wrapper onClick={onClickHandler} data-cy='SearchViewToggler'>{ toggleState === ToggleViewState.SWITCH_TO_MAP
      ? <>
        <ColoredIcon className="icon" width={32} height={32} id="map" fillColor={theme.colors.primaryText} />
      Show map</>
      : <>
        <ColoredIcon className="icon" width={32} height={32} id="list-view" fillColor={theme.colors.primaryText} />
        Show results</>}</Wrapper>
  }
  return null
})

export default SearchViewToggler
