import React from 'react'

import { getLocalized } from '../../../../src/utils/configUtils.js'
import { widgetEnum } from '../../../../utils/constants.js'

import { Header } from './topHeaderView.js'

function create (app, config) {
  const init = async () => {
    const logoIconUrl = await app.bus.get('theme/getVenueLogo')
    const linkUrls = getLocalized(config, 'linkUrls', app.i18n().language)

    app.bus.send('layers/register', {
      id: 'topHeader',
      widget: () =>
        <Header
          logoIconUrl={logoIconUrl}
          logoLinkUrl={config.logoLinkUrl}
          linkUrls={linkUrls}
          headerBGColor={config.headerBGColor}
          buttonTextColor={config.buttonTextColor}
        />,
      layoutId: 'topHeader',
      show: true,
      widgetType: widgetEnum.Desktop
    })
  }

  return { init }
}

export { create }
