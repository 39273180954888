import { filter, identity, join, pipe, path } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { handleKeyboardEscape, handleKeyboardSelect } from '../../../plugins/utils/keyboardUtils.js'
import { listElementHoverCss } from '../../../src/ui/hoverStates.js'
import { getPoiIconId } from '../../../src/utils/poi.js'
import { ParkingLabelStatus } from '../../../utils/constants.js'
import { Icon, ColoredIcon } from '../icons/Icon.jsx'
import PoiStatusPill from '../labels/PoiStatusPill.jsx'
import { SecurityLabel } from '../labels/SecurityLabel.jsx'

const SearchResultsElem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 8px 14px 12px;
  position: relative;
  margin: 0;
  cursor: pointer;

  &:hover {
    ${listElementHoverCss};
  }

  &:not(:last-child):after {
    content: "";
    position: absolute;
    width: calc(100% - 32px);
    margin: auto;
    bottom: 0;
    height: 1px;
    background-color: #ebebeb;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IconWrapper = styled.div`
  width: 36px;
  text-align: center;

  ${ColoredIcon} {
    vertical-align: middle;
  }
`

const RoomInfo = styled.div`
  margin-left: 12px;
  margin-right: 12px;

  span {
    display: block;
  }

  .label {
    display: block;
    color: ${({ theme }) => theme.colors.primaryText};
    ${({ theme }) => theme.fontSize('16px')};
    font-weight: 500;
    line-height: 150%;
  }

  .label--light {
    font-weight: 500;
  }

  .info {
    color: ${({ theme }) => theme.colors.secondaryText};
    ${({ theme }) => theme.fontSize('14px')};
    font-weight: 400;
    line-height: 150%;
  }
`

const SecurityWaitLabelStyled = styled(SecurityLabel)`
  margin-top: 6px;
  margin-left: 49px;
`

const PoiStatusPillStyled = styled(PoiStatusPill)`
  margin-top: 6px;
  margin-left: 49px;
`

const SearchResultsList = ({
  isDefault, isDesktop, results, onResultClicked, onChooseMapLocationClicked, className, handleEscapeKey, T
}) => {
  const getLocationInfo = poi => {
    if (poi.roomId)
      return [poi.roomId, poi.position.floorName]
    else {
      const nearbyLandmark = poi.nearbyLandmark && T('ui:Near _location_', { location: poi.nearbyLandmark })
      return [nearbyLandmark, poi.position.floorName, poi.position.structureName]
    }
  }
  const buildDetails = pipe(getLocationInfo, filter(identity), join(' • '))

  const SearchResult = ({ result }) => {
    const dynamicData = path(['dynamicData', 'security'], result)

    const statusText = dynamicData?.isTemporarilyClosed
      ? T('ui:Closed')
      : T('ui:_xx_ minute wait', { count: dynamicData?.queueTime })

    const parkingDD = path(['dynamicData', 'parking'], result)
    const openCloseDD = path(['dynamicData', 'open-closed-status'], result)

    let poiStatus

    if (parkingDD) {
      if (typeof parkingDD.lotStatus !== 'undefined' && parkingDD.lotStatus !== 'Unknown') {
        poiStatus = parkingDD.lotStatus
      }
    } else if (openCloseDD) {
      poiStatus = openCloseDD.isOpen ? ParkingLabelStatus.OPEN : ParkingLabelStatus.CLOSED
    }

    return (
      <SearchResultsElem
        data-cy={'searchResultsElementPoi'}
        tabIndex="0"
        role='button'
        onKeyDown={
          e => handleKeyboardSelect(() => onResultClicked(result))(e) ||
            handleKeyboardEscape(handleEscapeKey)(e) // try one then the other..
        }
        aria-label={`${T('ui:Search Result')}: ${result.name}, ${T('ui:info')}: ${buildDetails(result)} ${poiStatus}`}
        onClick={() => onResultClicked(result)}>
        <ContentWrapper>
          <IconWrapper>
            <Icon id={getPoiIconId(result)} width={36} height={36} />
          </IconWrapper>
          <RoomInfo>
            <span className="label">{result.name}</span>
            <span className="info">{buildDetails(result)}</span>
          </RoomInfo>
        </ContentWrapper>
        <SecurityWaitLabelStyled poi={result} T={T} statusText={statusText} />
        {poiStatus && <PoiStatusPillStyled status={poiStatus} T={T} />}
      </SearchResultsElem>
    )
  }

  return (
    <>
      {(isDefault && !isDesktop) && (
        <SearchResultsElem
          data-cy='chooseOnMap'
          onClick={onChooseMapLocationClicked}
          onKeyDown={handleKeyboardSelect(() => onChooseMapLocationClicked())}
          tabIndex={'0'}
        >
          <ContentWrapper>
            <IconWrapper>
              <ColoredIcon id="location" width={32} height={32} fillColor="#666" />
            </IconWrapper>
            <RoomInfo>
              <span className="label label--light">{T('ui:Choose location on map')}</span>
            </RoomInfo>
          </ContentWrapper>
        </SearchResultsElem>
      )}
      {results.map((result, index) => (
        <SearchResult key={index} result={result} />
      ))}
    </>
  )
}

export default SearchResultsList
