/*
  This utility module contains a set of functions dealing with "Geodesy" - measurements of
  the earth, its surface and related geometry.

  For a more cohesive set of functions, see https://github.com/chrisveness/geodesy
*/

// degrees to radians
export const toRadians = d => d * Math.PI / 180
export const toDegrees = r => r * 180 / Math.PI

/**
 * Haversine formula for finding distance between two lat/lng points.
 * from https://www.movable-type.co.uk/scripts/latlong.html
 * @param {float} lat1 latitutde of point 1
 * @param {float} lng1 longitude of point 1
 * @param {float} lat2 latitutde of point 2
 * @param {float} lng2 longitude of point 2
 * @returns number (in meters) between p1 and p2
 */
export function distance (lat1, lng1, lat2, lng2) {
  const R = 6371000 // approx. earth radius (meters)
  const φ1 = toRadians(lat1)
  const φ2 = toRadians(lat2)
  const Δφ = toRadians(lat2 - lat1)
  const Δλ = toRadians(lng2 - lng1)

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c
}

// Calculate a bearing from two points on earth.
// from https://www.movable-type.co.uk/scripts/latlong.html
// todo check if we can use 'bearing' from Turf
export function getBearing (lat1, lng1, lat2, lng2) {
  const φ1 = toRadians(lat1)
  const φ2 = toRadians(lat2)
  const λ1 = toRadians(lng1)
  const λ2 = toRadians(lng2)

  const y = Math.sin(λ2 - λ1) * Math.cos(φ2)
  const x = Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * Math.cos(φ2) * Math.cos(λ2 - λ1)
  return toDegrees(Math.atan2(y, x))
}
