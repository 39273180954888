import styled from 'styled-components'

export const FloatingComponent = styled.div`
  margin: 8px auto auto;
  display: ${({ isMobile }) => isMobile ? 'flex' : 'none'};
  justify-content: center;
  transition: 0.5s ease-in-out opacity;
  opacity: 1;
  flex-direction: column;
  align-items: center;
  border-radius: 2px;
  z-index: 90;
  background: ${({ theme }) => theme.colors.mapNavBackground};

  &.hiding {
    opacity: 0;
  }

  div {
    color: ${({ theme }) => theme.colors.mapNavText};
    font-size: 14px;
    line-height: 18px;
    padding: 7px 15px;
  }
`
