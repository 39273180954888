import styled from 'styled-components'

export const StyledHeader = styled.div`
  display: flex;
  padding: ${({ hasLogoUrl, theme }) => hasLogoUrl && theme.header.hasLogo ? theme.header.paddingWithLogoMobile : theme.header.paddingMobile};
  background:  ${props => props.theme.colors.widgetBackground};
  flex: 1;
  .-desktop & {
    padding: ${({ hasLogoUrl, theme }) => hasLogoUrl && theme.header.hasLogo ? theme.header.paddingWithLogo : theme.header.padding};
    display: block;
  }

  .-mobile & {
    align-items: center;
    height: 64px;
  }
`
