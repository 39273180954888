import './public-path.js' // this should be first line, per https://webpack.js.org/guides/public-path/
import * as engine from '../src/main.js'

import prepareSDKConfig from './prepareSDKConfig.js'

// Handle SDK Init requests
window.addEventListener('message',
  e => {
    if (e.data.type === 'LL-INIT')
      engine.create(prepareSDKConfig(e.data.config))
  },
  false)

window.LocusLabsMaps = engine
