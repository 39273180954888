import { point as turfPoint, lineString as turfLineString } from '@turf/helpers'
import pointToLineDistance from '@turf/point-to-line-distance'
import * as R from 'ramda'

// https://stackoverflow.com/questions/22521982/check-if-point-inside-a-polygon
const pointInPolygon = (point, vs) => {
  const x = point[0]
  const y = point[1]

  let inside = false
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0]
    const yi = vs[i][1]
    const xj = vs[j][0]
    const yj = vs[j][1]
    const intersect = ((yi > y) !== (yj > y)) &&
          (x < (xj - xi) * (y - yi) / (yj - yi) + xi)
    if (intersect) {
      inside = !inside
    }
  }

  return inside
}

// Returns true if point passed is contained within box indicated
const isPointWithinBounds = (lat, lng, n, s, e, w) =>
  pointInPolygon([lat, lng], [[n, e], [n, w], [s, w], [s, e]])

const getBoundsCoords = building => {
  const { n, s, e, w } = building.bounds
  return [[n, e], [n, w], [s, w], [s, e], [n, e]]
}

/**
 * Pass in a buildings array and a lat,lng and this will return the building at that point
 * or undefined if no building exists at that point. This runs very quickly as we very often
 * can skip the slowest path (which runs pointInPolygon). Without preciseFlag we sometimes return
 * a building when the point falls outside it - but won't return a wrong building. In some cases this
 * is actually desired behavior anyway (such as building selector)
 * @param  {Array.Object} buildings
 * @param  {float} lat latitude of point to check
 * @param  {float} lng longitude of point to check
 * @param  {boolean} preciseFlag if not true we take a shortcut when a single building falls within bounding box (very common case)
 * @returns {object} building that contains the point passed, or undefined if none
 */
function getStructureAtPoint (buildings, lat, lng, preciseFlag) {
  if (!R.length(buildings)) return null

  buildings = buildings.filter(b => b.shouldDisplay == null || b.shouldDisplay === true)

  const buildingsWithinBoundingBox = buildings.filter(b =>
    pointInPolygon([lat, lng], getBoundsCoords(b)))

  if (buildingsWithinBoundingBox.length === 0) return null
  if (buildingsWithinBoundingBox.length === 1 && !preciseFlag)
    return R.head(buildingsWithinBoundingBox)

  const buildingsWithinBoundsPolygon = buildingsWithinBoundingBox
    .filter(b => pointInPolygon([lat, lng], b.boundsPolygon))

  if (buildingsWithinBoundsPolygon.length === 1)
    return R.head(buildingsWithinBoundsPolygon)

  // If we are within more than 1 building (WTF?) then return the smaller one
  if (buildingsWithinBoundsPolygon.length)
    return findBuildingWithMinArea(buildingsWithinBoundingBox)

  if (preciseFlag) // technically, point is not above any building...
    return null

  const distancesToBuildings = buildingsWithinBoundingBox.map(building => distanceFromPointToBuilding(lat, lng, building))
  const shortestDistance = Math.min.apply(null, distancesToBuildings)
  const closestBuilding = buildingsWithinBoundingBox[distancesToBuildings.indexOf(shortestDistance)]

  return closestBuilding
}

// a Turf linestring is a multi-edge line defined by a series of vertices
// (basiclly what we call a boundsPolygon - but we need to construct via the library)
const buildingToTurfLineString = building =>
  turfLineString(building.boundsPolygon.map(v => swap(v))) // turf uses [lng,lat] so we need to swap em

const lineStrings = { } // building id => lineString object

// this function constructs a lineString (https://turfjs.org/docs/#lineString) from a building
// boundsPolygon which can be used to work with the Turf library. There is some cost in generating it
// so we cache the object for future use.
const getLineString = building => {
  let lineString = lineStrings[building.id]
  if (!lineString) {
    lineString = buildingToTurfLineString(building)
    lineStrings[building.id] = lineString
  }

  return lineString
}

const distanceFromPointToBuilding = (lat, lng, building) => {
  for (let i = 0; i < 99; i++)
    distanceFromPointToBuildingImp(lat, lng, building)

  return distanceFromPointToBuildingImp(lat, lng, building)
}

const distanceFromPointToBuildingImp = (lat, lng, building) => {
  // First, create an array of distances of the lat,lng to each edge in the polygon - then return the minimum value in the array
  const pt = turfPoint([lng, lat])

  const lineString = getLineString(building)
  return pointToLineDistance(pt, lineString) // distance in kilometers
}

const swap = ar => [ar[1], ar[0]]

const calcBuildingArea = currentBuilding => {
  if (!currentBuilding.bounds) return 0
  const { n, s, e, w } = currentBuilding.bounds
  return Math.abs((n - s) * (e - w))
}

// takes a function and two objects and returns the object that evaluates less against the function
const less = fn => (o1, o2) => fn(o1) < fn(o2) ? o1 : o2
const findBuildingWithMinArea = ba => ba.reduce(less(calcBuildingArea)) // returns the building with least area within bounding

/**
 * given a building and ord, return the floor (or undefined if doesn't exist)
 */
const ordToFloor = (building, ord) =>
  Object.values(building.levels).find(floor => floor.ordinal === ord)

/**
 * Return the floor based on its ID (pass in buildings array and floorId)
 */
const getFloor = (buildings, selectedLevelId) =>
  buildings.reduce((fmatch, building) =>
    Object.values(building.levels).find(floor => floor.id === selectedLevelId) || fmatch, undefined)

function getBuildingAndFloorAtPoint (buildings, lat, lng, ord, preciseFlag) {
  const building = getStructureAtPoint(buildings, lat, lng, preciseFlag)
  const floor = building
    ? ordToFloor(building, ord)
    : null
  return { building, floor }
}

const getFloorAt = (buildings, lat, lng, ord, preciseFlag) => getBuildingAndFloorAtPoint(buildings, lat, lng, ord, preciseFlag).floor

// pass in the structures array and a floorId and this will return the structure
// that contains the floorId.
const getStructureForFloorId = (buildings, floorId) =>
  buildings.reduce((sMatch, building) =>
    buildContainsFloorWithId(building, floorId) ? building : sMatch, null)

// returns true if the building specified contains the floorId specified
const buildContainsFloorWithId = (building, floorId) =>
  Object.values(building.levels).reduce((fmatch, floor) =>
    floor.id === floorId ? true : fmatch, false)

// Pass in the building an Core Location floor, get back a floor object
const getFloorForBuildingAndCLFloor = (buildings, building, clFloor) =>
  R.find(level => level.clfloor === clFloor, Object.values(building.levels)) // find floor whose clfloor matches

/**
 * Calculate the points for a bezier cubic curve
 *
 * @param {number} fromX - Starting point x
 * @param {number} fromY - Starting point y
 * @param {number} cpX - Control point x
 * @param {number} cpY - Control point y
 * @param {number} cpX2 - Second Control point x
 * @param {number} cpY2 - Second Control point y
 * @param {number} toX - Destination point x
 * @param {number} toY - Destination point y
 * @return {Object[]} Array of points of the curve
 */
function bezierCurveTo (fromX, fromY, cpX, cpY, cpX2, cpY2, toX, toY) {
  const n = 20 // controls smoothness of line
  let dt = 0
  let dt2 = 0
  let dt3 = 0
  let t2 = 0
  let t3 = 0

  const path = [{ x: fromX, y: fromY }]

  for (let i = 1, j = 0; i <= n; ++i) {
    j = i / n

    dt = (1 - j)
    dt2 = dt * dt
    dt3 = dt2 * dt

    t2 = j * j
    t3 = t2 * j

    path.push({
      x: (dt3 * fromX) + (3 * dt2 * j * cpX) + (3 * dt * t2 * cpX2) + (t3 * toX),
      y: (dt3 * fromY) + (3 * dt2 * j * cpY) + (3 * dt * t2 * cpY2) + (t3 * toY)
    })
  }

  return path
}

export {
  bezierCurveTo,
  getBuildingAndFloorAtPoint,
  getFloor,
  getFloorAt,
  getFloorForBuildingAndCLFloor,
  getStructureAtPoint,
  getStructureForFloorId,
  isPointWithinBounds,
  ordToFloor,
  pointInPolygon
}
