const segmentCategories = {
  START: 'start',
  WALKING_TO_SECURITY_CHECKPOINT: 'walking to security checkpoint',
  WALKING_TO_PORTAL: 'walking to portal',
  WALKING_TO_END: 'walking to end',
  ELEVATOR: 'elevator',
  ELEVATOR_UP: 'elevator.up',
  ELEVATOR_DOWN: 'elevator.down',
  STAIRS: 'stairs',
  STAIRS_UP: 'stairs.up',
  STAIRS_DOWN: 'stairs.down',
  ESCALATOR: 'escalator',
  ESCALATOR_UP: 'escalator.up',
  ESCALATOR_DOWN: 'escalator.down',
  WALK: 'walk',
  WALK_DOWN: 'walk.down',
  WALK_UP: 'walk.up',
  TRAIN: 'train',
  TRAIN_UP: 'train.up',
  TRAIN_DOWN: 'train.down',
  BUS: 'bus',
  BUS_UP: 'bus.up',
  BUS_DOWN: 'bus.down',
  SECURITY_CHECKPOINT: 'enduring security checkpoint',
  RAMP: 'ramp',
  RAMP_UP: 'ramp.up',
  RAMP_DOWN: 'ramp.down'
}

export default segmentCategories
