import { prop } from 'ramda'

import { initEventHandler } from './events.js'
import { createSession } from './session.js'

function create (app, config) {
  const log = app.log.sublog('analytics')

  let currentFloorId = null // track current floor Id
  let currentVenueId = null

  const mapMoveEvent = ({ lat, lng, zoom }) => {
    if (currentFloorId && currentVenueId) // wait until we define this before logging any mapMoved events...
      app.bus.send('session/submitEvent', { type: 'mapMoved', center: { lat, lng, floorId: currentFloorId, venueId: currentVenueId }, mapZoomv5: zoom, radius: zoom })
    else
      log.info(`No map moved analytics event.. ${currentFloorId} - ${currentVenueId}`)
  }
  app.bus.monitor('map/floorChanged', ({ floor }) => (currentFloorId = prop('id', floor)))

  app.bus.monitor('map/moveEnd', mapMoveEvent)

  app.bus.monitor('poiDetails/showPoi', async ({ poi }) => {
    const venueId = await app.bus.get('venueData/getVenueId')
    app.bus.send('session/submitEvent', {
      type: 'poiViewed',
      poi: { venueId, poiId: poi.poiId },
      viewLevel: 'full'
    })
  })

  app.bus.monitor('search/queryAsync', async ({ term }) => app.bus.send('session/submitEvent', { type: 'search', query: term }))
  app.bus.monitor('search/query', ({ term }) => app.bus.send('session/submitEvent', { type: 'search', query: term }))
  app.bus.monitor('search/queryCategory', ({ category }) => app.bus.send('session/submitEvent', { type: 'search', searchType: 'categorySearch', query: category }))
  app.bus.monitor('search/queryNearby', () => app.bus.send('session/submitEvent', { type: 'search', searchType: 'customAction', query: 'nearby' }))

  createSession(app)
  initEventHandler(app.bus, config, log)

  return {
    init: () => {
      app.bus.get('map/getMapCenter')
        .then(pos => {
          if (pos && pos.floorId)
            currentFloorId = pos.floorId // initialize floorId
        })
        .catch(() => true) // if mapCenter is not available, don't bother (i.e. SDK headless)

      app.bus.on('venueData/venueDataLoaded', ({ venueData }) => {
        currentVenueId = venueData.id
      })
    }
  }
}

export {
  create
}
