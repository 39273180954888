export const easeBoth = x => Math.sin(Math.PI * (x - 1 / 2)) / 2 + 1 / 2
export const easeBoth3 = x => (x *= 2) < 1 ? 1 / 2 * x * x * x : 1 / 2 * ((x -= 2) * x * x + 2)
export const easeLinear = x => x

// Calculates the factor of the current metric (cm) within the range of the starting metric (sm)
// and the ending metric (em) along the curve of the easing function.
const tween = (sm, em, cm, easeFn) => easeFn((cm - sm) / (em - sm))

// Same as tween but ensures all returned values are between 0 and 1 inclusively.
// const pinTween = (sm, em, cm, easeFn) => Math.min(Math.max(0, tween(sm, em, cm, easeFn)), 1)

// Creates a projection function which will project a metric in relation to an input range
// into a metric in relation to an output range, along an easing curve.
// For example:
//  var perFn = project(0,10,0,100) // project from a 0-10 range into a 0-100 range
//  x = perFn(3) // 30
//  x = perFn(6) // 60
export function project (ins, ine, outs, oute, ease) {
  ease = ease || easeLinear
  return inc => (oute - outs) * tween(ins, ine, inc, ease) + outs
}
