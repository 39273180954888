import { rgba } from 'polished'
import React, { Component } from 'react'
import styled from 'styled-components'

import { LoadingIndicator } from '../LoadingIndicator.jsx'

// Added transform to create new stacking context
const Image = styled.img`
  opacity: 0;
  transition: 0.2s ease-in-out;
  width: 100%;
  height: auto;
  transform: translateZ(0);
`

const LoadingIndicatorStyled = styled(LoadingIndicator)`
  top: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => rgba(theme.colors.primary, 0.05)};
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  position: absolute;
`

class ProgressiveImage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hasLoadedBefore: false,
      hasLoaded: false,
      src: props.src
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    // If this image should show and wasn't loaded before
    if (this.state.hasLoadedBefore &&
      nextProps.src === this.props.src &&
      nextState.hasLoaded === this.state.hasLoaded
    ) return false
    return true
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps.src !== prevState.src)
      return {
        ...prevState,
        src: nextProps.src,
        hasLoaded: false
      }
    return null
  }

  componentDidUpdate (prevProps) {
    if (this.state.hasLoaded && !this.state.hasLoadedBefore) this.setState({ hasLoadedBefore: true })
  }

  setImageLoaded = () => {
    this.setState({ hasLoaded: true })
  }

  render () {
    const { hasLoaded, hasLoadedBefore, src } = this.state
    const { isShowing = true, className, alt } = this.props

    return <div className={className}>
      {!hasLoaded && <LoadingIndicatorStyled />}

      {(isShowing || hasLoadedBefore) &&
        <Image
          onLoad={() => this.setImageLoaded()}
          {...hasLoaded && { style: { opacity: '1' } }}
          alt={ alt }
          src={src}
        />}

    </div>
  }
}

export default ProgressiveImage
