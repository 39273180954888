/**
 * General routine for examining a keyboard event and if it matches the key(s) specified
 * it calls the specified handler function.
 *
 * @param  {(string[] | string)} keys single string or array of strings representing keys you wish to handle
 * @param  {() => } handlerFn the event handler you wish to trigger if key is satisfied
 * @param  {Object} config extra configuration options
 * @param  {boolean} [config.stopPropagation=false] if true, stopPropogation is called on the event
 * @returns {boolean} true if the key spec was met and the event was handled
 */
const handleKeyboardEvent = (keys, handlerFn, config = {}) => {
  keys = Array.isArray(keys) ? keys : [keys] // if keys is single key, convert to array
  return (event) => {
    if (keys.includes(event.key)) {
      event.preventDefault()
      if (config.stopPropagation)
        event.stopPropagation()
      handlerFn(event)
      return true
    } else
      return false
  }
}

/**
 * Serves as a keydown event handler to activate the specified handerFn function
 * when the user presses a key that indicates "select" - currently Enter key or spacebar.
 *
 * @param  {() => } handlerFn the event handler you wish to trigger if key is satisfied
 * @param  {Object} config extra configuration options
 * @param  {boolean} [config.stopPropagation=false] if true, stopPropogation is called on the event
 * @returns {boolean} true if the key spec was met and the event was handled
 */
const handleKeyboardSelect = (handlerFn, config = {}) => handleKeyboardEvent(['Enter', ' '], handlerFn, config)

/**
 * Can be used as a keydown event handler to activate the specified function
 * (and optionally prevent default action) when the user presses the escape key.
 *
 * @param  {() => } handlerFn the event handler you wish to trigger if key is satisfied
 * @param  {Object} config extra configuration options
 * @param  {boolean} [config.stopPropagation=false] if true, stopPropogation is called on the event
 * @returns {boolean} true if the key spec was met and the event was handled
 */
const handleKeyboardEscape = (handlerFn, config = {}) => handleKeyboardEvent('Escape', handlerFn, config)

const callIfPressed = (requiredKeys, cb) => (e) => {
  const isPressed = (e.key === requiredKeys.key || e.code === requiredKeys.key) // difference between key and code: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
  const shiftIsPressedIfRequired = ((requiredKeys.shift && e.shiftKey) || (!requiredKeys.shift && !e.shiftKey))

  if (isPressed && shiftIsPressedIfRequired)
    cb(e)
}

const listenToKey = (key, { shift = false } = {}) => (cb, id) => {
  const element = document.getElementById(id)
  const handler = callIfPressed({ key, shift }, cb)
  element.addEventListener('keydown', handler)
  return () => element.removeEventListener('keydown', handler)
}

const listenToTab = listenToKey('Tab')
const listenToShiftTab = listenToKey('Tab', { shift: true })
const listenToEscape = listenToKey('Escape')

export {
  callIfPressed,
  handleKeyboardEscape,
  handleKeyboardEvent,
  handleKeyboardSelect,
  listenToEscape,
  listenToKey,
  listenToShiftTab,
  listenToTab
}
