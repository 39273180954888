// https://en.wikipedia.org/wiki/ANSI_escape_code#Colors
const nodeColorsOb = {
  black: '\x1b[30m',
  red: '\x1b[31m',
  green: '\x1b[32m',
  yellow: '\x1b[33m',
  blue: '\x1b[34m',
  magenta: '\x1b[35m',
  cyan: '\x1b[36m',
  white: '\x1b[37m'
}

function clog (name, options = { }) {
  let prefix = name + ': '
  let style = null

  if (options.color)
    if (options.isBrowser) {
      prefix = '%c' + prefix
      style = `color: ${options.color}`
    } else {
      const cstring = nodeColorsOb[options.color]
      if (cstring)
        prefix = cstring + prefix
    }

  const trunc = (str, len) => str && str.length > len ? str.substring(0, len) + '...' : str
  const checkPassesFilter = (args, filter) => typeof filter === 'string'
    ? args[0].includes(filter)
    : filter.test(args[0])

  function logGen (cmethod) {
    return function () {
      const isEnabled = options.enabled === undefined ? true : !!options.enabled
      if (isEnabled || cmethod === console.error) {
        let args = Array.from(arguments)
        if (style)
          args.unshift(style)
        args.unshift(prefix)
        if (options.truncateObjects && cmethod !== console.error)
          args = args.map(arg => {
            if (typeof arg === 'object')
              return trunc(JSON.stringify(arg), parseInt(options.truncateObjects) || 100)
            return arg
          })
        const passesFilter = !options.logFilter || checkPassesFilter(args, options.logFilter)
        if (passesFilter || cmethod === console.error)
          cmethod.apply(console, args)
      }
    }
  }

  const log = logGen(options.trace ? console.trace : console.log)
  log.info = log
  log.warn = logGen(console.warn)
  log.detailed = logGen(console.debug)
  log.error = logGen(console.error)

  log.setEnabled = e => { options.enabled = e }

  return log
}

export function initLog (name, options) {
  const log = clog(name, options)
  log.sublog = (sublogName, sublogOptions) => initLog(name + '.' + sublogName, Object.assign(Object.create(options), sublogOptions))
  return log
}
