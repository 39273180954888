import { complement, pathEq } from 'ramda'
import React, { useState, useEffect } from 'react'
import { withTheme } from 'styled-components'

import { ColoredIcon } from '../../../../src/ui/icons/Icon.jsx'

import {
  ShowOtherResultsBannerWrapper,
  ShowOtherResultsBanner,
  BannerLabel,
  OtherResultsIndicatorBanner,
  BannerIconWrapper
} from './styles.js'

export const ResultsOnOtherFloorsBanner = withTheme(({ theme, widgetState, bus }) => {
  const [isVisible, setIsVisible] = useState(areResultsOnOtherFloorsOrBuildings(widgetState.getState()))

  useEffect(() => {
    const isVisible = areResultsOnOtherFloorsOrBuildings(widgetState.getState())
    setIsVisible(isVisible)
  },
  [widgetState.getState().searchResults])

  useEffect(() => bus.on('map/userMoveStart', () => setIsVisible(false)))

  const onBannerClicked = () => {
    bus.send('mapLevelSelectorOnline/openLevelSelector', { isOpen: true })
    setIsVisible(false)
  }

  // todo add text Results on other levels to i18n
  return isVisible
    ? <ShowOtherResultsBannerWrapper
      data-cy='resultsOnOtherFloorsBanner'>
      <ShowOtherResultsBanner
        onClick={onBannerClicked}>
        <BannerIconWrapper>
          <ColoredIcon id="levels" width={32} height={32} fillColor={theme.colors.primaryText} />
          <OtherResultsIndicatorBanner/>
        </BannerIconWrapper>
        <BannerLabel>Results on other levels</BannerLabel>
      </ShowOtherResultsBanner>
    </ShowOtherResultsBannerWrapper>
    : null
})

function areResultsOnOtherFloorsOrBuildings ({ selectedBuilding, selectedLevel, searchResults }) {
  const anyDifferent = (path, value) => searchResults.some(complement(pathEq(path, value)))
  if (selectedLevel) return anyDifferent(['position', 'floorId'], selectedLevel.id)
  if (selectedBuilding) return anyDifferent(['position', 'buildingId'], selectedBuilding.id)
}
