import * as R from 'ramda'

import { bezierCurveTo } from '../../../src/utils/geom.js'

import SEGMENT_CATEGORIES from './segmentCategories.js'
import getSteps from './stepBuilder.js'

const setSegmentCategory = (segments) => {
  // Set the category of each segment based on the type of the current segment or the type of the next segment in case we are walking
  // to a portal or to a security checkpoint
  segments.forEach((segment, index) => {
    if (index === 0)
      segment.segmentCategory = SEGMENT_CATEGORIES.START
    else if (segment.waypoints[segment.waypoints.length - 1].isDestination)
      segment.segmentCategory = SEGMENT_CATEGORIES.WALKING_TO_END
    else if (segment.type === 'Security Checkpoint')
      segment.segmentCategory = SEGMENT_CATEGORIES.SECURITY_CHECKPOINT
    else if (segment.type === 'Bus')
      segment.segmentCategory = SEGMENT_CATEGORIES.BUS
    else if (segment.type === 'Train')
      segment.segmentCategory = SEGMENT_CATEGORIES.TRAIN
    else if (segment.type === 'Stairs') {
      if (segment.levelDifference > 0)
        segment.segmentCategory = SEGMENT_CATEGORIES.STAIRS_UP
      else if (segment.levelDifference < 0)
        segment.segmentCategory = SEGMENT_CATEGORIES.STAIRS_DOWN
      else
        segment.segmentCategory = SEGMENT_CATEGORIES.STAIRS
    } else if (segment.type === 'Elevator') {
      if (segment.levelDifference > 0)
        segment.segmentCategory = SEGMENT_CATEGORIES.ELEVATOR_UP
      else if (segment.levelDifference < 0)
        segment.segmentCategory = SEGMENT_CATEGORIES.ELEVATOR_DOWN
      else
        segment.segmentCategory = SEGMENT_CATEGORIES.ELEVATOR
    } else if (segment.type === 'Escalator') {
      if (segment.levelDifference > 0)
        segment.segmentCategory = SEGMENT_CATEGORIES.ESCALATOR_UP
      else if (segment.levelDifference < 0)
        segment.segmentCategory = SEGMENT_CATEGORIES.ESCALATOR_DOWN
      else
        segment.segmentCategory = SEGMENT_CATEGORIES.ESCALATOR
    } else if (segment.type === 'Ramp') {
      if (segment.levelDifference > 0)
        segment.segmentCategory = SEGMENT_CATEGORIES.RAMP_UP
      else if (segment.levelDifference < 0)
        segment.segmentCategory = SEGMENT_CATEGORIES.RAMP_DOWN
      else
        segment.segmentCategory = SEGMENT_CATEGORIES.RAMP
    } else if (segments[index + 1].type === 'Security Checkpoint')
      segment.segmentCategory = SEGMENT_CATEGORIES.WALKING_TO_SECURITY_CHECKPOINT
    else if (segments[index + 1].type !== 'Walk')
      segment.segmentCategory = SEGMENT_CATEGORIES.WALKING_TO_PORTAL
  })
}

const existsEnd = (segments, lastWaypoint) => {
  // if we have only one segment, add an extra segment with only the last point to simulate the end,
  // that way we can draw separate markers for the start and the end of the navigation
  if (segments.length === 1) {
    const segment = { segmentCategory: undefined, waypoints: [] }
    segment.segmentCategory = SEGMENT_CATEGORIES.WALKING_TO_END
    segment.type = 'Walk'
    segment.waypoints = [lastWaypoint]
    segments.push(segment)
  }
}

// todo refactor to not affect step builder
const joinSegments = (segments) => {
  segments.forEach((segment, index) => {
    if (index > 1) {
      const firstWaypointOfSegment = R.head(segment.waypoints)
      if (firstWaypointOfSegment.levelDifference === 0) {
        const lastWaypointOfPreviousSegment = R.last(segments[index - 1].waypoints)
        segment.waypoints = R.prepend(lastWaypointOfPreviousSegment, segment.waypoints)
      }
    }
  })
}

const calculateCurveLineCoordinates = segment =>
  segment.waypoints.flatMap((waypoint, index) => {
    // todo index > 0 is hacky fix
    if (index > 0 && waypoint.curvedPathForward && waypoint.curvedPathForward.length > 0) {
      return waypoint.curvedPathForward
        .flatMap(p => bezierCurveTo(p.start.lng, p.start.lat, p.in.lng, p.in.lat, p.out.lng, p.out.lat, p.end.lng, p.end.lat))
        .map(el => [el.x, el.y])
    } else {
      return [[waypoint.position.lng, waypoint.position.lat]]
    }
  })

const addCurveLineCoordinates = R.map(
  R.converge(R.assoc('coordinates'), [calculateCurveLineCoordinates, R.identity]))

// todo refactor
const createSegments = (waypoints) => {
  const segments = []
  let segment = { segmentCategory: undefined, waypoints: [] }
  let lastWaypoint = null
  let segmentWaypoints = []

  // Always add the first point to be the start of the route
  segment.waypoints = [waypoints[0]]
  segment.type = waypoints[0].isPortal ? waypoints[0].portalType : 'Walk'
  segments.push(segment)
  segment = { segmentCategory: undefined, waypoints: [] }

  waypoints.forEach(waypoint => {
    segmentWaypoints.push(waypoint)

    if (!lastWaypoint) { // true on first waypoint only
      segment.type = waypoint.isPortal ? waypoint.portalType : 'Walk'
      lastWaypoint = waypoint
    } else {
      if ((lastWaypoint.isPortal === waypoint.isPortal) &&
        (lastWaypoint.isSecurityCheckpoint === waypoint.isSecurityCheckpoint)) { // todo not sure if this can happen
        segment.levelDifference = waypoint.levelDifference
      } else {
        segment.waypoints = segmentWaypoints

        if (waypoint.isPortal || lastWaypoint.isPortal) {
          if (segmentWaypoints.length > 1)
            segmentWaypoints.pop()

          // if the portal is not train or bus, we only want it to be one point segment
          if (waypoint.isPortal && (waypoint.portalType.toLowerCase() === 'train' || waypoint.portalType.toLowerCase() === 'bus'))
            segmentWaypoints = [segmentWaypoints[segmentWaypoints.length - 1], waypoint]
          else
            segmentWaypoints = [waypoint]
        } else
          segmentWaypoints = []

        if (lastWaypoint.poiId)
          segment.poiId = lastWaypoint.poiId
        segments.push(segment)

        segment = { segmentCategory: undefined, waypoints: [] }

        segment.type = waypoint.isPortal ? waypoint.portalType : 'Walk'
        segment.levelDifference = waypoint.levelDifference
      }

      lastWaypoint = waypoint
    }
  })

  segment.waypoints = segmentWaypoints

  if (segmentWaypoints.length === 0)
    segment.waypoints = [lastWaypoint]

  segments.push(segment)

  setSegmentCategory(segments)

  existsEnd(segments, lastWaypoint)

  joinSegments(segments)

  return segments
}

const getSegmentType = (segment) => {
  if (segment.type === 'Train') {
    return 'nav.train'
  }
  if (segment.type === 'Bus') {
    return 'nav.transit'
  }
  if (segment.type === 'Security Checkpoint') {
    return 'nav.secure'
  }
  return 'nav.primary'
}

/**
 *
 * @typedef Badge
 * @property {string} canonicalName
 * @property {Coordinate} coordinates
 *
 * @typedef Segment
 * @property {string} levelId
 * @property {string} ordinalId
 * @property {string} segmentType
 * @property {Boolean} shouldDrawSegment - to not show edges like stairs or elevator
 * @property {Array.<Coordinate>} coordinates - list of coordinate pairs [lng, lat]
 * @property {Array<Badge>} badges
 *
 * @param {Waypoint} waypoints
 * @param {Endpoint} fromEndpoint
 * @param {Endpoint} toEndpoint
 * @param {Object.<string, string>} floorIdToNameMap - dictionary of floor id to floor name
 * @param T - i18n translations function
 * @param {QueueTypes} queueTypes
 * @param {boolean} requiresAccessibility
 * @return {{steps: Step[], segments: Segment[]}}
 */
const buildSegments = (waypoints, fromEndpoint, toEndpoint, floorIdToNameMap, T, queueTypes, requiresAccessibility) => {
  let rawSegments = createSegments(waypoints)
  rawSegments = addCurveLineCoordinates(rawSegments)

  // add start location (kiosk) as first coordinate to link it with the navline
  if (fromEndpoint)
    rawSegments[0].coordinates.unshift([fromEndpoint.lng, fromEndpoint.lat])

  if (toEndpoint)
    R.last(rawSegments).coordinates.push([toEndpoint.lng, toEndpoint.lat])

  const segments = rawSegments.map((segment, index) => {
    const startWaypoint = R.last(segment.waypoints)
    const coordinates = segment.coordinates
    const shouldDrawSegment = !(segment.levelDifference && segment.waypoints.every(R.prop('isPortal')))
    const cookedSegment = {
      levelId: startWaypoint.position.structureId,
      ordinalId: `ordinal: ${startWaypoint.position.ordinal}`,
      coordinates,
      shouldDrawSegment
    }

    const badges = []
    if (SEGMENT_CATEGORIES.WALKING_TO_PORTAL === segment.segmentCategory) {
      const nextSegment = rawSegments[index + 1]
      badges.push({
        canonicalName: `wayfinding.${nextSegment.segmentCategory}`,
        coordinates: R.last(coordinates)
      })
    } else if (SEGMENT_CATEGORIES.START !== segment.segmentCategory) {
      badges.push({
        canonicalName: `wayfinding.${segment.segmentCategory}`,
        coordinates: R.last(coordinates)
      })
    }
    cookedSegment.badges = badges
    cookedSegment.segmentType = getSegmentType(segment)
    if (segment.poiId)
      cookedSegment.poiId = segment.poiId
    return cookedSegment
  })

  const steps = getSteps(rawSegments, R.prop('title', fromEndpoint), R.prop('title', toEndpoint), floorIdToNameMap, T, queueTypes, requiresAccessibility)

  return { segments, steps }
}

export { buildSegments }
