import { rgba } from 'polished'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Icon } from '../../../../../src/ui/icons/Icon.jsx'
import { withModalWindow } from '../../../../../src/ui/modals/ModalWindowMol.jsx'
import { getThemeVal } from '../../../../../src/utils/styled-component-tools.js'
import { ButtonBase } from '../styles.js'

import StepView from './StepView.jsx'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  width: 100%;

  .-desktop & {
    width: 351px;
  }
`

const NextStepButton = styled(ButtonBase)`
  background-color: ${({ theme, disabled }) => disabled ? rgba(theme.colors.primaryButton, 0.3) : theme.colors.primaryButton};
  border: none;
  border-radius: ${getThemeVal('radii.lg', '8px')};
  color: ${({ theme }) => theme.colors.primaryButtonText};
  color: ${getThemeVal('colors.primaryButtonText', '#2266DD')}
  margin-top: 8px;
  &:not([disabled]):hover {
    background-color: ${({ theme }) => theme.colors.primaryButtonHover};
  }

  .-desktop & {
    margin-bottom: 32px;
  }
`

const PickerSteps = {
  SECURITY: 'security', IMMIGRATION: 'immigration'
}

const LanePickerView = ({ widgetState, isDesktop, T, onSubmitClick, onModalDismiss, setModalBackButtonAction, setModalDismissAction }) => {
  const [state, setWidgetState] = useState(widgetState.getState())
  const [step, setStep] = useState(PickerSteps.SECURITY) // set initial step to security check
  const [hasImmigrationCheck, setHasImmigrationCheck] = useState(state.immigrationLanes && state.immigrationLanes.length)

  useEffect(() => widgetState.addCallback(setWidgetState))

  useEffect(() => {
    setModalDismissAction(onModalDismiss)
  }, [])

  useEffect(() => {
    setHasImmigrationCheck(state.immigrationLanes && state.immigrationLanes.length)
  }, [state.immigrationLanes])

  const onSecurityLaneClick = (id) => {
    const newLanes = state.securityLanes.map(type => {
      if (type.id === id) type.default = !type.default
      return type
    })
    setWidgetState({ ...state, securityLanes: newLanes })
  }

  const onImmigrationLaneClick = (id) => {
    const newLanes = state.immigrationLanes.map(type => {
      if (type.id === id) type.default = !type.default
      return type
    })
    setWidgetState({ ...state, immigrationLanes: newLanes })
  }

  const onNextStepClick = () => {
    setStep(PickerSteps.IMMIGRATION)
    setModalBackButtonAction(onPrevStepClick)
  }

  const onPrevStepClick = () => {
    setStep(PickerSteps.SECURITY)
    setModalBackButtonAction(null)
  }

  const securityLaneView = <StepView
    title={T('getDirectionsFromTo:You’ll be passing through Security')}
    subtitle={T('getDirectionsFromTo:To find your quickest route, please select the security categories you are able to pass through.') + ' ' + T('getDirectionsFromTo:Security wait times are an approximation.')}
    categories={state.securityLanes}
    onCategoryClick={onSecurityLaneClick}
  />

  const immigrationLaneView = <StepView
    title={T('getDirectionsFromTo:You’ll be passing through Immigration and Customs')}
    subtitle={T('getDirectionsFromTo:To find the quickest route, please select the immigration and customs categories you are able to pass through.') + ' ' + T('getDirectionsFromTo:Security wait times are an approximation.')}
    categories={state.immigrationLanes}
    onCategoryClick={onImmigrationLaneClick}
  />

  let view = securityLaneView

  switch (step) {
    case PickerSteps.SECURITY:
      view = securityLaneView
      break
    case PickerSteps.IMMIGRATION:
      view = immigrationLaneView
      break
  }

  const getNextStepButton = () => {
    const hasSecurityChecked = state.securityLanes.some(el => el.default === true)
    const hasImmigrationChecked = !hasImmigrationCheck ? true : state.immigrationLanes.some(el => el.default === true) // set to true if immigration check is not required

    if (step !== PickerSteps.IMMIGRATION && hasImmigrationCheck)
      return <NextStepButton
        onClick={onNextStepClick}
        disabled={!hasSecurityChecked}>
        {T('getDirectionsFromTo:Next')}
      </NextStepButton>

    return <NextStepButton
      onClick={onSubmitClick}
      disabled={!(hasSecurityChecked && hasImmigrationChecked)}
      data-cy='GetMyRouteButton'>
      {T('getDirectionsFromTo:Get My Route')}
    </NextStepButton>
  }
  const nextStepButton = getNextStepButton()

  return <Wrapper>
    <Icon id="security" width={48} height={48} title={T('attractLoop:Security')}/>
    {view}
    {nextStepButton}
  </Wrapper>
}

export default withModalWindow(LanePickerView, '[data-cy=GetMyRouteButton]')
