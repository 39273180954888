import { rgba } from 'polished'
import React, { useState } from 'react'
import styled from 'styled-components'

import TextButton from '../../../../../src/ui/buttons/TextButton.jsx'
import { getThemeColor } from '../../../../../src/utils/styled-component-tools.js'
import HandlerContext from '../handlerContext.js'

const Wrapper = styled.div`
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 22px;
  margin: ${({ mobile }) => mobile ? `16px -12px 104px` : `16px -12px 24px`};
  border-top: 1px solid ${getThemeColor('primary', '#2266DD', 0.1)};
`

const StyledTextButton = styled(TextButton)`
  width: 100px;
  margin: 0 10px 0 0px;
  font-size: 14px !important;
  text-decoration: none;
  font-weight: 500;
  color: ${getThemeColor('primary', '#2266DD')};
  border-radius: 2px;
  padding: 10px;
  transition: color background-color 80ms ease-in;

  &:active:hover {
    background-color: white;
    color: white;
    background-color: ${getThemeColor('primary', '#2266DD')};
    
  }
  :focus-visible {
    outline: 2px solid ${getThemeColor('primary', '#2266DD')};
  }
  :hover {
  background-color: ${getThemeColor('primary', '#2266DD', 0.1)};
  color: ${getThemeColor('primaryButton', '#2266DD')};
  }
`

const StyledText = styled.h3`
  font-size: 12px !important;
  font-weight: 600 !important;
`

const ButtonWrapper = styled.div`
  width: 100px;
  position: relative;
  left: -12px;
  display: flex;
  justify-content: space-between;
`
const PoiFeedback = ({ poi, T, hidePoiFeedback, venueId, mobile }) => {
  const [hasResponsed, setHasResponded] = useState(false)

  const responseClickHandler = () => {
    setTimeout(() => setHasResponded(true), 400)
  }

  if (hidePoiFeedback?.includes('*') || hidePoiFeedback?.includes(poi.category)) return null

  return (
    <HandlerContext.Consumer>
      {ctx =>
        <Wrapper data-cy='PoiFeedbackWrapper' mobile={mobile} aria-label={T('poiView:Is this information correct?')}>
          {!hasResponsed
            ? <>
              <StyledText data-cy='PromptText'>{T('poiView:Is this information correct?')}</StyledText>
              <ButtonWrapper>
                <StyledTextButton
                  data-cy='FeedbackButton'
                  onClick={() => {
                    responseClickHandler()
                    ctx.submitPoiFeedback('yes', poi, venueId)
                  }}>{T('poiView:Yes')}</StyledTextButton>
                <StyledTextButton
                  data-cy='FeedbackButton'
                  onClick={() => {
                    responseClickHandler()
                    ctx.submitPoiFeedback('no', poi, venueId)
                  }}>{T('poiView:No')}</StyledTextButton>
              </ButtonWrapper>
            </>
            : <StyledText role="alert" data-cy='ResponseText'>{T('poiView: Thank you for your feedback!')}</StyledText>
          }
        </Wrapper>
      }
    </HandlerContext.Consumer>
  )
}

export default PoiFeedback
