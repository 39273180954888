import React from 'react'
import styled, { withTheme } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: ${({ headerBGColor }) => headerBGColor};
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
`

const StyledLogo = styled.img`
  margin-left: 20px;
  margin-top: 14px;
  margin-bottom: 14px;
  height: 34px;
  display: block;
`

const Link = styled.a`
  color: ${({ buttonTextColor }) => buttonTextColor};
  ${({ theme }) => theme.fontSize('16px')};
  margin: auto 10px;
  text-decoration: none;
`

const LinkWrapper = styled.div`
  margin: auto 19px auto auto;
`

export const Header = withTheme(({ theme, logoIconUrl, logoLinkUrl, linkUrls, headerBGColor, buttonTextColor }) => {
  return <Wrapper headerBGColor={headerBGColor || theme.colors.primary}>
    <a href={logoLinkUrl} target="_blank">
      <StyledLogo src={logoIconUrl} alt='logo'/>
    </a>
    <LinkWrapper>
      {linkUrls ? generateTextLinks(linkUrls, buttonTextColor || theme.colors.primaryButtonText) : null}
    </LinkWrapper>
  </Wrapper>
})

const generateTextLinks = (linkUrls, buttonTextColor) => linkUrls.map(
  ({ text, url, target, preserveState }) => {
    // Was going to try and preserve state, but would require click-time URL changes, not render time.
    // if (preserveState)
    //   url += location.search.includes('?') ? '&s=' : '?s=' + queryString.parse(location.search).s
    return <Link href={url} target={ target || '_blank'} key={`text_link_${text}`} buttonTextColor={buttonTextColor}>{text}</Link>
  })
