function WebGeoLocationProvider () {
  let watchId = null

  function start (cb, err) {
    if (!navigator || !navigator.geolocation || !navigator.geolocation.watchPosition)
      throw Error('GeoLocation API unavailable.')
    if (watchId)
      stop() // clear previous watch
    watchId = navigator.geolocation.watchPosition(position => {
      const { latitude, longitude, altitude, accuracy, altitudeAccuracy, heading, speed, floorLevel } = position.coords // eslint-disable-line no-unused-vars
      cb(
        position.timestamp,
        latitude,
        longitude,
        accuracy,
        floorLevel,
        'CoreLocation'
      )
    },
    err,
    {
      enableHighAccuracy: true,
      timeout: 10000 // timeout in 10 seconds
    }
    )
  }

  function stop () {
    navigator.geolocation.clearWatch(watchId)
    watchId = null
  }

  return {
    start,
    stop
  }
}

export default WebGeoLocationProvider
