import FlexSearch from 'flexsearch'

const NON_ASCII_LANGUAGES = ['ko', 'ja', 'zh-Hans', 'zh-Hant']

const getFlexSearchInstance = ({ lang, type = 'standard' }) => {
  const options = {
    tokenize: 'forward',
    rtl: lang === 'ar', // only for arabic
    stemmer: {
      s: '',
      es: '',
      ies: 'y'
    }
  }

  // Use the full tokenizer for non-ASCII languages and for standard searching
  // but use "forward" for typeahead
  if (NON_ASCII_LANGUAGES.includes(lang))
    options.tokenize = 'full'

  return new FlexSearch.Index(options)
}

export { getFlexSearchInstance }
