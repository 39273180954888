import React, { useEffect, useState } from 'react'

import DirectionsSearchControlsView from './DirectionsSearchControlsView.js'
import DirectionsSearchControlsViewMobile from './DirectionsSearchControlsViewMobile.js'
import DirectionsSearchControlsViewMulti from './DirectionsSearchControlsViewMulti.jsx'

const DirectionsSearchControls = ({
  handleInputSelect,
  onInputClearButtonClick,
  handleSwitchInputsButtonClicked,
  handleKeyPress,
  widgetState,
  logoUrl,
  logoLinkUrl,
  clientName,
  isDesktop,
  handleInputValueChange,
  onBackButtonClicked,
  multipointRouting,
  handleSearchInputsChange,
  T
}) => {
  const [state, setWidgetState] = useState(widgetState.getState())
  useEffect(() => widgetState.addCallback(setWidgetState))

  return multipointRouting
    ? <DirectionsSearchControlsViewMulti {...state}
      logoUrl={logoUrl}
      logoLinkUrl={logoLinkUrl}
      clientName={clientName}
      onInputSelect={handleInputSelect}
      onInputClearButtonClick={onInputClearButtonClick}
      onSingleInputValueChange={handleInputValueChange}
      onBackButtonClicked={onBackButtonClicked}
      onSwitchInputsButtonClicked={handleSwitchInputsButtonClicked}
      handleKeyPress={handleKeyPress}
      multipointRouting={multipointRouting}
      isMobile={!isDesktop()}
      handleSearchInputsChange={handleSearchInputsChange}
      T={T}
      widgetState={widgetState}
    />
    : isDesktop()
      ? <DirectionsSearchControlsView {...state}
        logoUrl={logoUrl}
        logoLinkUrl={logoLinkUrl}
        clientName={clientName}
        onInputSelect={handleInputSelect}
        onInputCloseButtonClick={onInputClearButtonClick}
        onInputChange={handleInputValueChange}
        onBackButtonClicked={onBackButtonClicked}
        onSwitchInputsButtonClicked={handleSwitchInputsButtonClicked}
        handleKeyPress={handleKeyPress}
        T={T}
      />
      : <DirectionsSearchControlsViewMobile {...state}
        onInputSelect={handleInputSelect}
        onInputCloseButtonClick={onInputClearButtonClick}
        onInputChange={handleInputValueChange}
        onBackButtonClicked={onBackButtonClicked}
        onSwitchInputsButtonClicked={handleSwitchInputsButtonClicked}
        handleKeyPress={handleKeyPress}
        T={T}
      />
}

export default DirectionsSearchControls
