import LLIcon from '@locus-labs/mol-desktop-icon'
import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'

import { Icon } from '../../../../../src/ui/icons/Icon.jsx'
import { getThemeVal } from '../../../../../src/utils/styled-component-tools.js'
import { PrimaryLabel, SecondaryLabel } from '../styles.js'

const DivAsCheckbox = styled.div`
  position: relative;
  height: 52px;
  font-size: ${getThemeVal('fonts.size.h2', '18px')};
  font-weight: ${getThemeVal('fontWeights.normal', '500')};
  color: ${getThemeVal('colors.primaryButton', '#2266DD')};
  overflow: hidden;
  border: 1px solid ${getThemeVal('colors.primaryButton', '#2266DD')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  padding: 4px 18px 4px 4px;
  border-radius: ${getThemeVal('radii.lg')};
  &[aria-checked=true] {
    background-color: ${({ theme }) => rgba(theme.colors.primaryButton, 0.1)};
  }
  &:focus-visible {
    background-color: ${({ theme }) => rgba(theme.colors.primaryButton, 0.1)};
    outline: 1px solid ${getThemeVal('colors.primaryButton', '#2266DD')};
  }
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => rgba(theme.colors.primaryButton, 0.1)};
  }
`

const CheckIconWrapper = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  left: 16px;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${getThemeVal('colors.secondary')};
  background-color: ${({ selected }) => selected ? getThemeVal('colors.primaryButton') : getThemeVal('colors.widgetBackground') };
  & svg {
    fill: white;
  }
`

const ImageIconWrapper = styled.div`
  height: 40px;
  width: 40px;
  padding-top: 6px;
  margin-left: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: none;
`

const LaneTypeArea = ({ default: isSelected, displayText, id, imageId, onClickAction }) => {
  const onKeyDownHandler = (e, id) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      onClickAction(id)
    }
  }

  return (
    <li onClick={() => onClickAction(id)} >
      <DivAsCheckbox data-cy={id} role='checkbox' aria-label={displayText} aria-checked={isSelected} onKeyDown={(e) => onKeyDownHandler(e, id)}tabIndex={0}>
        <CheckIconWrapper selected={isSelected}>
          { isSelected &&
          <LLIcon name='tick' iconSize='sm' />
          }
        </CheckIconWrapper>
        { imageId
          ? <ImageIconWrapper>
            <Icon id={imageId} />
          </ImageIconWrapper>
          : displayText
        }
      </DivAsCheckbox>
    </li>
  )
}

const PrimaryLabelStyled = styled(PrimaryLabel)`
  margin: 16px 0;
  && {
    font-weight: 500;
  }
  .-desktop & {
    margin: 28px 0 16px;
  }
`
const StyledGroup = styled.div`
  width: 100%;
`
const LaneTypePickerUL = styled.ul`
  margin: 16px 0;
  padding: unset;
  box-shadow: none;
  list-style-type: none;
`

const StepView = ({ title, subtitle, categories, onCategoryClick }) => (
  <>
    <PrimaryLabelStyled data-cy='LanePickerTitle' id='ModalBoxTitle'>
      {title}
    </PrimaryLabelStyled>
    <SecondaryLabel>
      {subtitle}
    </SecondaryLabel>
    <StyledGroup role='group' aria-label='security lane choices'>
      <LaneTypePickerUL>
        {categories && categories.map(type => <LaneTypeArea key={type.id} {...type} onClickAction={onCategoryClick} />)}
      </LaneTypePickerUL>
    </StyledGroup>
  </>)

export default StepView
