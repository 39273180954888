import { RouteType } from './constants.js'

let defaultRouteAccessibilityChoice = RouteType.DIRECT

const setDefaultRouteAccessibilityChoice = routeAccessibilityChoice => {
  defaultRouteAccessibilityChoice = routeAccessibilityChoice
}

const getDefaultState = () => ({
  endpoints: [],
  endpointIds: [],
  currentStep: 0,
  currentStop: 0,
  steps: [[]],
  directRoutes: [],
  accessibleRoutes: [],
  times: [],
  distances: [],
  isNavigating: false,
  routeTypes: 'distinct',
  routeAccessibiltyChoice: defaultRouteAccessibilityChoice, // state of UI button selection direct/accessible
  showLevelName: false,
  currentLevelName: null,
  oldTimeout: null,
  currentInputIndex: 0,
  searchInputs: [{}, {}], // Whenever state is reset there is always a 'from' and a 'to'
  hasLoaded: false,
  isRouteUnavailable: false,
  wasRoutePreviouslyDisplayed: false,
  wereLanesPicked: false,
  triggerShowDirections: true
})

export {
  getDefaultState,
  setDefaultRouteAccessibilityChoice
}
