import React from 'react'
import styled from 'styled-components'

import { Icon, ColoredIcon, ShadowedIcon } from './Icon.jsx'

const StyledIcon = styled.div`
  border-radius: 50%;
  background-clip: padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  background-color: ${({ backgroundColor = 'inherit' }) => backgroundColor};
  width: ${({ iconSize }) => iconSize}px;
  height: ${({ iconSize }) => iconSize}px;
`

const BaseIcon = ({ backgroundColor, id, iconSize, svgSize, castShadow = false, fillColor, ...omittedProps }) => {
  const IconElem = castShadow ? ShadowedIcon : Icon
  return <StyledIcon backgroundColor={backgroundColor} iconSize={iconSize} data-cy='CircleIcon'>
    {fillColor
      ? (
        <ColoredIcon id={id} width={svgSize} height={svgSize} fillColor={fillColor} {...omittedProps}/>
      )
      : (
        <IconElem id={id} width={svgSize} height={svgSize} {...omittedProps}/>
      )
    }
  </StyledIcon>
}

const CircleIcon = (props) => { return <BaseIcon iconSize={props.size || 48} svgSize={props.size ? props.size : 30} {...props} /> }
const BigCircleIcon = (props) => { return <BaseIcon iconSize={80} svgSize={80} {...props} /> }

export { CircleIcon, BigCircleIcon }
