import ZoomControl from '@locus-labs/mol-desktop-zoom-control'
import React from 'react'

import { widgetEnum } from '../../../../utils/constants.js'

function create (app) {
  const init = () => { }
  const handleKeyboardSelect = (event) => {
    if (event.key !== 'Enter' && event.key !== ' ') return

    const zoomControl = event.target.closest('[data-cy="ZoomControl"]')
    if (!zoomControl) return

    const zoomInButton = zoomControl.querySelector('[name="zoom.in"]').closest('button')
    const zoomOutButton = zoomControl.querySelector('[name="zoom.out"]').closest('button')

    if (event.target === zoomInButton) app.bus.send('map/zoomIn')
    else if (event.target === zoomOutButton) app.bus.send('map/zoomOut')
  }

  app.bus.send('layers/register', {
    id: 'mapZoom',
    widget: () => <ZoomControl
      onKeyDown={handleKeyboardSelect}
      data-cy='ZoomControl'
      onZoomIn={() =>
        app.bus.send('map/zoomIn')
      }
      onZoomOut={() => app.bus.send('map/zoomOut')}
      zoomInTooltipContent={app.gt()('mapZoomOnline:Zoom In')}
      zoomOutTooltipContent={app.gt()('mapZoomOnline:Zoom Out')}
    />,
    show: true,
    isOverlay: true,
    layoutId: 'buttonOptions',
    widgetType: widgetEnum.Desktop,
    order: 30
  })

  return {
    init
  }
}

export {
  create
}
