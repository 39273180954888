import MaplibreGL from 'maplibre-gl'
import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'

const MapContainerDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

function MapComponent ({ onInit, viewLimits }) {
  const ref = useRef(null)
  const [map, setMap] = useState(null)
  useEffect(() => {
    if (ref.current && !map) {
      const map = new MaplibreGL.Map({
        container: ref.current,
        attributionControl: false,
        ...viewLimits
      })
      setMap(map)
      onInit(map)
    }
  }, [ref, map])
  return <MapContainerDiv ref={ref} />
}

export default MapComponent
