import React, { useState } from 'react'
import styled from 'styled-components'

import TextButton from '../../../../../src/ui/buttons/TextButton.jsx'

const Wrapper = styled.div`
  ${({ theme }) => theme.fontSize('16px')};
  line-height: 150%;
  color: #333;
  margin: 16px 0;
  display: flex;
  white-space: pre-wrap;
`
const getSentences = (description) => {
  /* sentence defined as ending with ".?!", followed by whitespace, followed by capitalized letter
  extra checks are included for if Dr. Mr. or other common honorifics exist and length < 40 characters */

  const regex = /(?!\b\w\.)\s*[.!?]\s+(?=[A-Z])/g
  const sentences = description.split(regex)

  if (sentences.length <= 1) return sentences // if no separate sentences just use the description as is

  const descriptionBySentences = sentences.map(sentence => sentence.trim()) // remove extra whitespace
  const punctuation = description.match(regex).map(punc => punc.trim())

  // add the punctuation back to the sentences if it exists
  punctuation.forEach((punc, index) => {
    descriptionBySentences[index] = descriptionBySentences[index] + `${punc}`
  })

  // check that minimum length of 40 characters for first sentence is met
  if (descriptionBySentences[0].length <= 39) {
    const combinedSentences = descriptionBySentences.slice(0, 2).join(' ')
    return [combinedSentences, ...descriptionBySentences.slice(2)]
  }

  // check specifically for ending a sentence with Mr. Mrs. Ms. Dr. or Bros. and combine with next sentence
  const honorificsRegex = /(Mr\.|Mrs\.|Ms\.|Dr\.|Bros\.)$/
  if (honorificsRegex.test(descriptionBySentences[0])) {
    const combinedSentences = descriptionBySentences.slice(0, 2).join(' ')
    return [combinedSentences, ...descriptionBySentences.slice(2)]
  }

  return descriptionBySentences
}

const PoiDescription = ({ poi, T }) => {
  const [isOpen, setIsOpen] = useState(false)

  if (!poi || !poi.description) return null

  const descriptionBySentences = getSentences(poi.description)
  const initialDescription = descriptionBySentences.length ? descriptionBySentences[0] : poi.description // If no full sentence was found, then use whole description as initial one
  return <Wrapper>
    <span>
      {isOpen ? poi.description : initialDescription}
      {descriptionBySentences && descriptionBySentences.length > 1 &&
        <TextButton onClick={(e) => {
          e.stopPropagation()
          setIsOpen(!isOpen)
        }}>
          {isOpen ? T('poiView:Show less') : T('poiView:Show more')}
        </TextButton>}
    </span>
  </Wrapper>
}

export default PoiDescription
export { getSentences }
