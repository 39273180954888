import styled from 'styled-components'

import { FlatButton, StandardButton } from '../../../../../src/ui/buttons/Button.jsx'
import { Icon, ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'

export const RouteInfo = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  padding: 14px 12px 6px;
  margin-bottom: 10px;
  align-items: center;

  .label {
    display: block;
    color: ${({ theme }) => theme.colors.secondaryText};
    ${({ theme }) => theme.fontSize('14px')};
  }

  .info {
    color: ${({ theme }) => theme.colors.primaryText};
    ${({ theme }) => theme.fontSize('20px')};
    font-weight: 500;
    line-height: 1.3em;
  }

  .info-secondary {
    color: ${({ theme }) => theme.colors.secondaryText};
    ${({ theme }) => theme.fontSize('12px')};
    padding-left: 5px;

    &--accent {
      color: ${({ theme }) => theme.colors.primary};
      padding: 5px 0 0;
      margin-left: -5px;
      font-weight: 500;
    }
  }
`

export const ButtonsContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
`

export const StyledFlatButton = styled(FlatButton)`
  display: flex;
  flex: 1 0 0;
  align-self: center;
  margin: 0 7px;
  height: 40px;
  ${({ theme }) => theme.fontSize('14px')};
  padding: 0 8px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

export const StepIconWrapper = styled.div`
  width: 36px;
  margin-right: 12px;
  text-align: center;

  ${Icon} {
    vertical-align: middle;
  }
`

export const MobileStandardButton = styled(StandardButton)`
  width: 169px;
  height: 40px;
  flex-shrink: 0;
`

export const MobileStandardSecondaryButton = styled(MobileStandardButton)`
  background-color: ${({ theme }) => theme.colors.secondaryButton};
  color: ${({ theme }) => theme.colors.secondaryButtonText};

  ${ColoredIcon} {
    fill: ${({ theme }) => theme.colors.secondaryButtonText} ;
  }

  &:not([disabled]):hover {
    background-color: ${({ theme }) => theme.colors.secondaryButton};

    .-desktop & {
      background-color: ${({ theme }) => theme.colors.secondaryButtonHover};
    }
  }
`
