const SEARCH_VIEW_BODY_ID = 'SearchResultsBody'
const SEARCH_VIEW_CATEGORY_LIST_ID = 'SearchResultsCategoryList'
const SEARCH_VIEW_TOGGLER = 'SearchResultsToggler'

const MAX_UI_RESULTS_LENGTH = 50

const ToggleViewState = {
  SWITCH_TO_MAP: 1,
  SWITCH_TO_LIST: 2
}

export {
  SEARCH_VIEW_BODY_ID,
  SEARCH_VIEW_CATEGORY_LIST_ID,
  SEARCH_VIEW_TOGGLER,
  MAX_UI_RESULTS_LENGTH,
  ToggleViewState
}
