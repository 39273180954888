import { DynamicSizeList as List } from '@john-osullivan/react-window-dynamic-fork'
import { rgba } from 'polished'
import { curry } from 'ramda'
import React, { useRef } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import styled from 'styled-components'

const StyledList = styled(List)`
  &::-webkit-scrollbar {
    display: none;
  }

  div > .poiListWrapper:hover {
    background-color: ${({ theme }) => rgba(theme.colors.primary, 0.05)};
  }

  &::before {
    content: '';
    position: sticky;
    top: 0;
    height: 10px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
    z-index: 1;
    width: ${({ width }) => `${width}px`};
    transform: translateY(-10px);
  }
`

const Wrapper = styled.div`
  height: 100vh;
  min-height: 10em;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const Divider = styled.div`
  height: 1px;
  margin: 0 14px;
  width: auto;
  background: #EBEBEB;

  &:first-of-type { 
    display: none;
  }
`

export const SectionTitle = styled.div`
  background: ${({ theme }) => rgba(theme.colors.primary, 0.05)};
  font-weight: 500;
  ${({ theme }) => theme.fontSize('14px')};
  line-height: 22px;
  color: ${({ theme }) => theme.colors.primaryText};
  text-transform: uppercase;
  padding: 7px 16px;    
  box-shadow: 0px 1px 0px #EBEBEB, 0px -1px 0px #EBEBEB;
`

export const Row = styled.div`
  border-top: 1px solid #EBEBEB;
  &:first-of-type{
    border-top: none;
  }
`

export const createListItem = curry((type, value) => ({ type, value }))

export const sectionTitleRow = sectionTitle =>
  <SectionTitle>{sectionTitle}</SectionTitle>

export const ResultList = ({ items, className }) => {
  const componentRef = useRef(null)

  const createRow = ({ data, index, style }, ref) => {
    return <Row ref={ref} style={style} key={index}>
      {data[index]}
    </Row>
  }

  return <Wrapper className={className}>
    <AutoSizer>
      {({ width, height }) =>
        <StyledList
          height={height}
          key={items.length}
          width={width}
          itemCount={items.length}
          itemData={items}
          ref={componentRef}
        >
          {React.forwardRef(createRow)}
        </StyledList>
      }
    </AutoSizer>
  </Wrapper>
}
