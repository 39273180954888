import { rgba } from 'polished'
import React, { useState, useEffect } from 'react'
import styled, { withTheme } from 'styled-components'

import { ColoredIcon } from '../../../../src/ui/icons/Icon.jsx'

const FabButton = styled.button`
  cursor: pointer;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  outline: none;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  &:focus-visible {
    outline: 1px solid orange;
    outline-offset: 2px;
  }
`

const FollowMeCaption = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.colors.toolTipText};
  font-size: 14px;
  line-height: 16px;
  right: 0;
  top: -32px;
  white-space: nowrap;
  padding: 6px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  background: ${({ theme }) => theme.colors.toolTipBackground};
  border-radius: 4px;
`

// widgetState properties:
//  isMonitoring (true if device supports and user has allowed)
//  hasLocation (true if we have a "current" location within venue)
//  isFollowing (map is tracking bluedot - implies other two are true)

const FollowMeButton = withTheme(({ widgetState, onClick, theme }) => {
  const [state, setState] = useState(widgetState.getState())
  useEffect(() => widgetState.addCallback(setState))

  // FollowingMe button states (from spec)
  //   Active - when the user position is available and isFollowing.
  //   Unfocused - when the user position is available and !isFollowing
  //   Inactive - When the user position was available but was lost (isMonitoring && !hasLocation)
  //   Unavailable - When the user position was never available during this session (!isMonitoring)

  const buttonStatus =
    !state.isMonitoring
      ? 'Unavailable'
      : !state.hasLocation
        ? 'Inactive'
        : state.isFollowing
          ? 'Active'
          : 'Unfocused'

  const color = buttonStatus === 'Active' || buttonStatus.status === 'Unavailable'
    ? theme.colors.primaryButton // blue
    : buttonStatus === 'Inactive'
      ? theme.colors.secondaryText // gray
      : rgba(theme.colors.secondaryText, 0.5) // v. light gray ("Unfocused")

  const iconName = buttonStatus === 'Unavailable'
    ? 'my-location-arrow-stroke'
    : 'my-location-arrow'

  return (
    <FabButton onClick={onClick} data-cy='FollowMeButton' aria-label={'Follow Me'}>
      { widgetState.getState().followMeCaption &&
        <FollowMeCaption>{widgetState.getState().followMeCaption}</FollowMeCaption>}
      <ColoredIcon id={iconName} fillColor={color} height={32} width={32} />
    </FabButton>
  )
})

export default FollowMeButton
