import { rgba } from 'polished'
import * as PropTypes from 'prop-types'
import React, { useRef, useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'

import { handleKeyboardSelect } from '../../../plugins/utils/keyboardUtils.js'
import { ColoredIcon } from '../../../src/ui/icons/Icon.jsx'
import ToolTipComponent from '../tooltips/ToolTipComponent.jsx'

const InputWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 4px 4px 4px 12px;
  position: relative;
  display: flex;
  box-sizing: border-box;
  background-color: ${({ theme }) => rgba(theme.colors.widgetText, 0.1)};
  ${({ theme }) => theme.borderRadius('8')};
  line-height: 150%;
  min-height: 40px;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  .-desktop & {
    padding: 6px 4px 6px 12px;
    min-height: 44px;
  }

  input {
    flex: 1 1 auto;
    min-width: 0;
    ${({ theme }) => theme.fontSize('16px')};
    line-height: 150%;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.widgetText};
    border: 0;
    display: block;
    background-color: transparent;
    text-overflow: ellipsis;
    padding: 0;

    &:focus:not([readonly]) {
      box-shadow: none;
      border-bottom: none;
    }

    &:focus {
      outline: none;
    }
  }

  div:last-child {
    position: relative;
  }
`

const InputLabel = styled.span`
  color: ${({ theme }) => rgba(theme.colors.widgetText, 0.6)};
  padding-right: 5px;
  flex: 0 0 auto;
`

const CloseButton = styled.div`
  cursor: pointer;
`

const SearchInputLabelled = ({
  autoFocus = false,
  value,
  id,
  label,
  onInputChange,
  onInputCloseButtonClick,
  onInputSelect,
  handleKeyPress,
  theme,
  className,
  T,
  'data-cy': dataCy,
  hasCloseButton = true,
  ...rest
}) => {
  const [term, setTerm] = useState(value)

  const inputRef = useRef(null)

  const handleUpdate = e => {
    if (onInputSelect)
      onInputSelect(id)
    setTerm(e.target.value)
    onInputChange(e.target.value)
  }

  const handleCloseClick = id => { // This gets attached to the 'X' icon to close the search input field
    inputRef.current.focus()
    if (onInputCloseButtonClick)
      onInputCloseButtonClick(id)
    if (onInputSelect)
      onInputSelect(id)
  }

  useEffect(() => {
    if (autoFocus && inputRef.current && id !== document.activeElement.id) {
      inputRef.current.focus()
    }
  }, [autoFocus])

  useEffect(() => {
    setTerm(value) // necessary as input value can be changed through parent (like when choosing POI from the list)
  }, [value])

  const handleKey = (e) => {
    if (handleKeyPress) handleKeyPress(e, term)
  }

  return (
    <InputWrapper data-cy={dataCy} className={className}>
      <InputLabel>{label}</InputLabel>
      <input
        ref={inputRef}
        {...rest}
        id={id}
        value={term}
        onChange={handleUpdate}
        onKeyPress={handleKey}
        aria-label={label}
        aria-autocomplete="list"
        aria-haspopup={false}
        aria-controls='SuggestedLocations'
      />
      {hasCloseButton && term.length
        ? <ToolTipComponent tooltipText={T('ui:Clear Text')} arrowPosition="top">
          <CloseButton
            className='closeButton'
            aria-label={T('ui:Clear Text')}
            role="button"
            onClick={() => handleCloseClick(id)}
            data-cy='ClearText'
            tabIndex={'0'}
            onKeyDown={handleKeyboardSelect(() => handleCloseClick(id))}>
            <ColoredIcon id="close" fillColor={theme.colors.widgetIconFill} width={32} height={32} aria-hidden={true}/>
          </CloseButton>
        </ToolTipComponent>
        : null}
    </InputWrapper>
  )
}

SearchInputLabelled.propTypes = {
  autoFocus: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onInputCloseButtonClick: PropTypes.func,
  onInputSelect: PropTypes.func,
  onInputChange: PropTypes.func.isRequired
}

SearchInputLabelled.defaultProps = {
  autoFocus: true
}

export default withTheme(SearchInputLabelled)
