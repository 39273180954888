import * as R from 'ramda'

import { getMapState, keyToLayerStyle } from './utils.js'

export default function SelectionController (app, mapInitialized) {
  const selectableLayers = []

  const updateLayers = (map, entityIds, isHiglighting = false) => {
    const isSomethingSelected = entityIds.length > 0
    selectableLayers.forEach(layer => {
      const selectedId = layer.selected.id
      const selectedFilters = map.getFilter(selectedId)[2]
      if (isHiglighting && !layer.forHighlight) {
        map.setFilter(selectedId, ['all', false, selectedFilters])
      } else {
        map.setFilter(selectedId, ['all', isSomethingSelected ? ['match', ['get', 'id'], entityIds, true, false] : false, selectedFilters])
      }
      const notSelectedLayerId = layer.notSelected.id
      const notSelectedFilters = map.getFilter(notSelectedLayerId)[2]
      map.setFilter(notSelectedLayerId, ['all', isSomethingSelected ? ['match', ['get', 'id'], entityIds, false, true] : false, notSelectedFilters])
      const nothingSelectedLayerId = layer.nothingSelected.id
      const nothingSelectedFilters = map.getFilter(nothingSelectedLayerId)[2]
      map.setFilter(nothingSelectedLayerId, ['all', !isSomethingSelected, nothingSelectedFilters])
    })
    app.bus.send('map/entitySelectionUpdated', getMapState(map))
  }

  const selectEntities = (ids) => {
    mapInitialized().then(map => {
      updateLayers(map, R.uniq(ids), false)
    })
  }

  const highlightEntities = (ids) => {
    mapInitialized().then(map => {
      updateLayers(map, R.uniq(ids), true)
    })
  }

  const initMapLayers = (layers) => {
    selectableLayers.length = 0 // clear the array
    return layers.flatMap(layer => {
      const selection = R.pathOr(null, ['metadata', 'selection'], layer)
      if (selection) {
        let selectedLayer = { ...layer, id: `${layer.id}_selected` }
        R.mapObjIndexed((value, key) => {
          const path = [keyToLayerStyle(key), key]
          selectedLayer = R.assocPath(path, value.selected, selectedLayer)
        }, selection)
        selectedLayer.filter = ['all', false, selectedLayer.filter]
        let notSelectedLayer = { ...layer, id: `${layer.id}_notSelected` }
        R.mapObjIndexed((value, key) => {
          const path = [keyToLayerStyle(key), key]
          notSelectedLayer = R.assocPath(path, value.notSelected, notSelectedLayer)
        }, selection)
        notSelectedLayer.filter = ['all', false, notSelectedLayer.filter]
        selectableLayers.push({ nothingSelected: layer, selected: selectedLayer, notSelected: notSelectedLayer, forHighlight: ['polygons', 'polygons - counters'].indexOf(layer.id) !== -1 })
        layer.filter = ['all', true, layer.filter]
        return [selectedLayer, notSelectedLayer, layer]
      }
      return [layer]
    })
  }

  app.bus.on('map/selectEntities', ({ ids }) => selectEntities(ids))

  app.bus.on('map/highlightEntities', ({ ids }) => highlightEntities(ids))

  app.bus.on('map/cleanMap', () => {
    mapInitialized().then(map => {
      updateLayers(map, [])
    })
  })

  return { initMapLayers }
}
