import { path } from 'ramda'
import React from 'react'
import { debounce } from 'throttle-debounce'

import { changeTest } from '../../../src/utils/funcs.js'
import { StateObj } from '../../../src/utils/stateObj.js'
import { widgetEnum } from '../../../utils/constants.js'

import UserMenu from './userMenu.jsx'

const USER_MENU_ID_DESKTOP = 'userMenuDesktop'
const USER_MENU_ID_MOBILE = 'userMenuMobile'

const langNames = {
  ar: 'اَلْعَرَبِيَّةُ',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  hi: 'हिंदी',
  ja: '日本語',
  fr: 'Français',
  ko: '한국어',
  pl: 'polski',
  pt: 'Português',
  'zh-Hans': '汉语',
  'zh-Hant': '漢語',
  is: 'íslenska'
}

function create (app, config) {
  const T = app.gt()

  const init = async () => {
    const { menuOptions } = widgetState.getState()

    if (!config.noLangOptions) {
      const venueData = await app.bus.get('venueData/getVenueData')
      const venueList = venueData.venueList
      const myVenueCode = venueList[venueData.id].venueCode
      // Display a list of alternate language venues for the current venueId by looking through the v5 json file
      Object.values(venueList)
        .filter(venue => venue.venueCode === myVenueCode) // only show for same venue
        .filter(venue => venue.id !== venueData.id) // remove the currently showing venue
        .map(venue => ({ lang: venue.locale, label: langNames[venue.locale] || venue.locale, vid: venue.id })) // convert to venueOptions object
        .sort((a, b) => a.label < b.label ? -1 : 1) // sort by the label
        .forEach(venueOption => {
          menuOptions.unshift({
            iconId: 'language',
            id: `lang_${venueOption.lang}`,
            label: venueOption.label,
            onClick: async () => {
              app.bus.send('appInsights/log', { name: `language change click ${venueOption.lang}`, properties: { pageLocation: 'sub menu' } })
              const state = await app.bus.get('deepLinking/getEncodedState')
              location.href = `${location.origin}${location.pathname}?lang=${venueOption.lang}&vid=${venueOption.vid}&s=${state}`
            }
          })
        })
    }

    if (path(['config', 'debug', 'darkModeOption'], app)) {
      const onClick = () => {
        app.bus.send('appInsights/log', { name: 'dark mode toggle click', properties: { pageLocation: 'sub menu' } })
        app.bus.send('map/toggleDarkMode')
      }
      menuOptions.push({ iconId: 'reset', label: 'Toggle Dark Mode', onClick })
    }

    if (path(['config', 'plugins', 'userMenu', 'a11yUrl'], app)) {
      menuOptions.push({
        iconId: 'accessibility',
        label: T('userMenu:Accessibility'),
        onClick: () => {
          app.bus.send('appInsights/log', { name: 'a11y click', properties: { pageLocation: 'sub menu' } })
          window.open(path(['config', 'plugins', 'userMenu', 'a11yUrl'], app), '_blank')
        }
      })
    }

    widgetState.update({ menuOptions })
  }

  /*
    Note: For mobile, hiding and showing the menu is controlled via hiding/showing this plugin, while the ellipse
      hide/show toggle is shown in the headerOnline module. For desktop, this plugin is always showing, and renders
      the ellipse menu button itself - and hiding/showing the menu is controlled via the setIsMenuOpen state
      variable (called only within its own UI)
  */

  const options = [
    {
      iconId: 'reset',
      label: T('userMenu:Reset Map'),
      onClick: () => {
        app.bus.send('appInsights/log', { name: 'reset map click', properties: { pageLocation: 'sub menu' } })
        app.bus.send('app/reset')
      }
    },
    {
      iconId: '2d',
      id: 'pitchToggler',
      label: T('userMenu:Switch Map View'),
      onClick: () => {
        app.bus.send('appInsights/log', { name: 'toggle view click', properties: { pageLocation: 'sub menu' } })
        app.bus.send('pitchToggler/toggle')
      }
    }
  ]

  const widgetState = new StateObj({
    menuOptions: [...options],
    isMobileMenuOpen: false
  })

  app.bus.send('layers/register', {
    id: USER_MENU_ID_DESKTOP,
    widget: () => <UserMenu
      isDesktop={app.env.isDesktop()}
      widgetState={widgetState}
      T={app.gt()}
    />,
    show: true,
    isOverlay: true,
    layoutId: 'buttonOptions',
    widgetType: widgetEnum.Desktop,
    order: 50
  })

  app.bus.send('layers/register', {
    id: USER_MENU_ID_MOBILE,
    widget: () => <UserMenu
      isDesktop={app.env.isDesktop()}
      widgetState={widgetState}
      T={app.gt()}
    />,
    isOverlay: true,
    layoutId: 'headerOnline',
    widgetType: widgetEnum.Mobile,
    shouldShow: currentlyShowing => currentlyShowing.includes('headerOnlineHome')
  })

  const pitchChange = changeTest()
  const update2d3dMenuOption = debounce(1000, pitch => {
    if (pitchChange(pitch)) // possibly change view icon if pitch changes 2d/3d
      replaceOption({
        id: 'pitchToggler',
        iconId: pitch === 0 ? '3d' : '2d'
      })
  })

  // pass in an option override object and this will find the option with the matching ID
  // and replace it with any overrides specified in this option.
  const replaceOption = option => {
    const { menuOptions } = widgetState.getState()
    const moIndex = menuOptions.findIndex(mo => option.id === mo.id)
    if (moIndex >= 0) {
      menuOptions[moIndex] = { ...menuOptions[moIndex], ...option }
      widgetState.update({ menuOptions: [...menuOptions] })
    }
  }

  const addMenuItem = ({ iconId, id, label, onClick }) => {
    const { menuOptions } = widgetState.getState()
    const option = { iconId, id, label }
    option.onClick = () => {
      const onClickReturn = onClick(option) // pass the option object into the onClick - could be handy!
      if (onClickReturn) // if an object is returned, use to override this option
        replaceOption({ ...option, ...onClickReturn })
    }
    menuOptions.push(option)
    widgetState.update({ menuOptions })
  }

  app.bus.on('map/moveEnd', ({ pitch }) => update2d3dMenuOption(pitch))
  app.bus.on('userMenu/addItem', addMenuItem)

  const runTest = async (initialState, testRoutine) => {
    widgetState.update({ ...initialState })
    await testRoutine()
    return widgetState.getState()
  }

  return {
    init,
    runTest
  }
}

export {
  create
}
