import { DndContext, closestCenter } from '@dnd-kit/core'
import {
  restrictToVerticalAxis
} from '@dnd-kit/modifiers'
import {
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import Logo from '@locus-labs/mol-desktop-logo'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled, { withTheme, css } from 'styled-components'

import { Button } from '../../../../../src/ui/buttons/Button.jsx'
import { ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'
import SearchInputLabelledMulti from '../../../../../src/ui/inputs/SearchInputLabelledMulti.jsx'
import ToolTipComponent from '../../../../../src/ui/tooltips/ToolTipComponent.jsx'
import { getThemeColor } from '../../../../../src/utils/styled-component-tools.js'
import { MAX_SEARCH_INPUT_LENGTH } from '../constants.js'
import AddWaypointButton from '../DirectionsControls/AddWaypointButton.jsx'
import {
  handleAddStop,
  handleDragStart,
  handleDragEnd,
  handleDragOver,
  handleRemoveClick,
  reverseDirections,
  handleClearButtonClick,
  handleMoveClick
} from '../helpers/handleInputChangeEvents.js'
import useMapSearchInputsToState from '../hooks/useMapSearchInputsToState.js'
import useOnUpdateSearchInputs from '../hooks/useOnUpdateSearchInputs.js'

import RouteOptions from './RouteOptions.jsx'
import { LevelInfo } from './styles.js'

const WidgetWrapper = styled.div`
  width: 100%;
  padding: ${({ hasLogoUrl, theme }) =>
  hasLogoUrl && theme.header.hasLogo
    ? theme.header.paddingWithLogo
    : theme.header.padding};
  ${({ isMobile }) => isMobile && `padding-bottom: 0px;` }
`

const LogoWrapper = styled.div`
  padding: 0 0 20px 0;
`
const TopButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  margin-right: ${({ isMobile }) => isMobile ? '12px' : '0px'};
  div {
    text-align: center;
    user-select: none;
    color: ${({ theme }) => theme.colors.widgetIconFill};
    ${({ theme }) => theme.fontSize('12px')};
    cursor: pointer;
    position: relative;

    &:last-child {
      margin-left: ${({ isMobile }) => (isMobile ? 'none' : 'auto')};
      position: relative;
    }

    &:hover > .tooltip {
      display: block;
      position: absolute;
      color: #fff;
      margin: 0 4px;
    }
  }

  .directionSwap {
    margin: 0px;
  }

  ${({ navInputsHidden }) => navInputsHidden && css`
    justify-content: flex-start;
  `}
`

const ReverseDirectionsWrapper = styled.div`
  cursor: pointer;
`

const BackButton = styled.button`
  color: ${getThemeColor('widgetText', '#333333')};
  border: none;
  background: none;
  font-size: 12px !important;
`

const ReverseDirectionsButton = styled.button`
  color: ${getThemeColor('widgetText', '#333333')};
  border: none;
  background: none;
  font-size: 12px !important;
`

const InputAndAddWrapper = styled.div`
  ${({ isMobile }) => {
  return isMobile
    ? css`
      width: 100%;
    `
    : null
}}
`

const RouteTypeSwitcherWrapper = styled.div`
  width: 100%;
  padding: 0;
`

const ChevronDiv = styled.div`
  display: flex;
  justify-content: center;
`

const FlippingButton = styled(Button)`
width: 100%;
display: block;
background-color: 'transparent';
color:  ${props => props.theme.colors.mapNavText};
padding: 0 12px;
border: none;
border-radius: unset;
position: relative;

transform: ${({ isOpen }) => isOpen ? 'none' : 'rotate(180deg)'};
`

const StyledColoredIcon = styled(ColoredIcon)`
    width:24;
    height:24;
    fill: ${({ theme }) => `${theme.colors.secondary}`};
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px 5px 0px 0px;
  flex-grow: 1;

  .info {
    color: ${({ theme }) => theme.colors.primaryText};
    ${({ theme }) => theme.fontSize('20px')};
    font-weight: 500;
    line-height: 1.3em;
  } 
`

// disable all react-beautiful-dnd development warnings
window['__react-beautiful-dnd-disable-dev-warnings'] = false

const DirectionsResultControlsViewMulti = ({
  showLevelName,
  currentLevelName,
  routeAccessibiltyChoice,
  routeTypes,
  onSwitchRouteTypeButtonClicked,
  onBackButtonClicked,
  logoUrl,
  clientName,
  theme,
  onInputChange,
  onInputClearButtonClick,
  onInputSelect,
  handleKeyPress,
  T,
  // new
  searchInputs,
  currentInputIndex,
  isMobile,
  handleSearchInputsChange,
  handleAddStopSelect,
  widgetState
}) => {
  const [internalSearchInputs, setInternalSearchInputs] = useState(searchInputs)
  const [draggingSearchInputs, setDraggingSearchInputs] = useState(searchInputs)
  const [isDragging, setIsDragging] = useState(false)
  const [navInputsHidden, setNavInputsHidden] = useState(false)

  useEffect(() => {
    // If the navInputs are hidden and the screen size is in desktop view, show the navInputs
    if (navInputsHidden && !isMobile) {
      setNavInputsHidden(false)
    }
  }, [isMobile])

  // Incoming app state
  useMapSearchInputsToState(currentInputIndex, searchInputs, setInternalSearchInputs, setDraggingSearchInputs)

  // Outgoing update to app state
  useOnUpdateSearchInputs(searchInputs, internalSearchInputs, handleSearchInputsChange)

  const ChevronButton = ({ onClick, isOpen, ariaLabel }) => {
    return <ChevronDiv><FlippingButton data-cy="CloseInputChevronButton" onClick={onClick} aria-label={ariaLabel} isOpen={isOpen}>
      <StyledColoredIcon id="chevron-up" />
    </FlippingButton></ChevronDiv>
  }

  return (
    <WidgetWrapper
      data-cy={'DirectionsResultControlsViewMulti'}
      hasLogoUrl={typeof logoUrl !== 'undefined'}
      isMobile={isMobile}
    >
      {logoUrl && theme.header.hasLogo && (
        <LogoWrapper>
          <Logo
            logoHeight={theme.logo.height}
            alt={clientName}
            url={logoUrl}
            width="auto"
            position={theme.logo.centered ? 'center' : 'left'}
            padding={theme.logo.padding}
          />
        </LogoWrapper>
      )}
      <TopButtonContainer isMobile={isMobile}>
        <BackButton onClick={onBackButtonClicked} data-cy='BackButton' aria-label={T('ui:Back')}>
          <ColoredIcon
            className="icon"
            width={24}
            height={24}
            id="arrow-left"
            fillColor={theme.colors.widgetIconFill}
          />
          {!isMobile && <u>{T('getDirectionsFromTo:Home')}</u>}
        </BackButton>
        {!navInputsHidden && <ToolTipComponent
          tooltipText={T(
            'getDirectionsFromTo:Swap start and end points around'
          )}
          arrowPosition="left"
          isVisible={internalSearchInputs?.length < 3}
        >
          <ReverseDirectionsWrapper
            $internalSearchInputs={internalSearchInputs}
          >
            <ReverseDirectionsButton
              data-cy='ReverseDirectionsButton'
              onClick={reverseDirections(internalSearchInputs, setInternalSearchInputs)}
            >
              <u>{T('getDirectionsFromTo:Swap')}</u>
              <ColoredIcon
                className="icon"
                width={24}
                height={24}
                id="swap"
                fillColor={theme.colors.widgetIconFill}
              />
            </ReverseDirectionsButton>
          </ReverseDirectionsWrapper>
        </ToolTipComponent>}
      </TopButtonContainer>
      {navInputsHidden
        ? <HeaderWrapper navInputsHidden={navInputsHidden}><span className='info'>{T('navigationBar:Directions')}</span></HeaderWrapper>
        : <InputAndAddWrapper isMobile={isMobile}>
          <DndContext modifiers={[restrictToVerticalAxis]} collisionDetection={closestCenter} onDragStart={handleDragStart(setIsDragging)} onDragOver={handleDragOver(internalSearchInputs, setDraggingSearchInputs)} onDragEnd={handleDragEnd(setInternalSearchInputs, setIsDragging)}>
            <SortableContext items={internalSearchInputs} strategy={verticalListSortingStrategy}>
              {internalSearchInputs.map((input, index) => {
                let placeholder = `${T('Add stop')}:`
                if (index === 0) placeholder = `${T('From')}:`
                if (index === internalSearchInputs.length - 1) placeholder = `${T('To')}:`
                const first = isDragging ? draggingSearchInputs[0].id === input.id : index === 0
                const last = isDragging ? draggingSearchInputs[draggingSearchInputs.length - 1].id === input.id : index === internalSearchInputs.length - 1
                return (
                  <SearchInputLabelledMulti
                    data-cy={'SearchInputLabelledMulti'}
                    index={index}
                    searchInputs={searchInputs}
                    id={input.id} // Both this prop and 'key' prop seem to need to have the same value
                    key={input.id || index}
                    autoFocus={!widgetState.getState().modalIsShowing && currentInputIndex === index}
                    value={input.term ? input.term : ''}
                    placeholder={placeholder}
                    onInputChange={onInputChange}
                    onInputSelect={onInputSelect}
                    onClick={() => onInputSelect(index)}
                    onInputClearButtonClick={handleClearButtonClick(internalSearchInputs, setInternalSearchInputs, onInputClearButtonClick, index)}
                    clickableItemOptions={!(internalSearchInputs.length < 3)}
                    handleRemoveClick={handleRemoveClick(internalSearchInputs, setInternalSearchInputs, index, widgetState)}
                    handleMoveClick={handleMoveClick(internalSearchInputs, setInternalSearchInputs, index, widgetState)}
                    handleKeyPress={handleKeyPress}
                    draggable={true}
                    first={first}
                    last={last}
                    T={T}
                    isMobile={isMobile}
                  />
                )
              })}
            </SortableContext>
          </DndContext>
          <AddWaypointButton tooltipText={T('getDirectionsFromTo:Maximum Stops Reached')} disabled={internalSearchInputs.length > MAX_SEARCH_INPUT_LENGTH - 1} T={T} theme={theme} handleAddStop={handleAddStop(internalSearchInputs, setInternalSearchInputs, handleAddStopSelect)} />
        </InputAndAddWrapper>}
      {!navInputsHidden && isMobile
        ? (
          <RouteTypeSwitcherWrapper>
            <RouteOptions
              routeAccessibiltyChoice={routeAccessibiltyChoice}
              routeTypes={routeTypes}
              onSwitchRouteTypeButtonClicked={onSwitchRouteTypeButtonClicked}
              T={T}
            />
          </RouteTypeSwitcherWrapper>
        )
        : !navInputsHidden
          ? (
            <RouteOptions
              routeAccessibiltyChoice={routeAccessibiltyChoice}
              routeTypes={routeTypes}
              onSwitchRouteTypeButtonClicked={onSwitchRouteTypeButtonClicked}
              T={T}
            />
          )
          : null}
      {isMobile && <ChevronButton data-cy='ChevronButton' ariaLabel={T('ui:Toggle content')} onClick={() => setNavInputsHidden(!navInputsHidden)} isOpen={!navInputsHidden}></ChevronButton>}
      {showLevelName && (
        <LevelInfo>
          <span>{currentLevelName}</span>
        </LevelInfo>
      )}
    </WidgetWrapper>
  )
}

export default withTheme(DirectionsResultControlsViewMulti)

DirectionsResultControlsViewMulti.propTypes = {
  /* eslint-disable key-spacing */
  routeAccessibiltyChoice: PropTypes.string,
  routeTypes: PropTypes.string,
  isNavigating: PropTypes.bool,
  time: PropTypes.number,
  distance: PropTypes.number,
  currentLevelName: PropTypes.string,
  showLevelName: PropTypes.bool
  /* eslint-enable key-spacing */
}
