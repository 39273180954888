import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;  
  background-color: rgba(0,0,0, 0.5);
  width: 100%;
  height: 100%; 
`

const ModalContent = styled.div`
  width: 331px;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #fff;
  box-shadow: inset 0px 1px 0px #EBEBEB;
  ${({ theme }) => theme.borderRadius('2')};
`

const ModalView = (props) => {
  return <Wrapper>
    <ModalContent>
      {props.children}
    </ModalContent>
  </Wrapper>
}

export default ModalView
