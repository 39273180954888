export const getNavGraphFeatureLayers = (nodesSourceName, edgesSourceName) => ([
  {
    id: 'nav_nodes_connected',
    filter: [
      'all',
      ['==', ['geometry-type'], 'Point'],
      ['==', ['get', 'isOrphaned'], false]
    ],
    type: 'circle',
    source: nodesSourceName,
    layout: {
      visibility: 'visible'
    },
    paint: {
      'circle-color': '#ffffff',
      'circle-radius': 1,
      'circle-stroke-color': '#0000FF',
      'circle-stroke-width': 1
    }
  },
  {
    id: 'nav_nodes_orphaned',
    filter: [
      'all',
      ['==', ['geometry-type'], 'Point'],
      ['==', ['get', 'isOrphaned'], true]
    ],
    type: 'circle',
    source: nodesSourceName,
    layout: {
      visibility: 'visible'
    },
    paint: {
      'circle-color': '#ffffff',
      'circle-radius': 3,
      'circle-stroke-color': '#FF0000',
      'circle-stroke-width': 2
    }
  },
  {
    id: 'nav_nodes_text',
    filter: ['==', ['geometry-type'], 'Point'],
    type: 'symbol',
    source: nodesSourceName,
    layout: {
      'text-field': ['get', 'nodeId'],
      'text-variable-anchor': ['bottom'],
      'text-radial-offset': 0.5,
      'text-justify': 'auto',
      'text-font': ['Noto Sans Bold'],
      'text-size': [ // todo update text size, to be smaller
        'interpolate',
        ['exponential', 1.96],
        ['zoom'],
        20, 12, 22, 20
      ],
      visibility: 'visible'
    },
    paint: {
      'text-color': '#4d4563'
    }
  },
  {
    id: 'nav_edges_driveway',
    filter: ['==', ['geometry-type'], 'LineString'],
    type: 'line',
    source: edgesSourceName,
    paint: {
      'line-color': [
        'case',
        ['==', ['get', 'isDriveway'], true],
        '#00FF00',
        ['get', 'strokeColor']
      ],
      'line-width': 2
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
      'line-round-limit': 0
    }
  }
])
