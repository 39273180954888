import queryString from 'query-string'
import * as R from 'ramda'

import { b64DecodeUnicode } from '../utils/funcs.js'

export function process (config) {
  const stateString64 = queryString.parse(location.search).s
  if (stateString64)
    config = setStateFromStateString(config, b64DecodeUnicode(stateString64))

  return config
}

/**
 * Given a state string (JSON representation of latestState above) this will
 * update the app state (via calling all plugins with their state)
 *
 * Note: forceCreate is a potential security risk. It is used by the SDK BEFORE the extends
 * is processed, so needed here - but a user could prepare a stateString that included
 * some plugin they aren't supposed to have activated. Lets revisit this soon and
 * instead have a way to tell this postprocessor to look for state somewhere BESIDES
 * the location.search - then we can remove dangerous flag. (of course it would take someone
 * incredibly familiar with our source code to exploit this - and currently there is nothing
 * they could gain from it)
 * @param  {Object} app App object created in App.js
 * @param  {string} stateString stringified JSON object representing state
 * @param {boolean} forceCreate if true, create any plugin entries if not found
 */
export function setStateFromStateString (config, stateString, forceCreate) {
  const state = JSON.parse(stateString)

  // Iterate through each state object, which has a plugin id and key/value state props
  Object.keys(state).forEach(id => {
    const so = state[id]
    let pluginConf = config.plugins[id]
    if (!pluginConf && forceCreate)
      pluginConf = config.plugins[id] = { }
    if (pluginConf) {
      const curDLP = pluginConf.deepLinkProps
      if (!curDLP)
        pluginConf.deepLinkProps = so
      else
        pluginConf.deepLinkProps = R.mergeDeepRight(curDLP, so)
    }
  })

  return config
}
