export const isDOMNode = (o) => {
  return (
    typeof Node === 'object' ? o instanceof Node : o && typeof o === 'object' && typeof o.nodeType === 'number' && typeof o.nodeName === 'string'
  )
}

export const $ = (expr, container) => typeof expr === 'string' ? (container || document).querySelector(expr) : expr || null
export const $$ = (expr, container) => Array.prototype.slice.call((container || document).querySelectorAll(expr))

// adds an element (default: div) defined in eConf to parent element (default: body) - returns element
// i.e.
// const newDiv = ad() = creates a new div, adds to end of page and returns it
// const newDiv = ad({klass: "foo"}) = same as above with class of "foo"
// const newImg = ad({tag: "img", src: "test.png"}, "#top") = image at end of top
export function ad (eConf, parent) {
  const c = eConf || {}
  parent = parent ? (typeof parent === 'string' ? document.querySelector(parent) : parent) : document.body

  let tag = 'div'

  if (c.tag)
    tag = c.tag

  const node = document.createElement(tag)

  for (const cc in c) {
    if (c[cc] != null) // null or undefined
      if (cc === 'klass')
        node.setAttribute('class', c.klass)
      else if (cc === 'tag')
        ; // already swallowed this
      else if (cc === 'styles')
        setStyles(node, c.styles)
      else if (cc === 'text')
        node.textContent = c.text
      else if (cc === 'html')
        node.innerHTML = c.html
      else
        node.setAttribute(cc, c[cc])
  }

  parent.appendChild(node)

  return node
}

export const setStyles = (node, styles) => {
  for (const style in styles)
    node.style[style] = styles[style]
}

export const isAppleMobile = () => (/\b(iPad|iPhone)\b/.test(navigator.userAgent) &&
    /WebKit/.test(navigator.userAgent) &&
    !window.MSStream) ||
    (navigator.platform === 'MacIntel' &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints === 5)
