// small helper for showing args
const SQ = '\'' // single quote
const sqStr = function (str) { return SQ + str + SQ }

export function validate (library, sig, cob) {
  if (sig.args)
    checkTypeList(library.customTypes, sig.args, cob)
}

export function getSigMatch (library, cob) {
  const matches = library.commandDefsList
    .filter(cd => (cd.sig.command === cob.command) && matchesArgs(cd.sig, cob))
  if (matches.length > 1) { throw Error('Command matches multiple signatures!') }
  if (matches.length > 0) { return matches[0] }
  return null // no matching signature found
}

// returns true if each of the required arguments in the command
// spec (c) is defined within the command object (o) - else false
function matchesArgs (c, o) {
  if (!c.args) { return true } // no args
  return c.args.reduce(function (cs, argOb) {
    return o[argOb.name] !== undefined || argOb.optional ? cs : false
  }, true)
}

function checkMinMax (name, typeSpec, value) {
  if (typeSpec.min !== undefined && value < typeSpec.min) { throw Error('argument ' + sqStr(name) + ' must be at least ' + typeSpec.min + ' but is ' + value) }
  if (typeSpec.max !== undefined && value > typeSpec.max) { throw Error('argument ' + sqStr(name) + ' must be at most ' + typeSpec.max + ' but is ' + value) }
}

/**
 * Checks an argument value against that arguments spec. If there are no violations of the argSpec, true is returned.
 * If there is a violation, an error is thrown.
 * @param {object} customTypes any defined custom types
 * @param {object} typeSpec An type spec object which inclues a type and other constraints if applicable
 * @param {*} value The JSON value to be checked against this spec
 * @return {true|string} returns true if arg is valid, else a string description of the problem
 */
export function checkType (customTypes, typeSpec, value) {
  if (!typeSpec) // if there is no typespec to validate against, any value is ok
    return true

  const name = typeSpec.name || ''

  // if type is a reference/custom, grab that type def but allow "optional" override - TODO: consider other overrides (perhaps all but "type"?)
  if (customTypes && customTypes[typeSpec.type])
    typeSpec = Object.assign({}, customTypes[typeSpec.type], typeSpec, { type: customTypes[typeSpec.type].type })

  if (typeSpec.type === 'integer') {
    if (typeof value === 'string')
      value = parseInt(value, 10)
    if (!Number.isInteger(value))
      throw Error('expected integer argument for argument ' + sqStr(name) + ' but received ' + value)
    checkMinMax(name, typeSpec, value)
  } else
    if (typeSpec.type === 'float') {
      if (typeof value === 'string')
        value = parseFloat(value)
      if (!Number.isFinite(value))
        throw Error('expected float argument for argument ' + sqStr(name) + ' but received \'' + value + '\'')
      checkMinMax(name, typeSpec, value)
    } else
      if (typeSpec.type === 'string') {
        if (typeof value !== 'string')
          throw Error(`argument ${sqStr(name)} must be a string but is not (value: ${value})`)
        if (typeSpec.minLength && value.length < typeSpec.minLength)
          throw Error(`argument ${sqStr(name)} must be a at least ${typeSpec.minLength} characters but is '${value}' (${value.length} chars)`)
        if (typeSpec.maxLength && value.length > typeSpec.maxLength)
          throw Error(`argument ${sqStr(name)} must be a at most ${typeSpec.maxLength} characters but is '${value}' (${value.length} chars)`)
      } else
        if (typeSpec.type === 'boolean') {
          if (value !== true && value !== false && value !== 'false' && value !== 'true' && value !== 'yes' && value !== 'no') { throw Error('argument ' + sqStr(name) + ' must be a boolean but is type ' + (typeof value) + ' with value of ' + value) }
        } else
          if (typeSpec.type === 'list') {
            if (!Array.isArray(value))
              throw Error('argument ' + sqStr(name) + ' must be a list but is not. Value = ' + value)
            if (typeSpec.minLength !== undefined && typeSpec.minLength > value.length)
              throw Error('argument ' + sqStr(name) + ' must contain at least ' + typeSpec.minLength + ' items but only contains ' + value.length + ' items')
            if (typeSpec.maxLength !== undefined && typeSpec.maxLength < value.length)
              throw Error('argument ' + sqStr(name) + ' must contain at most ' + typeSpec.maxLength + ' items but contains ' + value.length + ' items')
            if (typeSpec.itemType) {
              const allItemsValid = value.reduce(function (isValid, curItem) {
                try {
                  checkType(customTypes, typeSpec.itemType, curItem)
                } catch (e) { return false }
                return isValid
              }, true)
              if (!allItemsValid) { throw Error(`argument ${sqStr(name)} contains an invalid item(s). All items in the list must be of type: ${JSON.stringify(typeSpec.itemType)} - list: ${JSON.stringify(value)}`) }
            }
          } else
            if (typeSpec.type === 'object') {
              if (typeof (value) !== 'object' || Array.isArray(value))
                throw Error('argument ' + sqStr(name) + ' must be an object but is not. Value = ' + value)
              try {
                if (typeSpec.props)
                  checkTypeList(customTypes, typeSpec.props, value)
              } catch (e) {
                throw Error('Within ' + name + ', ' + e.message)
              }
            } else
              if (typeSpec.type === 'multi') {
                const oneTypeValid = typeSpec.types
                  .reduce((isValid, curType) => {
                    try {
                      checkType(customTypes, curType, value)
                    } catch (e) { return isValid }
                    return true
                  }, false)
                if (!oneTypeValid)
                  throw Error(`Argument ${sqStr(name)} can be of several types, but is not valid for any: ${value}`)
              } else
                throw Error('type ' + typeSpec.type + ' is an unknown type')

  return true
}

/**
 * Given a value and a typelist, ensure the value abides by the typelist
 * @param types custom defined types
 * @param typeList A list of types to check
 * @param args The properties object to check against
 */
export function checkTypeList (customTypes, typeList, args) {
  typeList.forEach(nextType => {
    if (args[nextType.name] !== undefined) { checkType(customTypes, nextType, args[nextType.name]) } else
      if (!nextType.optional) { throw Error('you must include a value for ' + nextType.name) }
  })
}
