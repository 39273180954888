import React from 'react'
import styled from 'styled-components'

import { ColoredIcon } from '../icons/Icon.jsx'

const NoSearchResults = styled.li`
  padding: 18px 12px 20px;
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  height: ${({ isDesktop }) => isDesktop ? 'auto' : '100vh'};
`

const IconWrapper = styled.div`
  vertical-align: middle;
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Labels = styled.div`
  margin-left: 12px;

  span {
    display: block;
  }

  .label {
    display: block;
    color: #333;
    ${({ theme }) => theme.fontSize('16px')};
    font-weight: 500;
    line-height: 150%;
  }

  .info {
    color: #666;
    ${({ theme }) => theme.fontSize('14px')};
    font-weight: 400;
    line-height: 18px;
  }
`

const NoSearchResultsWidget = ({ isDesktop, T }) => <NoSearchResults
  data-cy='noSearchResultsWidget'
  isDesktop={isDesktop}>
  <ContentWrapper>
    <IconWrapper>
      <ColoredIcon id='no-results' fillColor='#666666' width={40} height={40} />
    </IconWrapper>
    <Labels role="alert">
      <span className="label">{T('ui:Sorry, no matches were found')}</span>
      <span className="info">{T('ui:Try searching a different phrase')}</span>
    </Labels>
  </ContentWrapper>
</NoSearchResults>

export default NoSearchResultsWidget
