import * as R from 'ramda'

import { getFlexSearchInstance } from './utils.js'

const DEFAULT_RESULTS_LIMIT = 5000

export default function createPOISearch (pois, lang) {
  const index = getFlexSearchInstance({ lang })

  // index.addMatcher({
  //   '[\'.,]': ''
  // })

  prepareIndexEntries(pois)
    .forEach(([id, content]) => index.add(id, content))

  function prepareIndexEntries (pois) {
    return Object.values(pois).map((poi) => {
      const { poiId, category = '', name, keywords = [], roomId = '' } = poi
      const grabTags = R.path(['dynamicData', 'grab', 'tags'], poi) || []
      const searchKeywords = keywords
        .filter(R.prop('isUserSearchable'))
        .map(R.prop('name'))
      const content = `${name} ${category.split('.').join(' ')} ${roomId} ${searchKeywords.join(' ')} ${grabTags.join(' ')}`
      return [Number(poiId), content]
    })
  }

  function search (queryParams) {
    const options = { ...queryParams }
    if (!options.limit) // sometimes limit is NaN or undefined!
      options.limit = DEFAULT_RESULTS_LIMIT
    const ids = index.search(options)
    return Object.values(R.pick(ids, pois))
  }

  function updateMultiple (pois) {
    prepareIndexEntries(pois)
      .forEach(([id, content]) => index.update(id, content))
  }

  return { search, updateMultiple }
}
