import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'

import { images } from '../../../../../src/ui/Gallery.jsx'

const Wrapper = styled.div`
  margin: 12px 0;
  display: flex;

  & > div {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 197px;
    border-radius: 2px;
  }

  .controller {
    background: ${({ theme }) => rgba(theme.colors.mapNavBackground, 0.85)};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

    :hover {
      background: ${({ theme }) => theme.colors.mapNavBackground};
    }

    .icon {
      width: 24px;
      height: 24px;
      fill: ${({ theme }) => theme.colors.mapNavText};
      margin: 4px;
    }

    &.left {
      left: 8px;
    }

    &.right {
      right: 8px;
    }
  }

  .number-indicator {
    display: block;

    background: ${({ theme }) => rgba(theme.colors.mapNavBackground, 0.85)};
    fill: ${({ theme }) => theme.colors.mapNavText};

    color: ${({ theme }) => theme.colors.mapNavText};
  }
`

const PoiImages = ({ poi, T }) => {
  if (!poi || !poi.images.length) return null

  return <Wrapper>{images(poi.images, T, poi.name)}</Wrapper>
}

export default PoiImages
