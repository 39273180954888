import { rgba } from 'polished'
import styled from 'styled-components'

import { getThemeColor } from '../../../../src/utils/styled-component-tools.js'

export const Wrapper = styled.nav`
  z-index: 60;
  cursor: pointer;

  .-desktop & {
    max-height: 100%;
    display: flex;
    position: relative;
    pointer-events: all;
  }

  .-mobile & {
    width: 100%;
    max-height: 100%;

    &.open {
      height: auto;
      display: flex;
      align-items: flex-end;
      display: block;
      background: none;
    }
  }
`

export const OtherResultsIndicatorMobile = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    right: 9px;
    top: 9px;
    background-color: ${({ theme }) => theme.colors.mapNavSearchResults};
    border-radius: 50%;
    display: inline-block;
`

export const Submenu = styled.div`
  background: ${({ theme }) => theme.colors.background};

  button:first-child {
    box-shadow: inset 0 4px 4px -2px rgba(46, 48, 50, 0.4);
  }
  button:last-child {
    box-shadow: inset 0 -4px 4px -2px rgba(46, 48, 50, 0.4);
    border-bottom: none;
  }
`

export const ToggleShowMenuButtonMobile = styled.button`
  cursor: pointer;
  pointer-events: all;
  position: relative;
  margin: 0;
  padding: 0;
  border-radius: 100%;
  outline: none;
  border: none;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  &:focus-visible {
    outline: 1px solid orange;
    outline-offset: 2px;
  }

  & svg {
    fill: ${getThemeColor('secondaryText', '#666666')};
  }
`

export const MapNavigatorMobile = styled.div`
  pointer-events: all;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  max-height: 100%;
  overflow-y: scroll;

  .exitSelector {
    background: ${({ theme }) => theme.colors.background};
    outline: none;
    border: none;
    flex: 1 0 auto;
  }
`

export const BuildingWrapperMobile = styled.div`
width: inherit;
background: ${({ theme }) => theme.colors.background};
`
export const BuildingDropdownMobile = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mapNavBackground};
  padding: 16px 8px;
  background: ${({ theme }) => theme.colors.mapNavBackground};
  width: inherit;

  .buildingName {
    color: ${({ theme }) => theme.colors.mapNavText};
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .indicator {
    border-radius: 100%;
    height: 26px;
    width: 26px;
    background: ${({ theme }) => theme.colors.mapNavSearchResults};
    color: ${({ theme }) => theme.colors.mapNavSearchResultsText};
    position: absolute;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  &:focus-visible {
    outline: 2px solid orange;
    outline-offset: -2px;
  }
`
export const LevelDropdownMobile = styled.button`
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #EBEBEB;
  padding: 13px 48px;
  text-align: inherit;
  position: relative;
  background: ${({ theme }) => theme.colors.background};

  &.selectedBg {
    background: ${({ theme }) => rgba(theme.colors.primary, 0.05)};
  }

  .selected {
    height: 100%;
    width: 4px;
    left: 0;
    top: 0;
    background-color: ${({ theme }) => theme.colors.primary};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    position: absolute;
  }

  .levelName {
    color: ${({ theme }) => theme.colors.primaryText};
    font-weight: 500;
    ${({ theme }) => theme.fontSize('16px')};
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .secondaryLabel {
    color: ${({ theme }) => theme.colors.secondaryText};
    ${({ theme }) => theme.fontSize('14px')};
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .indicator {
    border-radius: 100%;
    height: 26px;
    width: 26px;
    background: ${({ theme }) => theme.colors.mapNavSearchResults};
    color: ${({ theme }) => theme.colors.mapNavSearchResultsText};
    position: absolute;
    top: 19px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  &:focus-visible {
    outline: 2px solid orange;
    outline-offset: -2px;
  }
`
export const DragCloseButton = styled.button`
  background: ${({ theme }) => theme.colors.background};
  outline: none;
  border: none;
  flex-direction: column;
  
  &:focus-visible {
    outline: 2px solid orange;
    outline-offset: -2px;
  }
`

export const DragIndicator = styled.div`
  width: 100%;
  height: 15px;
  position: relative;
  left: 47vw;
  
  outline: none;
  border: none;
  background: ${({ theme }) => theme.colors.background};
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 31px;
    height: 5px;
    background-color: #DCDCDC;
  }
`

export const ShowOtherResultsBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto auto 0;
  padding-left: 8px;
  padding-right: 16px;
  transition: 0.5s ease-in-out opacity;
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.secondaryButton};
  border-radius: 1px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`

export const BannerLabel = styled.div`
  color: ${({ theme }) => theme.colors.secondaryText};
  line-height: 18px;
  ${({ theme }) => theme.fontSize('14px')};
  margin-left: 4px;
`

export const ShowOtherResultsBannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 14px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 60;
`

export const BannerIconWrapper = styled.div`
  position: relative;
`

export const OtherResultsIndicatorBanner = styled(OtherResultsIndicatorMobile)`
  right: 5px;
  top: 5px;
`
