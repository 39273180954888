import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { FlatButton } from '../../../../../src/ui/buttons/Button.jsx'

const ControlsWrapper = styled('div')`
    display: ${({ isOpen }) => isOpen ? 'none' : 'flex'};
    padding: 12px;
`
const StyledFlatButton = styled(FlatButton)`
    flex: 1 0 auto;
    border-radius: 8px;
`

const EditButton = styled(StyledFlatButton)`
color: ${({ theme }) => theme?.colors?.secondaryButtonText || '#124CB1'};
background-color: ${({ theme }) => theme?.colors?.secondaryButton || '#EBEBEB'};

&:not([disabled]):hover {
    color: ${({ theme }) => theme?.colors?.secondaryButtonText || '#124CB1'};
    background-color: ${({ theme }) => theme.colors.secondaryButtonHover || '#D9D9D9'};
  }
`
const EndButton = styled(StyledFlatButton)`
    color: ${({ theme }) => theme?.colors?.destructiveButtonText || '#FFFFFF'};
    background-color: ${({ theme }) => theme?.colors?.destructiveButton || '#B00000'};

    &:not([disabled]):hover {
    color: ${({ theme }) => theme?.colors?.destructiveButtonText || '#FFFFFF'};
    background-color: ${({ theme }) => theme?.colors?.destructiveButtonHover || '#8C0000'};
    }
`
const Spacer = styled('div')`
    width: 13px;
`

const EnrouteControls = ({ ...props }) => {
  const { isOpen, editRouteLabel, endRouteLabel, onEditRouteClick, onEndRouteClick } = props

  return (
    <ControlsWrapper data-cy="EnRouteControls" isOpen={isOpen}>
      <EditButton data-cy="EditButton" onClick={onEditRouteClick}>{editRouteLabel}</EditButton>
      <Spacer />
      <EndButton data-cy='EndButton' onClick={onEndRouteClick}>{endRouteLabel}</EndButton>
    </ControlsWrapper>

  )
}

EnrouteControls.defaultProps = {
  editRouteLabel: 'Edit Route',
  endRouteLabel: 'End Route'
}

EnrouteControls.propTypes = {
  /** A string to be passed into the 'edit route' button. */
  editRouteLabel: PropTypes.string,
  /** A string to be passed into the 'end route' button. */
  endRouteLabel: PropTypes.string,
  /** A callback for the edit route button on click. */
  onEditRouteClick: PropTypes.func.isRequired,
  /** A callback for the end route button on click. */
  onEndRouteClick: PropTypes.func.isRequired
}

export { EnrouteControls }
