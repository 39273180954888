export const widgetEnum = {
  Desktop: 'desktop',
  Mobile: 'mobile'
}

export const ParkingLabelStatus = {
  OPEN: 'Open',
  CLOSED: 'Closed',
  NEARING_CAPACITY: 'Nearing capacity',
  FULL: 'Full'
}
