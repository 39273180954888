import { isEmpty } from 'ramda'

const getNextEmptySearchFieldIndex = (searchInputs, currentSearchFieldIndex) => {
  // if out of bounds, return 0
  if (currentSearchFieldIndex < 0 || currentSearchFieldIndex >= searchInputs.length) return 0
  // if any are empty, pick the "next" one, circling around if necessary
  for (let i = 0; i < searchInputs.length; i++) {
    const index = (currentSearchFieldIndex + i + 1) % searchInputs.length
    if (isEmpty(searchInputs[index])) {
      return index
    }
  }
  // if all are filled out, stay on current input
  return currentSearchFieldIndex % searchInputs.length
}

export { getNextEmptySearchFieldIndex }
