import * as R from 'ramda'
import { throttle } from 'throttle-debounce'

import { getFloorAt, getStructureAtPoint } from '../../../src/utils/geom.js'

import { convertRadiusToZoom, convertZoomToRadius, getMapState } from './utils.js'

export default function StateController (app, mapInitialized, config) {
  let state = {}

  mapInitialized().then(map => {
    map.on('move', (e) => {
      // only dispatch map state change if the event was user interaction, not programmatic
      if (e.originalEvent) {
        dispatchUserMoving(map)
      }
    })

    map.on('movestart', (e) => {
      // only dispatch map/userMoveStart if the event was user interaction, not programmatic
      if (e.originalEvent) {
        app.bus.send('map/userMoveStart', getMapState(map))
      }
    })

    map.on('moveend', () => {
      const mapState = getMapState(map)
      app.bus.send('map/moveEnd', mapState)
      notifyState(mapState)
    })
  })

  const dispatchUserMoving = throttle(200, false, (map) => app.bus.send('map/userMoving', getMapState(map)))

  app.bus.on('venueData/mapDataLoaded', ({ structures, venueCenter, venueRadius }) => {
    state = { ...state, structures }
    mapInitialized().then(map => {
      const [lat, lng] = venueCenter
      const zoom = convertRadiusToZoom(venueRadius, { lat, lng }, map, app.config.renderDiv)
      const defaultView = state.defaultView || R.mergeRight({ lat, lng, zoom }, config.defaultViewParams)
      state = { ...state, defaultView }
      app.bus.send('map/animateToView', { ...defaultView, center: [defaultView.lng, defaultView.lat], duration: 0 })
      if (config.deepLinkProps)
        animateToState(config.deepLinkProps)
    })
  })

  const animateToState = viewParams => {
    if (viewParams.vp) {
      const { bearing, lat, lng, pitch, zoom } = viewParams.vp
      app.bus.send('map/animateToView', { bearing, zoom, pitch, center: [lng, lat], duration: 0 })
    }
    const { bearing, lat, lng, pitch, zoom, radius, buildingId, floorId } = viewParams
    if (floorId)
      app.bus.send('map/selectFloor', { id: floorId })
    else
      if (buildingId)
        app.bus.send('mapLevelSelector/selectBuilding', { id: buildingId })
    if (bearing || lat || pitch || zoom || radius) {
      const filterNils = R.filter(x => R.not(R.isNil(x)))
      const view = R.pipe(filterNils, R.map(parseFloat))({ bearing, pitch, zoom, radius, lat, lng })
      if (radius)
        app.bus.send('map/animateToPointWithRadius', { ...view, animOptions: R.pick(['pitch', 'bearing'], view) })
      else {
        if (lat)
          view.center = [parseFloat(lng), parseFloat(lat)]
        app.bus.send('map/animateToView', view)
      }
    }
    if (R.has('ord', viewParams))
      app.bus.send('map/changeOrdinal', { ordinal: viewParams.ord })
  }

  app.bus.on('deepLinking/setState', R.when(R.propEq('id', 'mapRenderer'), animateToState))

  const showDefaultView = () => {
    const { defaultView } = state
    app.bus.send('map/animateToView', { ...defaultView, center: [defaultView.lng, defaultView.lat], duration: 500 })
  }

  app.bus.on('app/reset', () => showDefaultView())

  app.bus.on('map/setDefaultView', (view) => {
    state = { ...state, defaultView: { ...state.defaultView, ...view } }
  })

  app.bus.on('map/showDefaultView', () => showDefaultView())

  app.bus.on('map/getMapCenter', () => {
    return mapInitialized().then(async map => {
      if (!map.getCenter)
        throw Error('*** Error in stateController:92 - map.getCenter not a function!')

      const { lat, lng } = map.getCenter()
      const ordinal = await app.bus.get('map/getCurrentOrdinal')
      const structureId = R.propOr(null, 'id', getStructureAtPoint(state.structures, lat, lng))
      const floorId = R.propOr(null, 'id', getFloorAt(state.structures, lat, lng, ordinal))
      const position = { lat, lng, ordinal, structureId, floorId }
      return position
    })
  })

  app.bus.on('map/getCurrentView', () => mapInitialized().then(map => getMapState(map)))

  app.bus.on('map/zoomIn', () => mapInitialized().then(map => app.bus.send('map/animateToView', { zoom: map.getZoom() + 1, duration: 300 })))

  app.bus.on('map/zoomOut', () => mapInitialized().then(map => app.bus.send('map/animateToView', { zoom: map.getZoom() - 1, duration: 300 })))

  app.bus.on('map/zoomTo', ({ value }) => mapInitialized().then(() => app.bus.send('map/animateToView', { zoom: value, duration: 300 })))

  app.bus.on('map/zoomBy', ({ value }) => mapInitialized().then(map => app.bus.send('map/animateToView', { zoom: map.getZoom() + value, duration: 300 })))

  app.bus.on('map/setBearing', ({ bearing }) => mapInitialized().then(() => app.bus.send('map/animateToView', { bearing, duration: 500 })))

  app.bus.on('map/resetBearing', () => mapInitialized().then(() => app.bus.send('map/animateToView', { bearing: 0, duration: 500 })))

  app.bus.on('map/setPitch', ({ pitch }) => mapInitialized().then(() => app.bus.send('map/animateToView', { pitch, duration: 500 })))

  app.bus.on('map/rotateRight', ({ delta = 1 } = {}) => mapInitialized().then(map => app.bus.send('map/animateToView', { bearing: map.getBearing() - delta, duration: 300 })))

  app.bus.on('map/rotateLeft', ({ delta = 1 } = {}) => mapInitialized().then(map => app.bus.send('map/animateToView', { bearing: map.getBearing() + delta, duration: 300 })))

  app.bus.on('map/pan', ({ offset }) => mapInitialized().then(map => map.panBy(offset, { duration: 300 })))

  app.bus.on('map/getVisibleEntities', () => {
    return mapInitialized().then(map => {
      const clientRect = map.getCanvas().getBoundingClientRect()
      const padding = map.getPadding()
      const bbox = [
        { x: clientRect.x + padding.left, y: clientRect.y + padding.top },
        { x: clientRect.x + clientRect.width - padding.right, y: clientRect.y + clientRect.height - padding.bottom }
      ]
      const allLayers = R.pluck('id', map.getStyle().layers.filter(l => l.type === 'symbol' && !l.id.includes(' dot') && l.source === 'llOrdinalSource'))
      const selectedLayers = allLayers.filter(l => l.includes('_selected'))
      const visibleSelectedRenderedFeatures = map.queryRenderedFeatures(bbox, { layers: selectedLayers })
      const visibleEntities = []
      if (visibleSelectedRenderedFeatures.length > 0) {
        const visibleSelectedEntityIds = visibleSelectedRenderedFeatures.map(R.path(['properties', 'id']), visibleSelectedRenderedFeatures.filter(l => l.properties.aiLayer === 'poi'))
        visibleEntities.push(...visibleSelectedEntityIds)
      } else {
        const nothingSelectedLayers = allLayers.filter(l => !l.includes('_selected') && !l.includes('_notSelected'))
        const visibleRenderedFeatures = map.queryRenderedFeatures(bbox, { layers: nothingSelectedLayers })
        const visibleEntityIds = visibleRenderedFeatures.map(R.path(['properties', 'id']), visibleRenderedFeatures.filter(l => l.properties.aiLayer === 'poi'))
        visibleEntities.push(...visibleEntityIds)
      }
      return R.uniq(visibleEntities)
    })
  })

  app.bus.on('map/getCurrentMapRadius', () => {
    return mapInitialized().then(async map => {
      const venueCenter = await app.bus.get('venueData/getVenueCenter')
      return convertZoomToRadius(map.getZoom(), venueCenter, map, app.config.renderDiv)
    })
  })

  // truncate a long value to 6 decimals
  const t6 = n => Math.round(n * 10 ** 6) / 10 ** 6

  const notifyState = async ({ bearing, lng, lat, pitch, zoom }) => {
    const ordinal = await app.bus.get('map/getCurrentOrdinal')
    app.bus.send('deepLinking/notifyState', {
      id: 'mapRenderer',
      vp: { lat: t6(lat), lng: t6(lng), zoom: t6(zoom), bearing: t6(bearing), pitch: t6(pitch) },
      ord: ordinal
    })
  }

  app.bus.on('map/ordinalChanged', async ordinal => {
    const view = await app.bus.get('map/getCurrentView')
    notifyState(view)
  })

  app.bus.on('venueData/loadNewVenue', () => {
    state = {}
  })

  const runTest = async (initialState, testRoutine) => {
    state = R.mergeRight(state, initialState)
    await testRoutine()
    return state
  }

  return { runTest }
}
