// Data should be an array of EITHER:
//   [lng,lat] coordinates each representing a weight of 1
//   [lng,lat,weight]
const transformData = data => ({
  type: 'FeatureCollection',
  features: data.map(di => ({
    type: 'Feature',
    properties: {
      weight: di[2] != null ? di[2] : 1 // allow 0, but not null or undefined
    },
    geometry: {
      type: 'Point',
      coordinates: [di[0], di[1]]
    }
  }))
})

const SOURCE_NAME = 'heatmap-data'
const LAYER_NAME = 'heatmap-style'

const removeHeatmap = map => {
  map.removeLayer(LAYER_NAME)
  map.removeSource(SOURCE_NAME)
}

export default function HeatmapController (app, mapInitialized) {
  app.bus.on('map/clearHeatmap', () => mapInitialized().then(removeHeatmap))

  app.bus.on('map/displayHeatmap', async ({ data, weightMax = 100 }) => mapInitialized().then(async map => {
    if (map.getSource(SOURCE_NAME))
      removeHeatmap(map)

    if (typeof data[0][0] === 'string' && data[0][0].indexOf('.') < 0) { // if data appears to be h3 format...
      const { cellToLatLng } = await import('h3-js')
      data = data.map(h3Item => {
        const [h3, weight] = h3Item
        return [...cellToLatLng(h3), weight]
      })
    }

    data = await app.bus.get('venueData/normalizeCoords', { coords: data })

    map.addSource(SOURCE_NAME, {
      type: 'geojson',
      data: transformData(data)
    })

    map.addLayer(
      {
        id: 'heatmap-style',
        type: 'heatmap',
        source: 'heatmap-data',
        maxzoom: 24,
        paint: {
        // Increase the heatmap weight based on frequency and property weight
          'heatmap-weight': [
            'interpolate',
            ['linear'],
            ['get', 'weight'],
            0, 0, // weights from 0 -> 100 will become 0 -> 1
            weightMax, 1
          ],
          // Increase the heatmap color weight weight by zoom level
          // heatmap-intensity is a multiplier on top of heatmap-weight
          'heatmap-intensity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            12, 0.01, // pixel size will interpolate based on zoom from 12 -> 22 as .01px -> 1px
            22, 1
          ],
          // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
          // Begin color ramp at 0-stop with a 0-transparancy color
          // to create a blur-like effect.
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0, 'rgba(33,102,172,0)',
            0.2, 'rgb(103,169,207)',
            0.4, 'rgb(209,229,240)',
            0.6, 'rgb(253,219,199)',
            0.8, 'rgb(239,138,98)',
            1, 'rgb(178,24,43)'
          ],
          // Adjust the heatmap radius by zoom level
          'heatmap-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0, 2, // heatmap radius interpolates zoom of 0 -> 9 to become 2px -> 20px
            9, 20
          ],
          // Transition from heatmap to circle layer by zoom level
          'heatmap-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            14, 0.9, // opacity interpolates based on zoom 14 -> 24 to 0.9 -> 1
            24, 1
          ]
        }
      },
      'logos')
  }))
}
