import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  ${({ theme }) => theme.fontSize('10px')};
  line-height: 13px;
  font-weight: 500;
  margin-right: 4px;
`

export default ({ app, text }) => <Wrapper>{text}</Wrapper>
