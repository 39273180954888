import * as R from 'ramda'

/**
 * Visits all nodes (breadth-first) from the nodes2visit
 * array, adding them to vnodes and adding their connected
 * nodes to nodes2visit - until nodes2visit is empty.
 * Nothing is returned - but the vnodes array will have been
 * altered.
 * @param  {Object.<id:node>} nodes - the node pool you wish to scan
 * @param  {Node} startingNode - which node to start the scan
 */
function visitAll (nodes, startingNode) {
  // initialize nodes2visit with the specified node to start
  const nodes2visit = [startingNode]
  const vnodes = new Set() // visited nodes

  while (nodes2visit.length) {
    const node = nodes2visit.splice(0, 1)[0] // next node to visit
    if (node && !vnodes.has(node)) {
      vnodes.add(node)
      node.edges.forEach(edge => {
        if (!vnodes.has(nodes[edge.dst]))
          nodes2visit.push(nodes[edge.dst])
      })
    }
  }

  return vnodes
}

/**
 * @param  {Object.<id:node>} nodes - the node pool you wish to scan
 * @param  {Node} [startingNode] - which node to start the scan (default: "first" node)
 * @returns two arrays of nodes in an object, { orphaned, connected }
 */
export function orphanTest (nodes, startingNode) {
  const nodesArray = Object.values(nodes) // Convert to array

  const vnodes = visitAll(nodes, startingNode || nodesArray[0]) // If no node is specified, use the first one we find
  const onodes = nodesArray.filter(n => !vnodes.has(n))

  console.log(`${onodes.length} Orphaned nodes found from ${nodesArray.length} total`)

  return { orphaned: onodes, orphanedByFloor: R.groupBy(R.prop('floorId'), onodes), connected: Array.from(vnodes) }
}

export const enrichDebugNavGraph = (nodes) => ({
  nodes: toOrphanedNodesArray(nodes),
  edges: getNavEdgeFeatures(nodes)
})

const transformNodes = isOrphaned =>
  R.map(R.pipe(R.assoc('isOrphaned', isOrphaned), R.dissoc('edges')))

const toOrphanedNodesArray = R.pipe(
  orphanTest,
  R.pick(['connected', 'orphaned']),
  R.evolve({
    connected: transformNodes(false),
    orphaned: transformNodes(true)
  }),
  R.values,
  R.flatten
)

const getNavEdgeFeatures = nodes =>
  Object.values(nodes).flatMap(node => node.edges)
    .map(edge => mapEdge(edge, nodes))

const mapEdge = ({ src, dst, type, isDriveway }, nodeMap) => ({
  startCoordinates: [nodeMap[src].lng, nodeMap[src].lat],
  endCoordinates: [nodeMap[dst].lng, nodeMap[dst].lat],
  isDriveway,
  ordinal: nodeMap[src].ordinal,
  category: getEdgeCategory(type),
  defaultStrokeColor: getStrokeColorForMissingCategory(type)
})

const getStrokeColorForMissingCategory = R.cond([
  [R.equals('Stairs'), R.always('#EFBC9B')],
  [R.equals('Elevator'), R.always('#A491D3')],
  [R.equals('Escalator'), R.always('#563F1B')],
  [R.equals('Ramp'), R.always('#DBD053')],
  [R.T, R.always('#FF0000')]
])

const getEdgeCategory = R.cond([
  [R.equals('Train'), R.always('nav.train')],
  [R.equals('Bus'), R.always('nav.transit')],
  [R.equals('Security Checkpoint'), R.always('nav.secure')],
  [R.equals('Ground'), R.always('nav.primary')],
  [R.T, R.always('')]
])
