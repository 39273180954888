import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ScrollableDropShadow from '../../../../../src/ui/ScrollableDropShadow.jsx'
import SearchResults from '../../../../../src/ui/SearchResults/SearchResults.js'
import RouteUnavailableWidget from '../RouteUnavailableWidget.js'

const SearchResultsStyled = styled(SearchResults)`
  overflow: auto;
`

const DirectionsSearchView = ({
  handlePoiClicked,
  handleChooseMapLocationClicked,
  widgetState,
  isDesktop,
  handleEscapeKey,
  T
}) => {
  const [state, setWidgetState] = useState(widgetState.getState())

  useEffect(() => widgetState.addCallback(setWidgetState))

  return state.isRouteUnavailable
    ? (
      <RouteUnavailableWidget T={T} />
    )
    : (
      <ScrollableDropShadow T={T} isDesktop={isDesktop()}>
        <SearchResultsStyled
          {...state}
          {...state.searchInputs[state.currentInputIndex]}
          onChooseMapLocationClicked={handleChooseMapLocationClicked}
          onPoiClicked={handlePoiClicked}
          isDesktop={isDesktop()}
          handleEscapeKey={handleEscapeKey}
          T={T}
        />
      </ScrollableDropShadow>
    )
}

export default DirectionsSearchView
