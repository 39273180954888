import React, { useState, useEffect } from 'react'

import { FloatingComponent } from './styles.js'

const LevelIndicator = ({ hideLevelIndicator, widgetState, T, isMobile }) => {
  const [state, setState] = useState(widgetState.getState())
  const [isHiding, setIsHiding] = useState(false)

  useEffect(() => widgetState.setCallback(setState))

  useEffect(() => {
    setIsHiding(false) // show the popup again

    // set timeouts to hide popup
    const hidingTaskId = setTimeout(() => setIsHiding(true), 2000)
    const timerTaskId = setTimeout(hideLevelIndicator, 2500)

    return function cleanup () {
      clearTimeout(hidingTaskId)
      clearTimeout(timerTaskId)
    }
  }, [state.structure, state.floor, state.showLevelIndicator])

  let text = T('levelIndicator:Zoom to explore the map')

  if (!state.isInitialLoad && state.structure && state.floor) {
    text = `${state.structure.name} / ${state.floor.name}`
  }

  return state.showLevelIndicator
    ? (
      <FloatingComponent className={isHiding ? 'hiding' : ''} isMobile={isMobile} data-cy='FloatingComponent'>
        <div>{text}</div>
      </FloatingComponent>
    )
    : null
}

export default LevelIndicator
