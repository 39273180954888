import * as PropTypes from 'prop-types'
import React from 'react'
import { withTheme } from 'styled-components'

import { ParkingLabelStatus } from '../../../utils/constants.js'

import PillLabel from './PillLabel.jsx'

const getLabelStyle = (status, theme) => {
  switch (status) {
    case ParkingLabelStatus.CLOSED:
      return {
        backgroundColor: theme.colors.statusClosedBackground,
        color: theme.colors.statusClosedText
      }
    case ParkingLabelStatus.NEARING_CAPACITY:
      return {
        backgroundColor: theme.colors.statusDelayedBackground,
        color: theme.colors.statusDelayedText
      }
    case ParkingLabelStatus.FULL:
      return {
        backgroundColor: theme.colors.statusCancelledBackground,
        color: theme.colors.statusCancelledText
      }
    case ParkingLabelStatus.OPEN:
    default:
      return {
        backgroundColor: theme.colors.statusOpenBackground,
        color: theme.colors.statusOpenText
      }
  }
}

const PoiStatusPill = withTheme(({ T, className, theme, status }) => {
  const pillStyle = getLabelStyle(status, theme)

  const statusText = T(`ui:${status}`)

  return <PillLabel
    backgroundColor={pillStyle.backgroundColor}
    color={pillStyle.color}
    className={className}>
    {statusText}
  </PillLabel>
})

PoiStatusPill.propTypes = {
  poi: PropTypes.object,
  T: PropTypes.func.isRequired
}

export default PoiStatusPill
