import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'

import { Button } from '../../../src/ui/buttons/Button.jsx'

const Wrapper = styled.div`
  z-index: 30;
  margin: 0;
  padding-left: 0;
  position: absolute;
  right: 40px;
  bottom: 150px;
`

const TogglePitchButton = styled(Button)`
  width: 48px;
  height: 48px;
  border-radius: unset;
  background: ${({ theme, isActive }) => isActive ? rgba(theme.colors.mapNavBackground, 0.85) : theme.colors.mapNavText};
  color: ${({ theme, isActive }) => isActive ? theme.colors.mapNavText : theme.colors.mapNavBackground};
  transition-duration: 0.2s;
  text-align: center;
  font-weight: 500;
  ${({ theme }) => theme.fontSize('16px')};

  &:hover {
    background: ${({ theme }) => rgba(theme.colors.mapNavBackground, 0.85)};
    color: ${({ theme }) => theme.colors.mapNavText};
    opacity: 1;
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }
 `

const PitchTogglerView = ({ setPitch2DHandler, setPitch3DHandler, pitch }) => {
  const is2D = pitch === 0

  return (
    <Wrapper>
      <TogglePitchButton onClick={setPitch2DHandler} isActive={is2D} aria-label={'2D'}>
        2D
      </TogglePitchButton>
      <TogglePitchButton onClick={setPitch3DHandler} isActive={!is2D} aria-label={'3D'}>
        3D
      </TogglePitchButton>
    </Wrapper>
  )
}
export default PitchTogglerView
