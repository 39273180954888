/**
 * This manages the creation and organization of your App instances.
 * Each instance is created via the create function - passing in a configuration
 * object, which extends any existing configuration template.
 */

import IObject from 'IObject' // Best to only do this during development for performance reasons

import { create as createApp } from './app.js'

// IObject.freeze = 'DEEP'

// This is a list of "instances" of your full app stack. Often this will be only one.
// If you wish to give it a name, use the appName property. Else one will be assigned.
let apps = new IObject()

// The configuration template is used as the base configurationx for all app
// instances. It can be set with setConfigTemplate
const configTemplate = new IObject()

function setConfigTemplate (configTemplate) {
  configTemplate = new IObject(configTemplate)
}

const sendAlert = msg => typeof window !== 'undefined' ? window.alert(msg) : console.error(msg)

/**
 * Create a new instance of the engine. Pass in a configuration object which will
 * extend the config template.
 * @param  {} config Configuration for this instance. Will be shallow copied.
 */
async function create (config) {
  if (!config) { throw Error('Attempt to create App instance with no configuration') }

  // Create a new immutable configuration based on the configuration template
  let myConfig = new IObject(Object.assign({}, configTemplate, config))

  // If no name was defined for this instance, create one.
  const appName = myConfig.appName || 'Instance' + (Object.keys(apps).length + 1)
  myConfig = myConfig.set('appName', appName)

  try {
    const app = await createApp(myConfig)
    // console.log('Got appInstance: ', app)
    apps = apps.set(appName, app)
    return app
  } catch (e) { console.error(e); e.message ? sendAlert(e.message) : sendAlert('Error creating map. Please try again later.') }
}

export {
  create,
  setConfigTemplate
}
