import { pick } from 'ramda'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 11px 50px;
  ${({ theme }) => theme.fontSize('16px')};
  line-height: 150%;
  color: ${({ theme }) => theme.colors.secondaryText};
`

const PersonalInfo = ({ poi }) => {
  if (!poi || !poi.additionalAttributes) return null

  // todo change when properties are migrated to data 3.0
  const infos = Object.values(pick(['title', 'dept', 'div'], poi.additionalAttributes))

  if (!infos.length) return null

  return <Wrapper>
    {infos.map(info => <span>{info}</span>)}
  </Wrapper>
}

export default PersonalInfo
