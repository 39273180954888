import * as R from 'ramda'

import { getLocation } from './location.js'

const getPoiIconId = (poi) => {
  const { category, links } = poi

  if (['label', 'terminal'].includes(category.split('.')[0]))
    return 'dynamicLabel'

  if (links && links.find(link => link.url === 'grab'))
    return 'pin.grabordering'

  return poi.category
}

async function sortPois (bus, pois) {
  if (!pois.length) return pois

  const startLocation = await getLocation(bus)
  if (!startLocation?.floorId)
    return pois

  const poisWithTime = await bus.get('wayfinder/addPathTimeMultiple', ({ pois, startLocation }))

  const sortByTransitTimeNullsLast = R.sortBy(R.propOr(Infinity, 'transitTime'))

  return sortByTransitTimeNullsLast(poisWithTime)
}

/**
 * Pan and zoom map to encompass a subset of the POIs specified in the first argument and the current location.
 * The POIs to include in the bounds includes the closest POI and any other POIs in the same building/floor.
 * If the user's current location (or map center) is not within a building, then all POIs on the same ordinal
 * are included.
 *
 * @param  {object[]} pois - List of POI objects to analyze
 * @param  {bustle} bus - central message bus
 * @param  {} isMobile
 * @param  {} locationIncludes
 */
const adjustBoundsToPois = async (pois, app) => {
  if (pois.length > 0) { // don't bother if no POIs!
    const myLocation = await getLocation(app.bus)
    const sortedPois = await sortPois(app.bus, pois)

    // begin bounds with first POI
    const closestPoi = sortedPois[0]
    let bounds = newBounds(poi2LatLng(closestPoi))

    const theme = await app.themePack.getTheme()

    if (closestPoi.position.floorOrdinal !== myLocation?.ordinal) {
      app.bus.send('map/changeFloor', { id: closestPoi.position.floorId })
      app.bus.send('toast/show', {
        message: app.gt()('ui:Floor changed to display closest result'),
        iconName: 'info',
        background: theme?.colors?.toolTipBackground || '#000',
        color: theme?.colors?.toolTipText || '#FFFF'
      })
      if (!app.env.isMobile())
        app.bus.send('mapLevelSelectorOnline/openLevelSelector', { isOpen: true })
    }

    // and now add every other POI that shares the floor/structure of the first POI to the bounds
    // (or if our location is not within a structure, just add all search results)
    for (const poi of pois)
      if (!myLocation?.structureId ||
          (poi.position.floorId === closestPoi.position.floorId &&
          poi.position.buildingId === closestPoi.position.buildingId))
        bounds = addBounds(bounds, newBounds(poi2LatLng(poi)))

    // and add our current location (or map center) if set
    if (myLocation?.lat)
      bounds = addBounds(bounds, newBounds([myLocation?.lat, myLocation?.lng]))

    await app.bus.send('map/centerInBounds', { bounds })
  }
}

// given two bounds, return single bounds that includes both
const addBounds = (b1, b2) => ({
  n: Math.max(b1.n, b2.n),
  s: Math.min(b1.s, b2.s),
  e: Math.max(b1.e, b2.e),
  w: Math.min(b1.w, b2.w)
})
// return a bounds
const poi2LatLng = poi => [poi.position.latitude, poi.position.longitude]
const newBounds = ([lat, lng]) => ({
  n: lat,
  s: lat,
  e: lng,
  w: lng
})

export { getPoiIconId, sortPois, adjustBoundsToPois }
