import { easeBoth } from '../../../src/utils/anim.js'

import { convertRadiusToZoom } from './utils.js'

let appPadding = { bottom: 0, top: 0, right: 0, left: 0 } // listens for map/changePadding events to alter

export default function AnimationController (app, mapInitialized, config) {
  app.bus.on('map/animateToPointWithRadius', ({ lat, lng, floorId = null, radius, animOptions = {}, ...rest }) => {
    mapInitialized().then(async map => {
      if (floorId) {
        app.bus.send('map/changeFloor', { id: floorId })
      }
      const validRadius = Math.max(radius, config.minZoomRadius)
      const venueCenter = await app.bus.get('venueData/getVenueCenter')
      const convertedZoom = convertRadiusToZoom(validRadius, venueCenter, map, app.config.renderDiv)

      if (!animOptions.padding && !rest.padding)
        rest.padding = appPadding
      map.flyTo({
        center: [lng, lat],
        zoom: convertedZoom,
        ...animOptions,
        ...rest // allow passing other props, such as padding
      })
    })
  })

  const setPadding = (padding, map) => {
    if (padding === 'mol')
      padding = app.env.isDesktop() ? { left: 347, right: 55, top: 72, bottom: 22 } : { top: 0, bottom: 0, left: 0, right: 0 }

    map.setPadding(padding)
    appPadding = padding
  }

  app.bus.on('map/changePadding', ({ padding }) => {
    mapInitialized().then(map => setPadding(padding, map))
  })

  app.bus.on('map/animateToPoint', ({ lat, lng, floorId = null, zoom = null, animOptions = {} }) => {
    mapInitialized().then(map => {
      if (floorId) {
        app.bus.send('map/changeFloor', { id: floorId })
      }
      const validZoom = zoom !== null ? zoom : map.getZoom()
      if (!animOptions.padding)
        animOptions.padding = appPadding
      map.flyTo({ center: [lng, lat], zoom: validZoom, ...animOptions })
    })
  })

  app.bus.on('map/animateToView', (params) => {
    mapInitialized().then(map => {
      if (!params.padding)
        params.padding = appPadding
      map.easeTo({ easing: easeBoth, ...params })
    })
  })

  app.bus.on('map/centerInBounds', async ({ bounds, bearing = null, floorId = null, pitch = null, animOptions = {} }) => {
    mapInitialized().then((map) => {
      if (floorId) {
        app.bus.send('map/changeFloor', { id: floorId })
      }
      const validBearing = bearing !== null ? bearing : map.getBearing()
      const validPitch = pitch !== null ? pitch : map.getPitch()
      map.fitBounds([[bounds.w, bounds.s], [bounds.e, bounds.n]], { duration: 1000, bearing: validBearing, pitch: validPitch, ...animOptions })
    })
  })
}
