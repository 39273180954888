import { DndContext, closestCenter } from '@dnd-kit/core'
import {
  restrictToVerticalAxis
} from '@dnd-kit/modifiers'
import {
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import Logo from '@locus-labs/mol-desktop-logo'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled, { withTheme, css } from 'styled-components'

import { ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'
import SearchInputLabelledMulti from '../../../../../src/ui/inputs/SearchInputLabelledMulti.jsx'
import ToolTipComponent from '../../../../../src/ui/tooltips/ToolTipComponent.jsx'
import { handleKeyboardSelect } from '../../../../utils/keyboardUtils.js'
import AddWaypointButton from '../DirectionsControls/AddWaypointButton.jsx'
import {
  handleAddStop,
  handleDragEnd, handleDragOver, handleDragStart,
  handleRemoveClick,
  handleMoveClick,
  reverseDirections,
  handleClearButtonClick
} from '../helpers/handleInputChangeEvents.js'
import useMapSearchInputsToState from '../hooks/useMapSearchInputsToState.js'
import useOnUpdateSearchInputs from '../hooks/useOnUpdateSearchInputs.js'

const WidgetWrapper = styled.div`
  width: 100%;
  padding: ${({ hasLogoUrl, theme }) =>
  hasLogoUrl && theme.header.hasLogo
    ? theme.header.paddingWithLogo
    : theme.header.padding};
  ${({ isMobile }) => {
      if (isMobile) {
        return css`
        display: flex;
        padding-right: 40px;
      `
      }
    }}
`

const LogoWrapper = styled.div`
  padding: 0 0 20px 0;
`

const TopButtonContainer = styled.div`
  display: flex;
  margin-bottom: 14px;
  margin-right: ${({ isMobile }) => isMobile ? '12px' : '0px'};
  div {
    text-align: center;
    user-select: none;
    color: ${({ theme }) => theme.colors.widgetIconFill};
    ${({ theme }) => theme.fontSize('12px')};
    cursor: pointer;
    position: relative;

    &:last-child {
      margin-left: ${({ isMobile }) => (isMobile ? 'none' : 'auto')};
      position: relative;
    }

    &:hover > .tooltip {
      display: block;
      position: absolute;
      color: #fff;
      margin: 0 4px;
    }
  }
`

const ReverseDirectionsWrapper = styled.div`
  cursor: pointer;
`
const InputAndAddWrapper = styled.div`
  ${({ isMobile }) => {
  return isMobile
    ? css`
      width: 100%;
    `
    : null
}}
`

const DirectionsSearchControlsViewMulti = ({
  currentInputIndex,
  onInputSelect,
  searchInputs,
  logoUrl,
  clientName,
  theme,
  onInputClearButtonClick,
  onSingleInputValueChange,
  onBackButtonClicked,
  handleKeyPress,
  handleSearchInputsChange,
  isMobile,
  T,
  widgetState
}) => {
  const [internalSearchInputs, setInternalSearchInputs] = useState(searchInputs)
  const [draggingSearchInputs, setDraggingSearchInputs] = useState(searchInputs)
  const [isDragging, setIsDragging] = useState(false)

  // Incoming app state
  useMapSearchInputsToState(currentInputIndex, searchInputs, setInternalSearchInputs, setDraggingSearchInputs)

  // Outgoing update to app state
  useOnUpdateSearchInputs(searchInputs, internalSearchInputs, handleSearchInputsChange)

  return (
    <WidgetWrapper
      data-cy={'DirectionsSearchControlsView'}
      hasLogoUrl={typeof logoUrl !== 'undefined' && !isMobile}
      isMobile={isMobile}
    >
      {logoUrl && theme.header.hasLogo && !isMobile && (
        <LogoWrapper>
          <Logo
            logoHeight={theme.logo.height}
            alt={clientName}
            url={logoUrl}
            width="auto"
            position={theme.logo.centered ? 'center' : 'left'}
            padding={theme.logo.padding}
          />
        </LogoWrapper>
      )}

      <TopButtonContainer isMobile={isMobile}>
        <div
          onClick={onBackButtonClicked}
          data-cy='BackButton'
          tabIndex={0}
          role='button'
          aria-label={T('getDirectionsFromTo:Home')}
          onKeyDown={handleKeyboardSelect(() => onBackButtonClicked())}>
          <ColoredIcon
            className="icon"
            width={24}
            height={24}
            id="arrow-left"
            fillColor={theme.colors.widgetIconFill}
          />
          {!isMobile && <u>{T('getDirectionsFromTo:Home')}</u>}
        </div>
        {!isMobile && (
          <ToolTipComponent
            tooltipText={T(
              'getDirectionsFromTo:Swap start and end points around'
            )}
            arrowPosition="left"
          >
            <ReverseDirectionsWrapper>
              <div
                data-cy='ReverseDirectionsButton'
                onClick={reverseDirections(internalSearchInputs, setInternalSearchInputs)}
                tabIndex={0}
                role='button'
                aria-label={T('getDirectionsFromTo:Swap start and end points around')}
                onKeyDown={handleKeyboardSelect(() => reverseDirections(internalSearchInputs, setInternalSearchInputs)())}
              >
                <u>{T('getDirectionsFromTo:Swap')}</u>
                <ColoredIcon
                  className="icon"
                  width={24}
                  height={24}
                  id="swap"
                  fillColor={theme.colors.widgetIconFill}
                />
              </div>
            </ReverseDirectionsWrapper>
          </ToolTipComponent>
        )}
      </TopButtonContainer>
      <InputAndAddWrapper isMobile={isMobile}>
        <DndContext modifiers={[restrictToVerticalAxis]} collisionDetection={closestCenter} onDragStart={handleDragStart(setIsDragging)} onDragOver={handleDragOver(internalSearchInputs, setDraggingSearchInputs)} onDragEnd={handleDragEnd(setInternalSearchInputs, setIsDragging)}>
          <SortableContext items={internalSearchInputs} strategy={verticalListSortingStrategy}>
            {internalSearchInputs.map((input, index) => {
              let placeholder = `${T('getDirectionsFromTo:Add stop')}:`
              if (index === 0) placeholder = `${T('getDirectionsFromTo:From')}:`
              if (index === internalSearchInputs.length - 1) placeholder = `${T('getDirectionsFromTo:To')}:`
              const first = isDragging ? input.id === draggingSearchInputs[0].id : index === 0
              const last = isDragging ? draggingSearchInputs[internalSearchInputs.length - 1].id === input.id : index === internalSearchInputs.length - 1
              return (
                <SearchInputLabelledMulti
                  data-cy={'SearchInputLabelledMulti'}
                  index={index}
                  searchInputs={searchInputs}
                  id={input.id} // Both this prop and 'key' prop seem to need to have the same value
                  key={input.id || index}
                  autoFocus={currentInputIndex === index}
                  value={input.term ? input.term : ''}
                  placeholder={placeholder}
                  onInputChange={onSingleInputValueChange}
                  onInputSelect={onInputSelect}
                  onClick={() => onInputSelect(index)}
                  onInputClearButtonClick={handleClearButtonClick(internalSearchInputs, setInternalSearchInputs, onInputClearButtonClick, index)}
                  handleKeyPress={handleKeyPress}
                  clickableItemOptions={!(internalSearchInputs?.length < 3)}
                  handleRemoveClick={handleRemoveClick(internalSearchInputs, setInternalSearchInputs, index, widgetState)}
                  handleMoveClick={handleMoveClick(internalSearchInputs, setInternalSearchInputs, index, widgetState)}
                  draggable={true}
                  first={first}
                  last={last}
                  T={T}
                  isMobile={isMobile}
                />
              )
            })}
          </SortableContext>
        </DndContext>
        <AddWaypointButton disabled={true} tooltipText={T('getDirectionsFromTo:Fill Empty Destination')} T={T} theme={theme} handleAddStop={handleAddStop(internalSearchInputs, setInternalSearchInputs, onInputSelect)} />
      </InputAndAddWrapper>
    </WidgetWrapper>
  )
}

DirectionsSearchControlsViewMulti.propTypes = {
  onInputSelect: PropTypes.func.isRequired,
  searchInputs: PropTypes.arrayOf(PropTypes.object).isRequired,
  logoUrl: PropTypes.string,
  logoLinkUrl: PropTypes.string,
  clientName: PropTypes.string,
  theme: PropTypes.object,
  onInputClearButtonClick: PropTypes.func.isRequired,
  onSingleInputValueChange: PropTypes.func.isRequired,
  onBackButtonClicked: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  handleSearchInputsChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  T: PropTypes.func.isRequired
}

export default withTheme(DirectionsSearchControlsViewMulti)
