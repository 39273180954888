import { setSource, createMapFeature } from './utils.js'

export default function LabelController (app, mapInitialized) {
  const featureThemer = app.bus.get('map/getMapFeatureThemer')

  const initMapSources = (sources) => {
    return { ...sources, llBuildingLabelsSource: createMapFeature([]) }
  }

  app.bus.on('venueData/mapDataLoaded', async ({ structures }) => {
    const themer = await featureThemer
    const buildingLabelFeatures = getBuildingLabelFeatures(structures, themer)
    mapInitialized().then(map =>
      setSource('llBuildingLabelsSource', buildingLabelFeatures, map))
  })

  // accept when shouldDisplay is null or undefined or true
  const shouldDisplayPredicate = building => building.shouldDisplay == null || building.shouldDisplay

  const getBuildingLabelFeatures = (structures, themer) => structures
    .filter(shouldDisplayPredicate)
    .map(structure => {
      const buildingCenterCoords = [
        (structure.bounds.e + structure.bounds.w) * 0.5,
        (structure.bounds.n + structure.bounds.s) * 0.5
      ]
      const labelFeature = {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: buildingCenterCoords },
        properties: {
          dynamicLabel: 'building',
          text: structure.name,
          buildingId: structure.id,
          category: 'dynamicLabel'
        }
      }
      return themer(labelFeature)
    })

  return { initMapSources }
}
