import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'

import { Icon } from '../../../../../src/ui/icons/Icon.jsx'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  background: ${({ theme }) => rgba(theme.colors.primary, 0.05)};
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  ${({ theme }) => theme.borderRadius(theme.globalRadius)};
  padding: 8px;
  color: ${({ theme }) => theme.colors.primaryText};
`
const Title = styled.div`
  ${({ theme }) => theme.fontSize('14px')};
  line-height: 22px;
  margin: 0 8px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.family};
`

const AmenitiesList = styled.ul`
  ${({ theme }) => theme.fontSize('16px')};
  line-height: 23px;
  margin: 0;
  padding: 0;
  list-style: none;
`

const AmenityItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7px;

  ${Icon} {
    margin-right: 6px;
  }
`

function PoiAmenities ({ poi, T }) {
  if (!poi || !poi.amenities) return null
  const listItems = poi.amenities.map((amenity, index) => {
    return <AmenityItem key={`amenity_${index}`}>
      <Icon id='generic-amenity' width={24} height={24} />
      <span>{amenity}</span>
    </AmenityItem>
  })
  return poiDetailsSection('poiView:Amenities', listItems, T)
}

function poiDetailsSection (title, data, T) {
  return <Wrapper>
    <Title>{T(title).toUpperCase()}</Title>
    <AmenitiesList>
      {data}
    </AmenitiesList>
  </Wrapper>
}

export { PoiAmenities }
