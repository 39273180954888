import Box from '@locus-labs/mol-desktop-box'
import Icon from '@locus-labs/mol-desktop-icon'
import Text from '@locus-labs/mol-desktop-text'
import Tooltip from '@locus-labs/mol-desktop-tooltip'
import styledSystemPropTypes from '@styled-system/prop-types'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import React, { forwardRef, useState, useContext, useEffect, useRef } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { withProp } from 'styled-tools'

import useComponentFocused from '../../../../src/utils/hooks/useComponentFocused.js'
import { getThemeVal } from '../../../utils/styled-component-tools'

const MenuOptions = styled(Box)`
  padding: unset;
  margin: unset;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${getThemeVal('colors.background', '#FFFFFF')};
  z-index: 1;
`

const MenuOption = styled(Box).attrs({ as: 'button' })`
  cursor: pointer;
  border: none;
  background: none;
  color: ${getThemeVal('colors.primaryText', '#333333')};

  &:focus-visible {
    outline-offset: -2px;
    outline-width: 2px; // these need to be explicit for Safari
    outline-style: solid;
  }

  &:hover {
    background-color: ${withProp(getThemeVal('colors.primaryText', '#333333'), (primaryText) => rgba(primaryText, 0.1))};
  }
  &:first-of-type {
    padding-top: 14px;
  }
  &:last-of-type {
    padding-bottom: 14px;
  }
`

const StyledText = styled(Text)`
  font-size: ${getThemeVal('fonts.size.h4', '14px')};
`

const StyledTriggerWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: unset;
  background-color: ${getThemeVal('colors.widgetBackground', '#F9F9F9')};
  position: relative;
  left: 4px;
  width: 4px;
  padding: 0px 0px 0px 0px;
  overflow: hidden;

  &:focus-visible {
    outline-offset: 12px;
    outline-width: 2px; // these need to be explicit for Safari
    outline-style: solid; // these need to be explicit for Safari
  }
`

const DraggableOptions = forwardRef((props, ref) => {
  const themeContext = useContext(ThemeContext)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const {
    ref: componentFocusedRef,
    isComponentFocused,
    setIsComponentFocused
  } = useComponentFocused(true)
  const { menuItems, tooltipContent, submenuLabel, isMobile, T, ...omittedProps } = props

  const triggerRef = useRef()
  const firstOptionRef = useRef(null)
  const lastOptionRef = useRef(null)
  const menuOptionsRef = useRef(null)

  useEffect(() => {
    if (!isComponentFocused && isMenuOpen) setIsMenuOpen(false) // if the outside click was detected, but menu was open, hide it
  }, [isComponentFocused])

  const toggleSubmenu = () => { // is on the trigger button
    setIsMenuOpen(!isMenuOpen)
    setIsComponentFocused(!isMenuOpen)
  }

  const onItemClick = (item) => { // is on each menuItem
    item.onClick()
    toggleSubmenu()
  }

  const MenuOptionsWrapperKeyDown = (e) => { // is on the menu options
    if (e.key === 'Tab' && e.shiftKey && document.activeElement === firstOptionRef.current) {
      setIsMenuOpen(false)
      setIsComponentFocused(false)
    }
    if (e.key === 'Tab' && !e.shiftKey && document.activeElement === lastOptionRef.current) {
      setIsMenuOpen(false)
      setIsComponentFocused(false)
    }
    if (e.key === 'Escape') {
      setIsMenuOpen(false)
      setTimeout(() => triggerRef.current.focus(), 0)
    }
  }

  const firstOrLastRef = (index) => {
    if (index === 0) return firstOptionRef
    if (index === menuItems.length - 1) return lastOptionRef
    return null
  }

  const boxKeyDown = (e) => {
    if (isMenuOpen && ((e.key === 'Tab' && e.shiftKey) || e.key === 'Escape') && document.activeElement === triggerRef?.current) { // check if you cliked the trigger and then tab back or escape
      setIsMenuOpen(false)
      setIsComponentFocused(false)
    }
  }

  return (
    <Box
      display="flex"
      ref={componentFocusedRef}
      onKeyDown={boxKeyDown}
      {...omittedProps}
    >
      <Tooltip
        data-cy="SubmenuTooltip"
        content={tooltipContent}
        cancelTooltip={isMenuOpen}
        placement="right-start"
        gutter={20}
      >
        <StyledTriggerWrapper
          data-cy="DraggableOptionsTrigger"
          onClick={toggleSubmenu}
          aria-label={tooltipContent}
          ref={triggerRef}
          aria-controls="MenuDialog"
          aria-expanded={isMenuOpen}
        >
          <Icon
            name="submenu"
            iconSize={themeContext?.narrowIconSizes?.lg || '24px'}
            color={themeContext.colors.widgetIconFill}
            degreesOfIconRotation={90}
            backgroundColor={themeContext.colors.widgetBackground}
            padding={0}
          />
        </StyledTriggerWrapper>
      </Tooltip>
      {isMenuOpen && (
        <Box position="relative" display="flex">
          <Box
            position="absolute"
            left={isMobile ? '-124px' : '5px'}
            top="30px"
            display="flex"
            alignItems="flex-end"
            borderRadius="lg"
            boxShadow="md"
            role="dialog"
            aria-modal="false"
          >
            <MenuOptions
              data-cy="MenuOptions"
              display="flex"
              flexDirection="column"
              borderRadius="lg"
              overflow="hidden"
              as="menu"
              ref={menuOptionsRef}
              onKeyDown={(e) => MenuOptionsWrapperKeyDown(e)}
            >
              {menuItems.map((item, index) => (
                <MenuOption
                  key={index}
                  ref={firstOrLastRef(index)}
                  data-cy={`MenuOption-${item.label.replace(/\s/g, '')}`}
                  className="menuOption"
                  onClick={() => onItemClick(item)}
                  display="flex"
                  position="relative"
                  alignItems="center"
                  cursor="pointer"
                  padding="10px 0 10px 14px"
                  minWidth="141px"
                  tabIndex={'0'}
                  aria-label={item.label}
                  aria-expanded={isMenuOpen}
                  role="button"
                >
                  <Icon
                    name={item.iconId}
                    iconSize="24"
                    color={themeContext.colors.primaryText}
                    marginRight="4px"
                    degreesOfIconRotation={90}
                    id='threeDots'
                  />
                  <StyledText>
                    {T(`getDirectionsFromTo:${item.label}`)}
                  </StyledText>
                </MenuOption>
              ))}
            </MenuOptions>
          </Box>
        </Box>
      )}
    </Box>
  )
})

DraggableOptions.displayName = 'DraggableOptions'

DraggableOptions.defaultProps = {
  'data-cy': 'DraggableOptions', // component name
  tooltipContent: 'View Actions'
}

export default DraggableOptions

DraggableOptions.propTypes = {
  ...styledSystemPropTypes.space,
  ...styledSystemPropTypes.color,
  ...styledSystemPropTypes.background,
  ...styledSystemPropTypes.border,
  ...styledSystemPropTypes.flexbox,
  ...styledSystemPropTypes.grid,
  ...styledSystemPropTypes.layout,
  ...styledSystemPropTypes.position,
  ...styledSystemPropTypes.shadow,
  ...styledSystemPropTypes.typography,
  as: PropTypes.string,
  forwardAs: PropTypes.string,
  tooltipContent: PropTypes.string,
  submenuLabel: PropTypes.string,
  tooltipZIndex: PropTypes.number,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      iconId: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  )
}
