import { compose, prop } from 'ramda'
import React, { useEffect, useState } from 'react'

import { changeTest } from '../../../src/utils/funcs.js'
import { StateObj } from '../../../src/utils/stateObj.js'

import PitchTogglerView from './PitchTogglerView.js'

const DEFAULT_PITCH_2D = 0
const DEFAULT_PITCH_3D = 44

const PitchToggler = ({ setPitch2D, setPitch3D, widgetState }) => {
  const [pitch, setPitch] = useState(true)
  useEffect(() => widgetState.setCallback(compose(setPitch, prop('pitch'))), [])
  return <PitchTogglerView setPitch2DHandler={setPitch2D} setPitch3DHandler={setPitch3D} pitch={pitch} />
}

// todo refactor plugin, check pitchToggler.md documentation section 'Under Consideration' for ideas.
function create (app, config) {
  const init = () => { }

  const widgetState = new StateObj({
    pitch: DEFAULT_PITCH_2D
  })

  const withView = typeof config.withView !== 'undefined' ? config.withView : true

  const setPitch2D = () => app.bus.send('map/setPitch', { pitch: DEFAULT_PITCH_2D })
  const setPitch3D = () => app.bus.send('map/setPitch', { pitch: DEFAULT_PITCH_3D })

  app.bus.send('layers/register', {
    id: 'pitchToggler',
    widget: () => withView && <PitchToggler
      setPitch2D={setPitch2D}
      setPitch3D={setPitch3D}
      widgetState={widgetState} />,
    show: true,
    isOverlay: true,
    layoutId: 'map'
  })

  const pitchChange = changeTest()
  app.bus.on('map/moveEnd', ({ pitch }) => {
    if (pitchChange(pitch)) // only update widgetState if pitch changes
      widgetState.update({ pitch })
  })

  app.bus.on('pitchToggler/toggle', () => {
    const { pitch } = widgetState.getState()

    if (pitch === 0) setPitch3D()
    else setPitch2D()
  })

  return {
    init
  }
}

export {
  create
}
