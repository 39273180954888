import React from 'react'
import styled, { withTheme } from 'styled-components'

import { IconButton } from '../../../../../src/ui/buttons/IconButton.jsx'
import { ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'
import SearchInputLabelled from '../../../../../src/ui/inputs/SearchInputLabelled.js'
import { FROM_SEARCH_FIELD_ID, TO_SEARCH_FIELD_ID } from '../constants.js'
const WidgetWrapper = styled.div`
  width: 100%;
  padding: ${({ hasLogoUrl, theme }) =>
  hasLogoUrl && theme.header.hasLogo
    ? theme.header.paddingWithLogoMobile
    : theme.header.paddingMobile};
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.widgetBackground};
  z-index: 1;

  & > * {
    min-width: 0;
  }
`

const LocationsWrapper = styled.div`
  flex: 1 1 auto;
  ${({ theme }) => theme.borderRadius('2')};
  padding: 0 8px;
  color: #fff;
  user-select: none;
  position: relative;
  cursor: default;
  max-width: calc(100vw - 100px);
  overflow: hidden;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
`

const DirectionsSearchControlsViewMobile = ({
  theme,
  searchInputs,
  currentInputIndex,
  onInputSelect,
  onInputCloseButtonClick,
  onInputChange,
  onBackButtonClicked,
  onSwitchInputsButtonClicked,
  handleKeyPress,
  T
}) => {
  return (
    <WidgetWrapper data-cy={'DirectionsSearchControlsViewMobile'}>
      <IconButton onClick={onBackButtonClicked} title="Navigate Back" data-cy='NavigateBackButton'>
        <ColoredIcon
          className="icon"
          width={32}
          height={32}
          id="arrow-left"
          fillColor={theme.colors.widgetIconFill}
        />
      </IconButton>
      <LocationsWrapper>
        <SearchInputLabelled
          id={FROM_SEARCH_FIELD_ID}
          autoFocus={currentInputIndex === FROM_SEARCH_FIELD_ID}
          value={
            searchInputs[FROM_SEARCH_FIELD_ID] &&
            searchInputs[FROM_SEARCH_FIELD_ID].term
              ? searchInputs[FROM_SEARCH_FIELD_ID].term
              : ''
          }
          label={T('getDirectionsFromTo:From') + ':'}
          onInputChange={onInputChange}
          onInputSelect={onInputSelect}
          onClick={() => onInputSelect(FROM_SEARCH_FIELD_ID)}
          onInputCloseButtonClick={onInputCloseButtonClick}
          handleKeyPress={handleKeyPress}
          T={T}
          data-cy={'SearchInputFrom'}
        ></SearchInputLabelled>

        <SearchInputLabelled
          id={TO_SEARCH_FIELD_ID}
          autoFocus={currentInputIndex === TO_SEARCH_FIELD_ID}
          value={
            searchInputs[TO_SEARCH_FIELD_ID] &&
            searchInputs[TO_SEARCH_FIELD_ID].term
              ? searchInputs[TO_SEARCH_FIELD_ID].term
              : ''
          }
          label={T('getDirectionsFromTo:To') + ':'}
          onInputChange={onInputChange}
          onInputSelect={onInputSelect}
          onClick={() => onInputSelect(TO_SEARCH_FIELD_ID)}
          onInputCloseButtonClick={onInputCloseButtonClick}
          handleKeyPress={handleKeyPress}
          T={T}
          data-cy={'SearchInputTo'}
        ></SearchInputLabelled>
      </LocationsWrapper>

      <IconButton onClick={onSwitchInputsButtonClicked}>
        <ColoredIcon
          className="icon"
          width={32}
          height={32}
          id="swap"
          fillColor={theme.colors.widgetIconFill}
        />
      </IconButton>
    </WidgetWrapper>
  )
}

export default withTheme(DirectionsSearchControlsViewMobile)
