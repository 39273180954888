/**
 * Follows a localization scheme of checking for named values with locales appended,
 * and checking more generally if values not found.
 *
 * For example, calling getLocalized("foo", "es-MX", "bar") would check the following keys in this order:
 *  "foo-es-MX"
 *  "foo-es"
 *  "foo"
 *
 * As soon as a value is found, it is returned, else the checks continue. If no value is found from any of these,
 * the def value is returned. (If def is undefined, the undefined is returned)
 *
 * @param  {object} config the configuration to inspect
 * @param  {string} name root key name to look up
 * @param  {string} lang language code
 * @param  {any} def default value to return if no value is found through localized checks
 */
export function getLocalized (config, name, lang, def) {
  let key = name
  if (lang)
    key += '-' + lang

  const value = config[key]

  if (value)
    return value

  if (lang) {
    if (lang.indexOf('-') > 0) // we have sublocales, so strip last one and try again
      return getLocalized(config, name, lang.substring(0, lang.indexOf('-')), def)
    else // no sublanguage, so next is try the name on its own
      return getLocalized(config, name, null, def)
  }

  // we are here means we just checked the name (no lang arg) and there was no value found, so
  // we simply return the def
  return def
}

/**
 * iterate over the values of an object allowing
 * self-referencing via >> prefix.
 *
 * i.e. resolveSelfRef({ a: 1, b: '>>a'}) = { a: 1, b: 1}
 * @param {Object} ob Object whose properies may self-reference
 */
export function resolveSelfRef (ob) {
  const ob2 = { ...ob }
  let refsFound = true // init to true to start the loop
  while (refsFound) {
    refsFound = false
    for (const key in ob2) {
      if (ob2[key] && ob2[key].startsWith && ob2[key].startsWith('>>')) {
        refsFound = true
        const refKey = ob2[key].substring(2) // after >>
        if (!ob2[refKey])
          throw Error(`Self reference of ${refKey} not found.`)
        ob2[key] = ob2[refKey]
      }
    }
  }
  return ob2
}

// A debug property is considered "true" if:
//  The key is defined as a property within the debug object AND
//  the value of that key is not "no" or "false" or false (boolean) or undefined or null
// Any other value will be considered true - even 0 and ""
export function debugIsTrue (app, key) {
  if (!app.config.debug)
    return false

  let value = app.config.debug[key]

  if (value === undefined || value === null || value === false)
    return false

  if (value === true)
    return true

  value = value.toLowerCase ? value.toLowerCase() : value // lower case it if possible

  return value !== 'no' && value !== 'false'
}

// import this global to access to the "window" or "global" variables from
// the browser or node. (This approach is considered more robust and ensures consistency)
export let global
try {
  // eslint-disable-next-line no-new-func
  global = Function('return this')()
} catch (e) {
  global = window
}
