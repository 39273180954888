import styled from 'styled-components'

import { getThemeVal } from '../../../../src/utils/styled-component-tools.js'

const GhostMarker = styled.div`
  display: flex;
  align-items: center;

  span {
    max-width: 144px;
    color: ${({ theme }) => theme.colors.programmaticPinMarkersText};
    ${({ theme }) => theme.fontSize('12px')};
    line-height: 18px;
    font-weight: 500;
    text-shadow: ${({ theme }) => `-1px -1px 1px ${theme.colors.programmaticPinMarkersStroke}, 1px -1px 1px ${theme.colors.programmaticPinMarkersStroke}, -1px 1px 1px ${theme.colors.programmaticPinMarkersStroke}, 1px 1px 1px ${theme.colors.programmaticPinMarkersStroke},  0px 2px 2px rgba(0, 0, 0, 0.2)`};
    margin-left: 4px;
  }
`

const PrimaryLabel = styled.h2`
  line-height: 24px;
  font-weight: 500;
  margin: 12px 0;
  && {
    font-size: ${getThemeVal('fontSizes.xl')}; // find sizes in rems are being applied for the global style...so this line applies the style for this component twice
  }
`

const SecondaryLabel = styled.p`
  line-height: 150%;
  margin: 0;
  && {
    font-size: ${getThemeVal('fontSizes.lg')}; 
  }
`

const ButtonBase = styled.button`
  cursor: pointer;
  &:focus-visible {
    outline: 1px solid blue;
    outline-offset: 2px;
  }
  padding: 5px 14px;
  height: 52px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  background: none;

  &[disabled] {
    cursor: not-allowed;
  }
`

export { GhostMarker, PrimaryLabel, SecondaryLabel, ButtonBase }
