import { rgba } from 'polished'
import React, { useState } from 'react'
import styled from 'styled-components'

import { ColoredIcon } from './icons/Icon.jsx'
import ProgressiveImage from './ProgressiveImage.jsx'
import ToolTipComponent from './tooltips/ToolTipComponent.jsx'

export const GalleryWrapper = styled.div`
  position: relative;
`

const ControllerWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`

const LeftControllerWrapper = styled(ControllerWrapper)`
  left: 8px;
`

const RightControllerWrapper = styled(ControllerWrapper)`
  right: 8px;
`

export const Controller = styled.div`
  background: ${({ theme }) => rgba(theme.colors.primaryButton, 0.85)};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background: ${({ theme }) => theme.colors.primaryButton};
  }
`

export const GalleryControllerIcon = styled(ColoredIcon)`
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.primaryButtonText};
`

const Image = styled(ProgressiveImage)`
  width: 100%;
  height: 100%;

  img {
   position: absolute;
   top: 0;
   bottom: 0;
   margin: auto;
   width: 100%;
   height: auto;
 }
`

export const ImageNumberIndicator = styled.div`
  position: absolute;
  display: block;

  background: ${({ theme }) => rgba(theme.colors.primaryButton, 0.85)};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  top: 8px;
  right: 8px;
  width: 52px;
  height: 22px;

  color: ${({ theme }) => theme.colors.primaryButtonText};
  text-align: center;
  line-height: 22px;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize('12px')};
  font-family: ${({ theme }) => theme.fonts.family};
`

const ImagesWrapper = styled.div`
  height: 100%;
  transition: transform 0.45s ease-out 0s;
  width: auto;
  display: flex;
`

const Slide = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1 0 auto;
`

export function images (images, T, name) {
  if (images.length === 0) return null
  if (images.length === 1)
    return <Image src={images[0]} alt={ (name ? `${name} ` : ``) + T('ui:Storefront photo') }/>
  return <Gallery images={images} T={T} name={name}/>
}

const Gallery = ({ images, T, name }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [translateValue, setTranslateValue] = useState(0)

  const imageNumberIndicatorText = `${currentImageIndex + 1} of ${images.length}`

  const nextClicked = () => {
    let nextImageIndex = currentImageIndex + 1
    let nextTranslateValue = translateValue - slideWidth()

    if (nextImageIndex === images.length) {
      nextImageIndex = 0
      nextTranslateValue = 0
    }

    setCurrentImageIndex(nextImageIndex)
    setTranslateValue(nextTranslateValue)
  }

  const previousClicked = () => {
    let previousImageIndex = currentImageIndex - 1
    let previousTranslateValue = translateValue + slideWidth()

    if (previousImageIndex < 0) {
      previousImageIndex = images.length - 1
      previousTranslateValue = -(previousImageIndex * slideWidth()) // Slide through to the last img
    }

    setCurrentImageIndex(previousImageIndex)
    setTranslateValue(previousTranslateValue)
  }

  const slideWidth = () => {
    return document.querySelector('.gallery-wrapper').clientWidth
  }

  return <GalleryWrapper className="gallery-wrapper">
    <ImagesWrapper translateValue={translateValue} style={{
      transform: `translateX(${translateValue}px)`
    }}>
      {
        images.map((image, index) => (
          <Slide key={index}>
            <Image key={index} isShowing={index === currentImageIndex} src={image} alt={ (name ? `${name} ` : ``) + T('ui:Storefront photo') + ` #${index + 1} / ${images.length}` }/>
          </Slide>))
      }
    </ImagesWrapper>

    <LeftControllerWrapper>
      <ToolTipComponent tooltipText={T('ui:Prev Photo')} arrowPosition="top">
        <Controller onClick={previousClicked}>
          <GalleryControllerIcon id='chevron-left' width={24} height={24} />
        </Controller>
      </ToolTipComponent>
    </LeftControllerWrapper>

    <RightControllerWrapper>
      <ToolTipComponent tooltipText={T('ui:Next Photo')} arrowPosition="top">
        <Controller onClick={nextClicked}>
          <GalleryControllerIcon id='chevron-right' width={24} height={24} />
        </Controller>
      </ToolTipComponent>
    </RightControllerWrapper>

    <ImageNumberIndicator>{imageNumberIndicatorText}</ImageNumberIndicator>
  </GalleryWrapper>
}
