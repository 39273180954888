import { rgba } from 'polished'
import React, { useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'

import { ColoredIcon } from '../../../../src/ui/icons/Icon.jsx'
import { Input2 } from '../../../../src/ui/inputs/Input2.jsx'
import { getThemeColor } from '../../../../src/utils/styled-component-tools.js'

const Wrapper = styled.div`
  padding: 10px;
  background: ${({ theme }) => theme.colors.widgetBackground};
  width: 100%;
  input {
    border: none;
    border-radius: 0;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const BackButton = styled.button`
  margin: 0 16px 0 8px;
  border: none;
  background: none;
`

const StyledInput = styled(Input2)`
  background: ${({ theme }) => rgba(theme.colors.widgetText, 0.1)};
`

const StyledCustomActionTitle = styled.h1`
  color: ${getThemeColor('widgetText', '#333333')};
  font-size: 18px !important;
  margin-left: -4px !important;
  font-weight: 500 !important;
`

const HeaderMobile = withTheme(
  ({
    theme,
    widgetState,
    handleInputChange,
    onBackButtonClicked,
    handleKeyPress,
    searchPlaceholder,
    T
  }) => {
    const [state, setWidgetState] = useState(widgetState.getState())

    useEffect(() => widgetState.addCallback(setWidgetState))

    return (
      <Wrapper data-cy="HeaderMobile">
        <Row>
          <BackButton
            onClick={onBackButtonClicked}
            title="Navigate Back"
            data-cy="searchBackButton"
          >
            <ColoredIcon
              className="icon"
              width={32}
              height={32}
              id="arrow-left"
              fillColor={theme.colors.widgetIconFill}
            />
          </BackButton>
          { state.customActionName && state.customActionCategory
            ? (
              <StyledCustomActionTitle data-cy='CustomActionText' aria-label={`${state.customActionName}`}>{state.customActionName}</StyledCustomActionTitle>)
            : <StyledInput
              data-cy="SearchInputBox"
              color={theme.colors.widgetText}
              placeholder={searchPlaceholder}
              value={state.term}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              T={T}
              aria-autocomplete="list"
              aria-haspopup={false}
              aria-controls='SuggestedLocations'
            />}

        </Row>
      </Wrapper>
    )
  }
)

export default HeaderMobile
