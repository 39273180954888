const _localStorage = {}
let __localStorage

if (typeof window === 'undefined' || !window.localStorage) {
  __localStorage = {
    getItem: function (k) {
      return _localStorage[k]
    },
    setItem: function (k, v) {
      _localStorage[k] = v
    },
    removeItem: function (k) {
      delete _localStorage[k]
    }

  }
} else {
  __localStorage = window.localStorage
}

export default __localStorage
