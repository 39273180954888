import React, { useEffect, useState } from 'react'

import DirectionsResultControlsView from './DirectionsResultControlsView.js'
import DirectionsResultControlsViewMobile from './DirectionsResultControlsViewMobile.js'
import DirectionsResultControlsViewMulti from './DirectionsResultControlsViewMulti.jsx'

const DirectionsResultControls = ({
  onBackFromNavigationClicked,
  onBackButtonClicked,
  onBackToSearchButtonClicked,
  onSwitchDirectionsButtonClicked,
  onSwitchRouteTypeButtonClicked,
  onInputClearButtonClick,
  handleChange,
  widgetState,
  handleInputSelect,
  isBackButtonDisabled,
  isDesktop,
  logoUrl,
  clientName,
  multipointRouting,
  handleSearchInputsChange,
  handleAddStopSelect,
  T
}) => {
  const [state, setWidgetState] = useState(widgetState.getState())
  useEffect(() => widgetState.addCallback(setWidgetState))

  const preferredUnits = state.preferredUnits

  return (
    multipointRouting
      ? (
        <DirectionsResultControlsViewMulti {...state}
          isNavigating={true}
          logoUrl={logoUrl}
          clientName={clientName}
          isBackButtonDisabled={isBackButtonDisabled}
          onBackButtonClicked={onBackButtonClicked}
          onBackFromNavigationClicked={onBackFromNavigationClicked}
          onSwitchDirectionsButtonClicked={onSwitchDirectionsButtonClicked}
          onSwitchRouteTypeButtonClicked={onSwitchRouteTypeButtonClicked}
          onInputClearButtonClick={onInputClearButtonClick}
          onInputChange={handleChange}
          onInputSelect={handleInputSelect}
          searchInputs={widgetState.lastState.searchInputs}
          handleSearchInputsChange={handleSearchInputsChange}
          handleAddStopSelect={handleAddStopSelect}
          T={T}
          widgetState={widgetState}
          isMobile={!isDesktop()}
        />
      )
      : isDesktop()
        ? (
          <DirectionsResultControlsView {...state}
            isNavigating={true}
            logoUrl={logoUrl}
            clientName={clientName}
            isBackButtonDisabled={isBackButtonDisabled}
            onBackButtonClicked={onBackButtonClicked}
            onBackFromNavigationClicked={onBackFromNavigationClicked}
            onSwitchDirectionsButtonClicked={onSwitchDirectionsButtonClicked}
            onSwitchRouteTypeButtonClicked={onSwitchRouteTypeButtonClicked}
            onInputCloseButtonClick={onInputClearButtonClick}
            onInputChange={handleChange}
            onInputSelect={handleInputSelect}
            T={T}
          />
        )
        : (
          <DirectionsResultControlsViewMobile {...state}
            isBackButtonDisabled={isBackButtonDisabled}
            onBackButtonClicked={onBackToSearchButtonClicked}
            onBackFromNavigationClicked={onBackFromNavigationClicked}
            onSwitchDirectionsButtonClicked={onSwitchDirectionsButtonClicked}
            onSwitchRouteTypeButtonClicked={onSwitchRouteTypeButtonClicked}
            onInputCloseButtonClick={onInputClearButtonClick}
            preferredUnits={preferredUnits}
            onInputChange={handleChange}
            onInputSelect={handleInputSelect}
            T={T}
          />
        )
  )
}

export default DirectionsResultControls
