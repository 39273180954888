import DesktopCompass from '@locus-labs/mol-desktop-compass'
import { compose, prop } from 'ramda'
import React, { useEffect, useState } from 'react'

import { CompassView } from './compassViewOnlineMobile.jsx'

const DEFAULT_BEARING = 0

const MapCompass = ({ isDesktop, handleMapResetButtonClick, widgetState, T }) => {
  const [bearing, setBearing] = useState(0)
  useEffect(() => {
    setBearing(widgetState.getState().bearing)
    return widgetState.addCallback(compose(setBearing, prop('bearing')))
  }, [])

  if (isDesktop)
    return <DesktopCompass
      onCompassClick={handleMapResetButtonClick}
      bearing={bearing}
      tooltipContent={T('mapCompass:Reset Map Rotation')} />
  // TODO: I can't see where we use this component anymore, can we just delete it? We currently only use a compass on desktop
  else return <CompassView
    resetBearingValue={DEFAULT_BEARING}
    onCompassClick={handleMapResetButtonClick}
    bearing={bearing}/>
}

export default MapCompass
