import {
  length,
  map,
  groupBy
} from 'ramda'
import React, { useEffect, useState } from 'react'
import { withTheme } from 'styled-components'

import { ColoredIcon } from '../../../../src/ui/icons/Icon.jsx'
import FocusTrap from '../../../../src/ui/modals/FocusTrap.jsx'
import useComponentFocused from '../../../../src/utils/hooks/useComponentFocused.js'

import {
  MapNavigatorMobile,
  BuildingWrapperMobile,
  BuildingDropdownMobile,
  LevelDropdownMobile,
  Submenu,
  DragIndicator,
  DragCloseButton
} from './styles.js'

const BUILDING_SELECT_EVENT_NAME = 'mapLevelSelector/selectBuilding'
const LEVEL_SELECT_EVENT_NAME = 'mapLevelSelector/selectLevel'

const OpenedMapLevelSelectorMobile = withTheme(({ theme, widgetState, bus }) => {
  const { isComponentFocused, setIsComponentFocused } = useComponentFocused(true)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [state, setState] = useState(widgetState.getState())
  useEffect(() => widgetState.addCallback(setState))

  useEffect(() => {
    // if the outside click was detected, but menu was open, hide it
    if (!isComponentFocused && isMenuOpen) {
      setIsOpen(false)
    }
  }, [isComponentFocused])

  useEffect(() => {
    if (state.isMenuOpen) {
      setIsMenuOpen(true)
      setIsComponentFocused(true)
    } else {
      setIsMenuOpen(false)
    }
  }, [state.isMenuOpen])

  // Focus on the proper building selector once the building selector opens
  useEffect(() => {
    if (isMenuOpen) {
      const elementToFocus = Array.from(
        document.querySelectorAll('.selectedBg')
      )[0]
      if (elementToFocus) {
        elementToFocus.focus()
      } else {
        const elementToFocus = Array.from(
          document.querySelectorAll('.mobileBuildingSelector')
        )[0]
        if (elementToFocus) {
          elementToFocus.focus()
        }
      }
    }
  }, [isMenuOpen])

  const getBuildingCounts = () => {
    const searchLocations = map(result => result.position, state.searchResults)
    const byBuilding = groupBy(result => result.buildingId)
    return byBuilding(searchLocations) || []
  }

  const buildingCounts = getBuildingCounts()

  const getLevelCounts = buildingResults => {
    const byLevel = groupBy(result => result.floorId)
    return byLevel(buildingResults)
  }

  const handleBuildingSelectButtonClick = (building) => {
    bus.send(BUILDING_SELECT_EVENT_NAME, { id: building.id })
  }

  const handleLevelSelectButtonClick = (level) => {
    bus.send(LEVEL_SELECT_EVENT_NAME, { id: level.id })
  }

  const setIsOpen = (isOpen = false) => {
    bus.send('mapLevelSelectorOnline/openLevelSelector', { isOpen: isOpen })
    setIsComponentFocused(isOpen)
  }

  const renderBuildingSelectorMobile = (building, selectedBuilding, buildingResults) => {
    const levelCounts = buildingResults ? getLevelCounts(buildingResults) : []
    const isSelected = selectedBuilding && building.id === selectedBuilding.id
    const themeColors = theme.colors
    return (
      <BuildingWrapperMobile key={building.id} themeColors={themeColors}>
        <BuildingDropdownMobile onClick={() => handleBuildingSelectButtonClick(building)} themeColors={themeColors} className="mobileBuildingSelector" aria-selected={isSelected} aria-label={building.name} data-cy={'building-list-item'}>
          <div>
            <ColoredIcon id={isSelected ? 'triangle-down' : 'triangle-right'} width={32} height={32} fillColor={themeColors.mapNavText} />
          </div>
          <div className="buildingName">{building.name}</div>
          {length(buildingResults) > 0
            ? <div className="indicator" data-cy='buildingResultsIndicator'>{length(buildingResults)}</div>
            : <div />}
        </BuildingDropdownMobile>
        {isSelected
          ? <Submenu data-cy='expandedLevelSelector'>
            {Object
              .values(selectedBuilding.levels)
              .map(level => renderLevelSelectorMobile(level, levelCounts[level.id]))}
          </Submenu>
          : <div />}
      </BuildingWrapperMobile>
    )
  }

  const renderLevelSelectorMobile = (level, levelResults) => {
    const { selectedLevel } = state
    const isSelected = selectedLevel && level.id === selectedLevel.id

    return (
      <LevelDropdownMobile
        key={level.id}
        onClick={() => handleLevelSelectButtonClick(level)}
        className={isSelected ? 'selectedBg' : null}
        data-cy='level-list-item'
        aria-selected={isSelected}
      >
        <div className={isSelected ? 'selected' : 'notSelected'} />
        <div className="levelName">{level.details}</div>
        <div className="secondaryLabel">{level.name}</div>
        {length(levelResults) > 0
          ? <div className="indicator" data-cy='levelResultsIndicator'>{length(levelResults)}</div>
          : <div />}
      </LevelDropdownMobile>)
  }

  return <MapNavigatorMobile data-cy='expandedBuildingSelector' id='expandedBuildingSelector'>
    <DragCloseButton className="exitSelector" onClick={() => setIsOpen(false)} title="Collapse Map Navigator">
      <DragIndicator />
    </DragCloseButton>
    {map((building) => renderBuildingSelectorMobile(building, state.selectedBuilding, buildingCounts[building.id]), state.buildings)}
  </MapNavigatorMobile>
})

const FocusTrappedLevelSelector = FocusTrap(OpenedMapLevelSelectorMobile, 'expandedBuildingSelector', '[data-cy=building-list-item]')

const MapLevelSelectorViewMobile = props => {
  const [state, setState] = useState(props.widgetState.getState())
  useEffect(() => props.widgetState.addCallback(setState))

  if (state.isMenuOpen)
    return <FocusTrappedLevelSelector {...props}/>
  else
    return null
}

export default MapLevelSelectorViewMobile
