import Logo from '@locus-labs/mol-desktop-logo'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import { ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'
import SearchInputLabelled from '../../../../../src/ui/inputs/SearchInputLabelled.js'
import ToolTipComponent from '../../../../../src/ui/tooltips/ToolTipComponent.jsx'
import { handleKeyboardSelect } from '../../../../utils/keyboardUtils.js'
import { FROM_SEARCH_FIELD_ID, TO_SEARCH_FIELD_ID } from '../constants.js'

import RouteOptions from './RouteOptions.jsx'
import { LevelInfo, TopButtons } from './styles.js'

const WidgetWrapper = styled.div`
  padding: ${({ hasLogoUrl, theme }) => hasLogoUrl && theme.header.hasLogo ? theme.header.paddingWithLogo : theme.header.padding};
  `

const LogoWrapper = styled.div`
  padding: 0 0 20px 0;
`

const DirectionsResultControlsView = ({
  endpoints,
  showLevelName,
  currentLevelName,
  routeAccessibiltyChoice,
  routeTypes,
  onSwitchRouteTypeButtonClicked,
  onBackButtonClicked,
  onSwitchDirectionsButtonClicked,
  isBackButtonDisabled,
  logoUrl,
  clientName,
  theme,
  onInputChange,
  onInputCloseButtonClick,
  onInputSelect,
  T
}) => {
  const isBackButtonEnabled = !isBackButtonDisabled
  return (
    <WidgetWrapper data-cy={'DirectionsResultControlsView'} hasLogoUrl={typeof logoUrl !== 'undefined'}>
      {(logoUrl && theme.header.hasLogo) && <LogoWrapper><Logo logoHeight={ theme.logo.height } alt={ clientName } url={ logoUrl } width="auto" position={ theme.logo.centered ? 'center' : 'left' } padding={ theme.logo.padding }/></LogoWrapper>}
      <TopButtons theme={theme}>
        {isBackButtonEnabled
          ? <div
            onClick={onBackButtonClicked}
            data-cy='ReturnHomeButton'
            role="button"
            tabIndex={'0'}
            onKeyDown={handleKeyboardSelect(() => onBackButtonClicked())}>
            <ColoredIcon className="icon" width={24} height={24} id="arrow-left" fillColor={theme.colors.widgetIconFill} />
            <u>{T('getDirectionsFromTo:Home')}</u>
          </div>
          : null}

        <ToolTipComponent tooltipText={T('getDirectionsFromTo:Swap start and end points around')} arrowPosition="left">
          <div
            onClick={onSwitchDirectionsButtonClicked}
            data-cy='DirectionSwapButton'
            role="button"
            tabIndex={'0'}
            onKeyDown={handleKeyboardSelect(() => onSwitchDirectionsButtonClicked())}>
            <u>{T('getDirectionsFromTo:Swap')}</u>
            <ColoredIcon className="icon" width={24} height={24} id="swap" fillColor={theme.colors.widgetIconFill} />
          </div>
        </ToolTipComponent>

      </TopButtons>
      <div>
        <SearchInputLabelled
          id={FROM_SEARCH_FIELD_ID}
          value={endpoints[0]?.title}
          label={T('getDirectionsFromTo:From') + ':'}
          onInputChange={onInputChange}
          onInputSelect={onInputSelect}
          onClick={() => onInputSelect(FROM_SEARCH_FIELD_ID)}
          onInputCloseButtonClick={onInputCloseButtonClick}
          autoFocus={false}
          T={T}
          data-cy={'SearchInputFrom'}
        />
        <SearchInputLabelled
          id={TO_SEARCH_FIELD_ID}
          value={endpoints[1]?.title}
          label={T('getDirectionsFromTo:To') + ':'}
          onInputChange={onInputChange}
          onInputSelect={onInputSelect}
          onClick={() => onInputSelect(TO_SEARCH_FIELD_ID)}
          onInputCloseButtonClick={onInputCloseButtonClick}
          autoFocus={false}
          T={T}
          data-cy={'SearchInputTo'}
        />
      </div>
      <RouteOptions routeAccessibiltyChoice={routeAccessibiltyChoice} routeTypes={routeTypes} onSwitchRouteTypeButtonClicked={onSwitchRouteTypeButtonClicked} T={T} />

      {showLevelName &&
        <LevelInfo>
          <span>{currentLevelName}</span>
        </LevelInfo>
      }
    </WidgetWrapper>

  )
}

export default withTheme(DirectionsResultControlsView)

DirectionsResultControlsView.propTypes = {
  /* eslint-disable key-spacing */
  endpoints: PropTypes.array,
  routeAccessibiltyChoice: PropTypes.string,
  routeTypes: PropTypes.string,
  isNavigating: PropTypes.bool,
  currentLevelName: PropTypes.string,
  showLevelName: PropTypes.bool
  /* eslint-enable key-spacing */
}
