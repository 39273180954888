import React from 'react'
import styled from 'styled-components'

import { handleKeyboardSelect, handleKeyboardEscape } from '../../../plugins/utils/keyboardUtils.js'
import { listElementHoverCss } from '../../../src/ui/hoverStates.js'
import { ColoredIcon } from '../icons/Icon.jsx'

const SearchSuggestionsElem = styled.li`
  padding: 12px 14px;
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${listElementHoverCss};
  }
`

const SearchSuggestionIcon = styled.div`
  width: 36px;
  text-align: center;

  ${ColoredIcon} {
    vertical-align: middle;
  }
`

const SearchSuggestionInfo = styled.span`
  display: block;
  margin-left: 12px;
  color: #333;
  ${({ theme }) => theme.fontSize('16px')};
  font-weight: 400;
  line-height: 150%;
`

const SearchSuggestionsListStyle = styled.ul`
  padding: 0;
  margin: 0;
`

const SearchSuggestionsList = ({ suggestions, onSuggestionClicked, handleEscapeKey, T }) => {
  return <SearchSuggestionsListStyle role='group' aria-label={T('homeView:Suggestions')} id='SearchSuggestions' >
    {suggestions.map((suggestion, index) => {
      return <SearchSuggestionsElem
        key={index}
        onClick={() => onSuggestionClicked(suggestion)}
        data-cy={'searchResultsElementKeyword'}
        tabIndex={'0'}
        role='button'
        aria-label={suggestion.toLowerCase()}
        onKeyDown={
          e => handleKeyboardSelect(() => onSuggestionClicked(suggestion))(e) ||
            handleKeyboardEscape(handleEscapeKey)(e) // try one then the other..
        }
      >
        <SearchSuggestionIcon>
          <ColoredIcon id="search" width={32} height={32} fillColor="#666" />
        </SearchSuggestionIcon>
        <SearchSuggestionInfo>{suggestion.charAt(0).toUpperCase() + suggestion.slice(1)}</SearchSuggestionInfo>
      </SearchSuggestionsElem>
    })}
  </SearchSuggestionsListStyle>
}

export default SearchSuggestionsList
