import { rgba } from 'polished'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import { CtaButton } from '../../../../../src/ui/buttons/Button.jsx'
import { ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'
import ToolTipComponent from '../../../../../src/ui/tooltips/ToolTipComponent.jsx'
import { handleKeyboardSelect } from '../../../../utils/keyboardUtils.js'
import HandlerContext from '../handlerContext.js'

const ButtonWrapper = styled.div`
  display: flex;
  margin: 12px 0;
  color: ${({ theme, disabled }) => disabled ? rgba(theme.colors.primaryButtonText, 0.4) : theme.colors.primaryButtonText};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${ColoredIcon} {
    margin-right: 8px;
  }


`

const CtaButtonStyled = styled(CtaButton)`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:focus-visible {
    outline: 1px solid blue;
    outline-offset: 2px;
  }
`

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`

const GetDirectionsButton = withTheme(({ theme, poi, T, multipointRouting, searchInputsLength }) => {
  const maxStopsReached = Boolean(multipointRouting && searchInputsLength >= 7)
  return poi.isNavigable
    ? <HandlerContext.Consumer>
      {ctx =>
        <ButtonWrapper data-cy='GetDirectionsButton' disabled={maxStopsReached}>
          <CtaButtonStyled
            disabled={maxStopsReached}
            onClick={(e) => {
              e.stopPropagation()
              ctx.onGetDirectionsClicked()
            }}
            backgroundColor={theme.colors.primaryButton}
            hoverBackgroundColor={theme.colors.primaryButtonHover}
            fontColor={theme.colors.primaryButtonText}
            data-cy='GetDirectionsToPoiButton'
            tabIndex="0" aria-label={T('poiView:Get Directions')}
            onKeyDown={handleKeyboardSelect(() => ctx.onGetDirectionsClicked(), { stopPropagation: true })}>
            <IconWrapper>
              <ColoredIcon id='directions-filled' width={24} height={24} fillColor={theme.colors.primaryButtonText} />
            </IconWrapper>
            <ToolTipComponent tooltipText={T('poiView:Maximum Stops Reached')} arrowPosition={'left'} isVisible={maxStopsReached}>
              <span>{(multipointRouting && searchInputsLength > 1) ? T('poiView:Add Stop') : T('poiView:Get Directions')}</span>
            </ToolTipComponent>
          </CtaButtonStyled>
        </ButtonWrapper>
      }
    </HandlerContext.Consumer >
    : null
})

export default GetDirectionsButton
