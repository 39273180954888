import React from 'react'
import styled, { withTheme } from 'styled-components'

const LoadingWrapper = styled.div`
  position: relative;
  background: white;
  height: 100%;
  width: 100%;

  div:nth-child(2) {
    visibility: ${({ hasLoaded }) => hasLoaded ? 'visible' : 'hidden'};
  }

  .loadingIndicatorWrapper
  {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    font-size: 1px;
  }

  .spinner {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.theme.colors.primary} transparent transparent transparent;
  }

  .spinner div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .spinner div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .spinner div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const MainContent = styled.div`
  width: 100%;
  height: 100%;
`

export const LoadingIndicator = withTheme((props) => (
  <LoadingWrapper {...props} >
    <div className='loadingIndicatorWrapper'>
      <div className="spinner"><div></div><div></div><div></div><div></div></div>
      <div role="alert" aria-label={props?.message ? props.message :"loading"}></div>
    </div>
    <MainContent>{props.children}</MainContent>
  </LoadingWrapper>
))
