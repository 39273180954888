function convertMetersToMiles (meters, precision = 1) {
  return parseFloat((meters * 0.000621371192).toFixed(precision))
}

const yardsPerMeter = 1.0936132983377 // 1 meter ≈ 1.0936 yards
const metersToYards = meters => Math.round(meters * yardsPerMeter)

export {
  convertMetersToMiles,
  metersToYards
}
