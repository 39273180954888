import { rgba } from 'polished'
import { isEmpty } from 'ramda'
import React, { useState, useEffect } from 'react'
import styled, { withTheme } from 'styled-components'

import { handleKeyboardSelect } from '../../../plugins/utils/keyboardUtils.js'
import { ColoredIcon } from '../icons/Icon.jsx'
import ToolTipComponent from '../tooltips/ToolTipComponent.jsx'

const InputWrapper = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  width: 100%;

  .-mobile & {
    padding: 5px 8px;
  }

  input:placeholder-shown + div {
    display: none;
  }
`

const StyledInput = styled.input`
  min-width: 1px;
  width: 100%;
  padding: 0.5em;
  color: ${props => props.isValid ? props.color : '#D83131'};
  border: 0;
  background: transparent;
  text-overflow: ${props => isEmpty(props.value) ? 'clip' : 'ellipsis'};
  white-space: ${props => isEmpty(props.value) ? 'normal' : 'nowrap'};
  overflow: hidden;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${props => rgba(props.color, 0.8)};
  }

  &::-ms-clear {
    display: none;
  }
`

const ClearButton = styled.div`
  cursor: pointer;
  position: relative;

  &:hover > .tooltip {
    display: block;
  }
`

export const Input2 = withTheme(props => {
  const {
    hasClearButton = true,
    value,
    onChange,
    onKeyPress,
    color,
    isValid = true,
    className,
    T,
    forwardRef,
    handleClearButtonKeyDown,
    ...otherProps
  } = props

  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  const valueChanged = val => {
    setLocalValue(val)
    onChange(val)
    document.getElementById('tooltip').setAttribute('style', 'display: none;')
  }

  return (
    <InputWrapper color={color} className={className}>
      <StyledInput
        type="text"
        ref={forwardRef}
        isValid={isValid}
        color={color}
        value={localValue}
        onKeyPress={onKeyPress}
        onChange={e => valueChanged(e.target.value)}
        hasClearButton={hasClearButton}
        {...otherProps}
      />
      {(hasClearButton && value.length)
        ? <ToolTipComponent tooltipText={T('ui:Clear Search')} arrowPosition="top">
          <ClearButton
            onClick={() => valueChanged('')}
            data-cy={'searchClearButton'}
            tabIndex={'0'}
            aria-label={T('ui:Clear Search')}
            role='button'
            onKeyDown={handleClearButtonKeyDown || handleKeyboardSelect(() => valueChanged(''))}>
            <ColoredIcon id="close" width={32} height={32} fillColor={props.theme.colors.widgetIconFill} />
          </ClearButton>
        </ToolTipComponent>
        : null}
    </InputWrapper>)
})
