import { pipe, identity, prop, trim, when, uniq } from 'ramda'

export function initRecentSearches (bus) {
  const recentSearchTerms = []

  const addTerm = term => recentSearchTerms.unshift(term)
  const checkAndAddToRecents = pipe(prop('term'), trim, when(identity, addTerm))
  bus.monitor('search/queryAsync', checkAndAddToRecents)
  bus.monitor('search/queryWithSpecial', checkAndAddToRecents)
  bus.monitor('search/queryNearby', checkAndAddToRecents)

  bus.on('search/recentSearches', ({ limit }) => {
    const recentSearches = uniq(recentSearchTerms.slice(0, limit))
    bus.send('search/showRecentSearches', { recentSearches })
  })
}
