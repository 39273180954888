import { rgba } from 'polished'
import * as PropTypes from 'prop-types'
import { map } from 'ramda'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import { ColoredIcon } from '../../../src/ui/icons/Icon.jsx'

const MenuOption = styled.button`
  width: 100%;
  height: 56px;
  box-shadow: inset 0px -1px 0px #EBEBEB;
  background: none;
  display: flex;
  align-items: center;
  padding: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primaryText};

  &:hover {
    background-color: ${({ theme }) => rgba(theme.colors.widgetText, 0.1)};
  }

  .menuLabel {
    font-size: 16px;
    line-height: 19px;
    margin-left: 8px;
  }

  &:focus-visible {
    outline: 1px solid orange;
  }
`

const handleMenuOptionClick = (onClickOption, hideMenu) => {
  onClickOption()
  hideMenu()
}

const renderMenuOption = (option, themeColors, isMobile, hideMenu) => (
  <MenuOption
    key={option.label}
    onClick={() => handleMenuOptionClick(option.onClick, hideMenu)}
    className={`menuOption${isMobile ? '-mobile' : null}`}
    data-cy='MenuOption'
  >
    <ColoredIcon id={option.iconId} width={32} height={32} fillColor={themeColors.primaryText} />
    <div className="menuLabel">{option.label}</div>
  </MenuOption>)

const MenuSelection = withTheme(({ theme, menuOptions, isMobile, hideMenu }) => {
  return (
    <>{map(option => renderMenuOption(option, theme.colors, isMobile, hideMenu), menuOptions)}</>
  )
})

MenuSelection.propTypes = { menuOptions: PropTypes.array.isRequired }

export default MenuSelection
