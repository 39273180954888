import styled from 'styled-components'

export const Wrapper = styled.div`
  grid-area: header;
  padding: 15px;
  background-color: #000;
`
export const Title = styled.div`
  color: ${({ theme }) => theme.colors.widgetText};
  font-size: 16px;
`
