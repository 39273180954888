import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.a`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryButton};
  }
`

const Link = ({ href, children, className, onClick }) => {
  return (
    <Wrapper className={className} href={href} target="_blank" onClick={onClick}>
      {children}
    </Wrapper>
  )
}

export default Link
