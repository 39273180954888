import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  ${({ theme }) => theme.fontSize('10px')};
  font-weight: 500;
  line-height: 13px;
`

export default ({ app }) => <Wrapper>v{app.info.wePkg.version}</Wrapper>
