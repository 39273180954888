import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { ColoredIcon } from '../icons/Icon.jsx'

const ButtonDefaultStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  &:focus-visible {
    outline: 1px solid blue;
    outline-offset: 2px;
  }
  border: none;
  user-select: none;
  text-decoration: none;
  ${({ theme }) => theme.borderRadius(theme.globalRadius)};
  font-family: ${({ theme }) => theme.fonts.family};
  color: ${({ fontColor }) => fontColor};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  ${({ theme }) => theme.fontSize('14px')};
  -webkit-appearance: none;

  &:hover {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
  }
`

export const Button = styled.button`
  ${ButtonDefaultStyle}
`

export const StandardButton = styled(Button)`
  padding: 16px 20px;
  margin: 0 10px;
  font-weight: 500;
  transition: all .1s ease-in;
  background-color: ${({ theme }) => theme.colors.primaryButton};
  color: ${({ theme, disabled }) => disabled ? rgba(theme.colors.primaryButtonText, 0.4) : theme.colors.primaryButtonText};
  ${({ theme }) => theme.fontSize('16px')};
  ${({ theme }) => theme.borderRadius(8)};

  ${ColoredIcon} {
    fill: ${({ theme, disabled }) => disabled ? rgba(theme.colors.primaryButtonText, 0.4) : theme.colors.primaryButtonText}
  }

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.primaryButtonHover};
  }

  &:focus-visible {
    outline: 2px solid blue;
    outline-offset: 2px;
  }
`

export const FlatButton = styled(StandardButton)`
  text-align: center;
  ${({ theme }) => theme.fontSize('16px')};
  line-height: 150%;
  font-weight: 500;
  padding: 8px;
  color: ${({ theme, disabled }) => disabled ? rgba(theme.colors.secondaryButtonText, 0.5) : theme.colors.secondaryButtonText};

  &:not([disabled]) {
    cursor: pointer;
  }

  &:hover, &:active {
    color: ${({ theme, disabled }) => disabled ? rgba(theme.colors.secondaryButtonText, 0.5) : theme.colors.secondaryButtonText};

    ${ColoredIcon} {
      fill: ${({ theme, disabled }) => disabled ? rgba(theme.colors.secondaryButtonText, 0.5) : theme.colors.secondaryButtonText};
    }
  }

  ${ColoredIcon} {
    fill: ${({ theme, disabled }) => disabled ? rgba(theme.colors.secondaryButtonText, 0.5) : theme.colors.secondaryButtonText} ;
  }
`

export const CtaButton = styled(Button)`
  ${({ theme }) => theme.borderRadius(2)};
  flex: 1 0 auto;
  text-align: center;
  padding: 14px 5px;
  font-weight: 500;
`

export const LinkButton = styled.a`
  ${ButtonDefaultStyle}
  ${({ theme }) => theme.borderRadius(2)};
  flex: 1 0 auto;
  padding: 10px 5px;
  font-weight: 500;
`
