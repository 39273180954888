class MinPriorityQueue {
  constructor () {
    this.heap = [null] // array representation of binary heap
    this.heapMap = {}
  }

  offerWithPriority (node, priority) {
    const index = this.heap.push([node, priority]) - 1

    this.heapMap[node] = index
    this.bubble(index)
  }

  raisePriority (node, priority) {
    const index = this.heapMap[node]
    this.heap[index][1] = priority

    this.bubble(index)
  }

  poll () {
    if (this.heap.length === 1) {
      return null
    }
    if (this.heap.length === 2) {
      const value = this.heap.pop()[0]
      delete this.heapMap[value]
      return value
    }

    const value = this.heap[1][0]
    delete this.heapMap[value]

    this.heap[1] = this.heap.pop()
    this.heapMap[this.heap[1][0]] = 1

    this.sink(1)

    return value
  }

  isEmpty () {
    return this.heap.length === 1
  }

  bubble (i) {
    while (i > 1) {
      const parentIndex = i >> 1 // floor(i/2)

      if (!this.isHigherPriority(i, parentIndex)) {
        break
      }

      this.swap(i, parentIndex)
      i = parentIndex
    }
  }

  sink (i) {
    while (i * 2 < this.heap.length) {
      const isRightChildHigherPriority = (this.heap[i * 2 + 1] === undefined)
        ? false
        : this.isHigherPriority(i * 2 + 1, i * 2) // i*2 +1 might be null
      const childIndex = isRightChildHigherPriority ? i * 2 + 1 : i * 2

      if (this.isHigherPriority(i, childIndex)) {
        break
      }

      this.swap(i, childIndex)
      i = childIndex
    }
  }

  swap (i, j) {
    this.heapMap[this.heap[i][0]] = j
    this.heapMap[this.heap[j][0]] = i

    const temp = this.heap[i]
    this.heap[i] = this.heap[j]
    this.heap[j] = temp
  }

  isHigherPriority (i, j) {
    return this.heap[i][1] < this.heap[j][1] // lower score -> higher priority
  }
}

export default MinPriorityQueue
