import Icon from '@locus-labs/mol-desktop-icon'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import { LinkButton } from '../../../../../src/ui/buttons/Button.jsx'

const Text = styled.span`
  line-height: normal;
  margin-top: -3px;
`

const Wrapper = styled.div`
  display: flex;
  margin: 12px 0;

  ${LinkButton} {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 52px;
    padding: 0 5px;

    &:only-of-type {
      padding: 10px 5px;
      flex-direction: row;
      justify-content: center;

      ${Text} {
        margin-top: 0;
      }
    }
  }
`

const Spacing = styled.div`
  &:not(:last-of-type) {
    padding-right: 12px;
  }
`

const CTAButtons = withTheme(({ theme, poi, T, submitAnalyticsEvent }) => {
  // Here is a mapping of link types that will result in buttons
  const ctas = {
    menu: { title: T('poiView:Menu'), icon: 'menu' },
    shop: { title: T('poiView:Shop Now'), icon: 'shop' },
    grab: { title: T('poiView:Order/Pay Online'), icon: 'servy' }
  }

  const Button = ({ url, text, iconId, onClick }) => <React.Fragment>
    <LinkButton
      href={url}
      target="_blank"
      backgroundColor={theme.colors.secondaryButton}
      hoverBackgroundColor={theme.colors.secondaryButtonHover}
      fontColor={theme.colors.secondaryButtonText}
      onClick={onClick}
    >
      <Icon iconSize="md" name={iconId} color={theme.colors.secondaryButtonText} />
      <Text>{text}</Text>
    </LinkButton>
    <Spacing />
  </React.Fragment>

  const buttons = poi.links &&
    poi.links.map(
      link => {
        const cta = ctas[link.type]
        return cta
          ? <Button
            key={`cta-button-${link.type}`}
            url={link.url}
            text={cta.title}
            iconId={cta.icon}
            onClick={ () => submitAnalyticsEvent(poi, link.type, link.url)}/>
          : null
      }).filter(x => x) // strip nulls

  return Boolean(buttons && buttons.length) && <Wrapper>
    {buttons}
  </Wrapper>
})

export default CTAButtons
