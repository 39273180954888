import { useEffect } from 'react'
import { v4 as uuid } from 'uuid'

const withId = (obj) => ({ id: uuid(), ...obj })

const useMapSearchInputsToState = (currentInputIndex, searchInputs, setSearchInputs, setDraggingSearchInputs) => {
  useEffect(() => {
    const newSearchInputs = [...searchInputs]
    const inputsWithIds = newSearchInputs.map(withId)
    setSearchInputs(inputsWithIds)
    setDraggingSearchInputs(inputsWithIds)
  }, [currentInputIndex])
}

export default useMapSearchInputsToState
