import * as R from 'ramda'

export const buildStructuresLookup = (structures) => {
  const floors = R.mergeAll(structures.map(R.prop('levels')))
  const floorIdToFloor = floorId => floors[floorId]
  const floorIdToOrdinal = R.pipe(floorIdToFloor, R.prop('ordinal'))
  const floorIdToStructure = floorId => R.find(R.path(['levels', floorId]), structures)
  const floorIdToStructureId = R.pipe(floorIdToStructure, R.prop('id'))
  return {
    floorIdToOrdinal,
    floorIdToFloor,
    floorIdToStructureId,
    floorIdToStructure
  }
}
