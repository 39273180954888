import { useState, useEffect, useRef } from 'react'

export default function useComponentFocused (initialIsFocused) {
  const [isComponentFocused, setIsComponentFocused] = useState(initialIsFocused)
  const ref = useRef(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentFocused(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    document.addEventListener('keydown', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
      document.removeEventListener('keydown', handleClickOutside, true)
    }
  })

  return { ref, isComponentFocused, setIsComponentFocused }
}
