const segmentBadges = {
  START: 'wayfinding.start',
  END: 'wayfinding.end',
  WALKING_TO_SECURITY_CHECKPOINT: 'wayfinding.security',
  ELEVATOR: 'wayfinding.elevator',
  ELEVATOR_UP: 'wayfinding.elevator.up',
  ELEVATOR_DOWN: 'wayfinding.elevator.down',
  STAIRS: 'wayfinding.stairs',
  STAIRS_UP: 'wayfinding.stairs.up',
  STAIRS_DOWN: 'wayfinding.stairs.down',
  ESCALATOR: 'wayfinding.escalator',
  ESCALATOR_UP: 'wayfinding.escalator.up',
  ESCALATOR_DOWN: 'wayfinding.escalator.down',
  WALK: 'wayfinding.movingwalkway',
  WALK_DOWN: 'walk.movingwalkway',
  WALK_UP: 'walk.movingwalkway',
  TRAIN: 'wayfinding.train',
  TRAIN_UP: 'wayfinding.train.up',
  TRAIN_DOWN: 'wayfinding.train.down',
  BUS: 'wayfinding.bus',
  BUS_UP: 'wayfinding.bus.up',
  BUS_DOWN: 'wayfinding.bus.down',
  SECURITY_CHECKPOINT: 'wayfinding.security',
  RAMP: 'wayfinding.ramp',
  RAMP_UP: 'wayfinding.ramp.up',
  RAMP_DOWN: 'wayfinding.ramp.down'
}

export default segmentBadges
