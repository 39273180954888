import { DateTime } from 'luxon'
import * as R from 'ramda'

export { parseAndOrderWeekdays, getCurrentWeekdayOpenHours, isOpen, getOpenTimeLabel }

function getCurrentWeekdayOpenHours (weekdayPeriods, today) {
  const currentWeekday = findWeekday(weekdayPeriods, today)
  return currentWeekday
    ? currentWeekday.timePeriod.formatted
    : ''
}

function isOpen (weekdayPeriods, today) {
  const currentWeekday = findWeekday(weekdayPeriods, today)
  if (!currentWeekday) return false

  const hours = today.getHours()
  const minutes = today.getMinutes()

  const { from, to } = currentWeekday.timePeriod
  return isInTimePeriod(hours, minutes, from.hours, from.minutes, to.hours, to.minutes)
}

function isInTimePeriod (nowHours, nowMinutes, fromHours, fromMinutes, toHours, toMinutes) {
  const isOvernightPeriod = isFirstTimeAfterSecond(fromHours, fromMinutes, toHours, toMinutes)
  if (isOvernightPeriod)
    return isInTimePeriod(nowHours, nowMinutes, fromHours, fromMinutes, 24, 0) ||
      isInTimePeriod(nowHours, nowMinutes, 0, 0, toHours, toMinutes)
  else {
    const isAfterFrom = isFirstTimeAfterSecond(nowHours, nowMinutes, fromHours, fromMinutes)
    const isBeforeTo = isFirstTimeAfterSecond(toHours, toMinutes, nowHours, nowMinutes)
    return isAfterFrom && isBeforeTo
  }
}

function isFirstTimeAfterSecond (firstHours, firstMinutes, secondHours, secondMinutes) {
  return firstHours > secondHours || (firstHours === secondHours && firstMinutes >= secondMinutes)
}

function findWeekday (weekdayPeriods, today) {
  return findWeekdayByDayIndex(weekdayPeriods, today.getDay())
}

function findWeekdayByDayIndex (weekdayPeriods, index) {
  const currentWeekdayName = WEEKDAY_ARRAY[index]
  return weekdayPeriods.find(
    ({ weekday }) => weekday === currentWeekdayName)
}

/**
 Mo-Su 08:00-17:00
 Mo 07:00-01:00; Tu 07:00-03:00; We 07:00-01:00; Th 07:00-03:00; Fr-Sa 07:00-04:30; Su 07:00-03:00
 Mo-Su 12:00-02:00
 Su-Th 10:00-18:00; Fr-Sa 09:00-19:00
 We-Su 17:30-21:30
 Su-Th 10:00-23:00; Fr-Sa 10:00-24:00
 Mo-Th 05:00-10:30; Fr-Su 09:00-22:45
 Su-Th 10:00-23:00; Fr-Sa 10:00-24:00,

 output:
 [ {
    weekday: 'Sunday',
    isToday: true
    timePeriod: {
      from: {
        hours: 8,
        minutes: 0
      },
      to: {
        hours: 23,
        minutes: 0
      },
      formatted: '8:00am-11:00pm'
    }, ...remaining days ]
 */
function parseAndOrderWeekdays (rawWeekdayPeriods, today) {
  const weekdays = parseWeekdayPeriods(rawWeekdayPeriods)
  const orderedWeekdays = orderFromSunday(weekdays)
  return markCurrentWeekday(orderedWeekdays, today)
}

const SHORT_WEEKDAY_TO_NUMERAL = {
  Su: 0,
  Mo: 1,
  Tu: 2,
  We: 3,
  Th: 4,
  Fr: 5,
  Sa: 6
}
const WEEKDAY_ARRAY = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const WEEKDAY_TO_NUMERAL = WEEKDAY_ARRAY.reduce(
  (obj, weekday, index) => Object.assign(obj, { [weekday]: index }), {})
const WEEKDAYS_DELIMITER = ';'
const WEEKDAY_AND_TIME_DELIMITER = /\s+/
const WEEKDAY_DELIMITER = '-'
const TIME_PERIOD_DELIMITER = '-'
const TIME_DELIMITER = ':'
const AM = ' AM'
const PM = ' PM'

function parseWeekdayPeriods (rawWeekdayPeriods) {
  return rawWeekdayPeriods
    .split(WEEKDAYS_DELIMITER)
    .map(weekdayAndTimePeriod => weekdayAndTimePeriod.trim())
    .map(weekdayAndTimePeriod => weekdayAndTimePeriod.split(WEEKDAY_AND_TIME_DELIMITER))
    .flatMap(([weekdayPeriod, timePeriod]) => {
      const weekdays = parseWeekdayPeriod(weekdayPeriod)
      const formattedTimePeriod = prepareTimePeriodData(timePeriod)
      return createWeekdayAndTimePeriodPairs(weekdays, formattedTimePeriod)
    })
}

function parseWeekdayPeriod (weekdayPeriod) {
  const [start, end] = weekdayPeriod.split(WEEKDAY_DELIMITER)

  const startIndex = SHORT_WEEKDAY_TO_NUMERAL[start]
  let weekdayIndices
  if (end) {
    const endIndex = SHORT_WEEKDAY_TO_NUMERAL[end]
    weekdayIndices = weekdayIndicesRange(startIndex, endIndex)
  } else {
    weekdayIndices = [startIndex]
  }

  return weekdayIndices.map(index => WEEKDAY_ARRAY[index])
}

function weekdayIndicesRange (startIndex, endIndex) {
  const weekdayIndices = [startIndex]
  let currentDayIndex = startIndex
  do {
    currentDayIndex = (currentDayIndex + 1) % WEEKDAY_ARRAY.length
    weekdayIndices.push(currentDayIndex)
  } while (currentDayIndex !== endIndex)
  return weekdayIndices
}

function prepareTimePeriodData (timePeriod) {
  const [from, to] = timePeriod.split(TIME_PERIOD_DELIMITER)

  const [fromTime, fromTimeFormatted] = getHoursMinutesAndFormatted(from)
  const [toTime, toTimeFormatted] = getHoursMinutesAndFormatted(to)
  const timePeriodFormatted = fromTimeFormatted + ` ${TIME_PERIOD_DELIMITER} ` + toTimeFormatted

  return {
    from: fromTime,
    to: toTime,
    formatted: timePeriodFormatted
  }
}

function getHoursMinutesAndFormatted (time) {
  const [hours, minutes] = time.split(TIME_DELIMITER)
  const timeFormatted = formatTimeAmPm(hours, minutes)
  return [{ hours: +hours, minutes: +minutes }, timeFormatted]
}

function formatTimeAmPm (hours, minutes) {
  hours = +hours
  minutes = +minutes
  const ampm = (hours < 12 || hours === 24) ? AM : PM
  hours = hours % 12 || 12
  const minutesStr = minutes > 9 ? minutes : '0' + minutes
  return hours + TIME_DELIMITER + minutesStr + ampm
}

function createWeekdayAndTimePeriodPairs (weekdays, timePeriod) {
  return weekdays.map(weekday => ({ weekday, timePeriod }))
}

function orderFromSunday (weekdayPeriods) {
  const startWeekdayIndex = 0 // Sunday
  const orderedWeekdays = []

  weekdayPeriods.forEach(weekday => {
    const weekdayIndex = WEEKDAY_TO_NUMERAL[weekday.weekday]
    let order = weekdayIndex - startWeekdayIndex
    if (order < 0) order += WEEKDAY_ARRAY.length
    orderedWeekdays[order] = weekday
  })

  return filterEmptyDays(orderedWeekdays)
}

function filterEmptyDays (weekdays) {
  return weekdays.filter(x => x)
}

function markCurrentWeekday (weekdays, today) {
  const todayDayName = WEEKDAY_ARRAY[today.getDay()]
  const addIsToday = R.converge(R.assoc('isToday'), [R.propEq('weekday', todayDayName), R.identity])
  return weekdays.map(addIsToday)
}

function getOpenTimeLabel (operationHours, nowDate, T, timezone) {
  if (operationHours === 'Mo-Su 00:00-24:00') return T('ui:Always open')
  if (!operationHours) return ''

  const localizedDate = toLocalDate(nowDate, timezone)

  const weekdayPeriods = parseAndOrderWeekdays(operationHours, localizedDate)
  if (!weekdayPeriods) return ''

  const currentWeekday = findWeekday(weekdayPeriods, localizedDate)

  const todayIsClosed = !currentWeekday || compareDateTimeAndWeekdayTime(localizedDate, currentWeekday.timePeriod.to) === -1

  if (todayIsClosed)
    return prepareTimeToNextWorkingDay(localizedDate, weekdayPeriods, T)
  else if (isDateInWeekdayOpenHours(localizedDate, currentWeekday))
    return prepareTimeToTodayClose(localizedDate, currentWeekday, T)
  else
    return prepareTimeToTodayOpen(localizedDate, currentWeekday, T)
}

function toLocalDate (date, timezone) {
  const { year, month, day, hour, minute } = DateTime.fromJSDate(date, { zone: timezone })
  return new Date(year, month - 1, day, hour, minute)
}

function prepareTimeToNextWorkingDay (nowDate, weekdayPeriods, T) {
  const tomorrowDate = getNextDayDate(nowDate)
  const nextWeekday = findWeekday(weekdayPeriods, tomorrowDate)

  if (nextWeekday) {
    const minutesToTomorrowOpen = minutesBetweenDateTimeAndNextWeekdayTime(nowDate, nextWeekday.timePeriod.from)
    if (minutesToTomorrowOpen >= 60)
      return formatClosedUntilWeekday(nextWeekday, T)
    else
      return formatOpeningInMinutes(minutesToTomorrowOpen, T)
  } else {
    const nextWorkingWeekday = findNextWorkingWeekday(weekdayPeriods, nowDate)
    return formatClosedUntilWeekday(nextWorkingWeekday, T)
  }
}

function prepareTimeToTodayClose (nowDate, currentWeekday, T) {
  const minutesToClose = minutesBetweenDateTimeAndWeekdayTime(nowDate, currentWeekday.timePeriod.to)
  if (minutesToClose >= 60)
    return formatOpenUntilHours(currentWeekday, T)
  else
    return formatClosingInMinutes(minutesToClose, T)
}

function prepareTimeToTodayOpen (nowDate, currentWeekday, T) {
  const minutesToOpen = minutesBetweenDateTimeAndWeekdayTime(nowDate, currentWeekday.timePeriod.from)
  if (minutesToOpen >= 60)
    return formatClosedUntilHours(currentWeekday, T)
  else
    return formatOpeningInMinutes(minutesToOpen, T)
}

function isDateInWeekdayOpenHours (date, weekday) {
  const { from, to } = weekday.timePeriod
  return isInTimePeriod(
    date.getHours(), date.getMinutes(),
    from.hours, from.minutes,
    to.hours, to.minutes
  )
}

function getNextDayDate (date) {
  const nextDate = new Date(date)
  nextDate.setDate(nextDate.getDate() + 1)
  return nextDate
}

function compareDateTimeAndWeekdayTime (date, weekdayHours) {
  const minutesBetween = minutesBetweenDateTimeAndWeekdayTime(date, weekdayHours)
  if (minutesBetween === 0) return 0
  if (minutesBetween > 0) return 1
  return -1
}

/*
 * important
 * use it to calculate minutes between date time and weekday time of the same day only
 */
function minutesBetweenDateTimeAndWeekdayTime (dateTime, weekdayTime) {
  const dateTimeMinutes = dateTime.getHours() * 60 + dateTime.getMinutes()
  const weekdayTimeMinutes = weekdayTime.hours * 60 + weekdayTime.minutes
  return weekdayTimeMinutes - dateTimeMinutes
}

/*
 * important
 * use it to calculate minutes between date time and weekday time of the next day only
 */
function minutesBetweenDateTimeAndNextWeekdayTime (dateTime, weekdayTime) {
  const dateTimeMinutes = dateTime.getHours() * 60 + dateTime.getMinutes()
  const weekdayTimeMinutes = weekdayTime.hours * 60 + weekdayTime.minutes
  const midnightMinutes = 24 * 60
  return weekdayTimeMinutes + (midnightMinutes - dateTimeMinutes)
}

const formatOpeningInMinutes = (minutesToOpen, T) => T('ui:Opening in _minutes_', { count: minutesToOpen })
const formatClosingInMinutes = (minutesToClose, T) => T('ui:Closing in _minutes_', { count: minutesToClose })

const formatClosedUntilHours = (weekday, T) => {
  const { hours, minutes } = weekday.timePeriod.from
  const formattedTime = formatTimeAmPm(hours, minutes)
  return T('ui:Closed until _time_', { time: formattedTime })
}

const formatOpenUntilHours = (weekday, T) => {
  const { hours, minutes } = weekday.timePeriod.to
  const formattedTime = formatTimeAmPm(hours, minutes)
  return T('ui:Open until _time_', { time: formattedTime })
}

const formatClosedUntilWeekday = (weekday, T) => {
  const { hours, minutes } = weekday.timePeriod.from
  const formattedTime = `${weekday.weekday} ${formatTimeAmPm(hours, minutes)}`
  return T('ui:Closed until _time_', { time: formattedTime })
}

const findNextWorkingWeekday = (weekdayPeriods, nowDate) => {
  let startIndex = (nowDate.getDay() + 1) % 7
  while (!findWeekdayByDayIndex(weekdayPeriods, startIndex))
    startIndex = (startIndex + 1) % 7

  return findWeekdayByDayIndex(weekdayPeriods, startIndex)
}
