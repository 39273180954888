import { rgba } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import { IconButton } from '../../../../../src/ui/buttons/IconButton.jsx'
import { ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'
import SearchInputLabelled from '../../../../../src/ui/inputs/SearchInputLabelled.js'
import { metersToYards } from '../../../../../src/utils/distance.js'
import { DISPLAY_UNITS_METERS, FROM_SEARCH_FIELD_ID, TO_SEARCH_FIELD_ID } from '../constants.js'

import RouteOptions from './RouteOptions.jsx'
import { LevelInfo } from './styles.js'

const ANIMATION_DELAY = '0.3s'

const WidgetWrapper = styled.div`
  width: 100%;
  min-height: 118px;
`

const FlexWrapper = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.header.paddingMobile};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.widgetBackground};
  z-index: 1;
  transition: 0.2s ease-in-out;

  & > * {
    min-width: 0;
  }

  &.is-navigating {
    align-items: center;
    padding: 15px 0;
  }

  & > span {
    flex-basis: 40px;
    text-align: center;
    user-select: none;
    cursor: default;
    padding-top: 4px;
  }
`

const NavigationTitle = styled.p`
  margin: 0 20px 0 0;
  flex: 1 1 auto;
  color: ${({ theme }) => theme.colors.widgetText};
  flex-basis: auto;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
  user-select: none;
  cursor: default;
  ${({ theme }) => theme.fontSize('16px')};
`

const SearchInputLabelledStyled = styled(SearchInputLabelled)`

`

const LocationsWrapper = styled.div`
  flex: 1 1 auto;
  ${({ theme }) => theme.borderRadius('2')};
  padding: 0 8px;
  color: #fff;
  user-select: none;
  position: relative;
  cursor: default;
  max-width: calc(100vw - 100px);
  overflow: hidden;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;

  span.secondary {
    color: ${({ theme }) => rgba(theme.colors.widgetText, 0.6)};
    padding-right: 5px;
  }

  ${SearchInputLabelledStyled} {
    transition: ${ANIMATION_DELAY}  ease-in-out;

    input {
      ${({ theme }) => theme.fontSize('16px')};
      transition: ${ANIMATION_DELAY} ease-in-out;
    }

    .closeButton {
      transition: ${ANIMATION_DELAY} ease-in-out;
      opacity: 1;
      height: 32px;
      display: flex;

      svg {
        width: 32px;
        height: 32px;
        transition: ${ANIMATION_DELAY} ease-in-out;
      }
    }
  }


  &.not-editing {
    & > * {
      pointer-events: none;
    }

    ${SearchInputLabelledStyled} {
      margin-bottom: 0;
      padding: 0 8px 4px;
      min-height: 0;
      height: auto;
      color: ${({ theme }) => theme.colors.widgetText};
      transition: ${ANIMATION_DELAY} ease-in-out;
      border-radius: 0 0 2px 2px;

      &:first-child {
        ${({ theme }) => theme.fontSize('12px')};
        padding: 6px 8px 2px;
        border-radius: 2px 2px 0 0;

        input {
          ${({ theme }) => theme.fontSize('12px')};
        }

      }

      span {
        color: ${({ theme }) => theme.colors.widgetText};
      }

      .closeButton {
        transition: ${ANIMATION_DELAY} ease-in-out;
        opacity: 0;
        height: 12px;

        svg {
          width: 12px;
          height: 12px;
          transition: ${ANIMATION_DELAY} ease-in-out;
        }
      }
    }
  }
`

const RouteTypeSwitcherWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: ${({ theme }) => theme.header.paddingMobile};
`

class DirectionsResultControlsViewMobile extends React.PureComponent {
  state = {
    isBeingEdited: false
  }

  NavigationView = () => {
    const {
      currentLevelName,
      distances,
      endpoints,
      onBackFromNavigationClicked,
      preferredUnits,
      showLevelName,
      theme,
      times,
      T
    } = this.props

    const minutesText = times[0]
      ? T('getDirectionsFromTo:_minutes_ min', { minutes: times[0] })
      : T('getDirectionsFromTo:<1 min')

    const distanceDisplay = preferredUnits === DISPLAY_UNITS_METERS
      ? T('getDirectionsFromTo:_distance_m', { distance: distances[0] | 0 })
      : T('getDirectionsFromTo:_distance_yards', { distance: metersToYards(distances[0] | 0, true) })

    return <FlexWrapper className={'is-navigating'}>
      <IconButton onClick={() => onBackFromNavigationClicked()} title="Navigate Back" data-cy='NavigateBackButton'>
        <ColoredIcon className="icon" width={32} height={32} id="arrow-left" fillColor={theme.colors.widgetIconFill} />
      </IconButton>

      <NavigationTitle data-cy='NavigationTitle'>
        {T('getDirectionsFromTo:Route to _location_', { location: endpoints[1].title })} / {minutesText} ({distanceDisplay})
      </NavigationTitle>

      {showLevelName &&
        <LevelInfo>
          <span>{currentLevelName}</span>
        </LevelInfo>
      }
    </FlexWrapper>
  }

  ControlsView = () => {
    const {
      endpoints,
      isBackButtonDisabled,
      onBackButtonClicked,
      onInputChange,
      onInputCloseButtonClick,
      onInputSelect,
      onSwitchDirectionsButtonClicked,
      onSwitchRouteTypeButtonClicked,
      routeAccessibiltyChoice,
      routeTypes,
      theme,
      T
    } = this.props
    return <WidgetWrapper data-cy={'DirectionsResultControlsViewMobile'}>
      <FlexWrapper onClick={this.handleClick} id="resultControlsWrapper">
        {!isBackButtonDisabled
          ? <IconButton onClick={() => onBackButtonClicked()} title="Navigate Back" data-cy='NavigateBack'>
            <ColoredIcon className="icon" width={32} height={32} id="arrow-left" fillColor={theme.colors.widgetIconFill} />
          </IconButton>
          : null}

        {<LocationsWrapper className={this.state.isBeingEdited ? 'editing' : 'not-editing'} id="switchForms" onClick={this.handleClick} data-cy='LocationsWrapper'>
          <SearchInputLabelledStyled
            id={FROM_SEARCH_FIELD_ID}
            value={endpoints[0].title}
            label={T('getDirectionsFromTo:From') + ':'}
            onInputChange={onInputChange}
            onInputCloseButtonClick={onInputCloseButtonClick}
            autoFocus={false}
            onInputSelect={onInputSelect}
            onClick={() => onInputSelect(FROM_SEARCH_FIELD_ID)}
            T={T}
            hasCloseButton={this.state.isBeingEdited}
            data-cy={'SearchInputFrom'}
          />
          <SearchInputLabelledStyled
            id={TO_SEARCH_FIELD_ID}
            value={endpoints[1].title}
            label={T('getDirectionsFromTo:To') + ':'}
            onInputChange={onInputChange}
            onInputCloseButtonClick={onInputCloseButtonClick}
            autoFocus={false}
            onInputSelect={onInputSelect}
            onClick={() => onInputSelect(TO_SEARCH_FIELD_ID)}
            T={T}
            hasCloseButton={this.state.isBeingEdited}
            data-cy={'SearchInputTo'}
          />
        </LocationsWrapper>}

        <IconButton onClick={() => {
          onSwitchDirectionsButtonClicked()
          this.setState({ isBeingEdited: false })
        }}
        data-cy='DirectionSwapButtonMobile'
        >
          <ColoredIcon className="icon" width={32} height={32} id="swap" fillColor={theme.colors.widgetIconFill} />
        </IconButton>
      </FlexWrapper>

      <RouteTypeSwitcherWrapper>
        <RouteOptions routeAccessibiltyChoice={routeAccessibiltyChoice} routeTypes={routeTypes} onSwitchRouteTypeButtonClicked={onSwitchRouteTypeButtonClicked} T={T} />
      </RouteTypeSwitcherWrapper>

    </WidgetWrapper>
  }

  handleClick = (e) => {
    if (e.target.id === 'switchForms') {
      e.preventDefault()
      e.stopPropagation()
      this.setState({ isBeingEdited: true })
    } else if (e.target.id === 'resultControlsWrapper') {
      e.preventDefault()
      e.stopPropagation()
      this.setState({ isBeingEdited: false })
    }
  }

  getFormView = () => {
    const { isNavigating } = this.props
    if (isNavigating) return this.NavigationView()
    else return this.ControlsView()
  }

  render () {
    return (
      this.getFormView()
    )
  }
}

export default withTheme(DirectionsResultControlsViewMobile)

DirectionsResultControlsViewMobile.propTypes = {
  currentLevelName: PropTypes.string,
  distances: PropTypes.array,
  endpoints: PropTypes.array,
  onBackFromNavigationClicked: PropTypes.bool,
  preferredUnits: PropTypes.string,
  isNavigating: PropTypes.bool,
  routeAccessibiltyChoice: PropTypes.string,
  routeTypes: PropTypes.string,
  showLevelName: PropTypes.bool,
  times: PropTypes.array
}
