import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'

import { Icon } from '../../../../src/ui/icons/Icon.jsx'
import { getBearing } from '../../../../src/utils/geodesy.js'
import { isPointWithinBounds, getStructureAtPoint, getFloorForBuildingAndCLFloor } from '../../../../src/utils/geom.js'

const LOST_LOCATION_TIME = 1000 * 60 * 1 // no report in 1 minute = lost it...
const HIDE_MARKER_TIME = 1000 * 60 * 5 // hide marker after 5 minutes no report

const bluedotEnabled = <Icon id="bluedot.enabled" height={30} />
const bluedotDisabled = <Icon id="bluedot.disabled" height={30} />

const AccuracyField = styled.div`
  border-radius: 100%;
  background: ${({ theme }) => rgba(theme.colors.primaryButton, 0.15)};
  width: ${({ radius }) => radius * 2}px;
  height: ${({ radius }) => radius * 2}px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transform: rotate(${({ bearing }) => 180 + bearing}deg);
`

export default function createMarker (app, log, buildings, venueBounds, widgetState) {
  let lastReading = null
  let lastBearing = null
  widgetState.addCallback(state => app.bus.send('bluedot/locationUpdate', { ...state, ...lastReading }))

  function timeoutCheck () {
    const timeSinceLastReading = lastReading ? Date.now() - lastReading.time : 0
    if (widgetState.getState().hasLocation && timeSinceLastReading > LOST_LOCATION_TIME) {
      const T = app.gt()
      followMeCaption(T('bluedot:My Location Lost'))
      log.warn('bluedot:My Location Lost.  timesincelast: ' + timeSinceLastReading)
      widgetState.update({ isFollowing: false, hasLocation: false })
      refresh()
    }
    if (timeSinceLastReading > HIDE_MARKER_TIME && !widgetState.getState().connectionTimedOut)
      widgetState.update({ connectionTimedOut: true, isMonitoring: false })

    setTimeout(timeoutCheck, 5000)
  }

  setTimeout(timeoutCheck, 5000)

  function refresh () {
    if (lastReading) {
      const { time, latitude, longitude, accuracy, clFloor } = lastReading
      const userPositionSource = widgetState.getState().userPositionSource
      updatePos(time, latitude, longitude, accuracy, clFloor, userPositionSource, true)
    }
  }

  // Displays a caption above the follow-me button, and then auto clears after 10 seconds (or specified ms)
  function followMeCaption (msg, ms = 10000) {
    widgetState.update({ followMeCaption: msg })
    setTimeout(() => widgetState.update({ followMeCaption: '' }), ms)
  }

  const getLastReading = () => lastReading

  function updatePos (time, latitude, longitude, accuracy, clFloor, userPositionSource, refreshFlag) {
    log.info(`Got ${refreshFlag ? 'refresh' : 'position'} - ${time}: ${latitude}/${longitude} - floor: ${clFloor} (accuracy: ${accuracy}) - state: `, widgetState.getState())
    const T = app.gt()

    // if (time instanceof window.GeolocationPositionError) // user declined permissions (or some other issue)
    //   return widgetState.update({ isFollowing: false, isMonitoring: false, hasLocation: false })
    if (!latitude) // GeolocationPositionError is not standard - was crashing in iPhone - this should suffice
      return widgetState.update({ isFollowing: false, isMonitoring: false, hasLocation: false })
    else
      widgetState.update({ latitude, longitude, accuracy })

    const inVenueBounds = isPointWithinBounds(latitude, longitude, venueBounds.n, venueBounds.s, venueBounds.e, venueBounds.w)
    let floorId = null
    let ordinal = null
    let structureId = null

    const bearing = lastReading
      ? latitude === lastReading.latitude && longitude === lastReading.longitude
        ? lastBearing // if lat,lng didn't change, use last bearing
        : Math.floor(getBearing(lastReading.latitude, lastReading.longitude, latitude, longitude))
      : 0
    lastBearing = bearing

    if (inVenueBounds) {
      let state = widgetState.getState()

      if (userPositionSource && state.userPositionSource !== userPositionSource)
        widgetState.update({ userPositionSource })

      if (!state.hasLocation && !refreshFlag) // if this is first reported location in venue bounds, auto-follow (update: no longer autofollow)
        state = widgetState.update({ isMonitoring: true, hasLocation: true })

      if ((clFloor === undefined || clFloor === null) && (!lastReading || lastReading.clFloor !== undefined))
        followMeCaption(T('bluedot:Using GPS'))

      const accuracyRadius = accuracy < 30
        ? 20
        : 50
      const content = <AccuracyField bearing={bearing} radius={accuracyRadius}>{state.hasLocation ? bluedotEnabled : bluedotDisabled}</AccuracyField>
      const building = getStructureAtPoint(buildings, latitude, longitude) // get building at this point
      structureId = !building ? null : building.id
      const floor = !building || clFloor === null || clFloor === undefined
        ? null
        : getFloorForBuildingAndCLFloor(buildings, building, clFloor)
      if (floor) {
        ordinal = floor.ordinal
        floorId = floor.id
        app.bus.send('map/addMarker', { id: 'bluedot', ordinal, lat: latitude, lng: longitude, markerComponent: content, markerOptions: { anchor: 'center', rotationAlignment: 'map' } })
        app.bus.send('user/physicalLocation', { latitude, longitude, structureId, floorId, ordinal })
        widgetState.update({ floorId, ordinal }) // we have a floor now..
      } else {
        // we are either outside, or in a structure Apple didn't map, or for some other reason Core Location can't figure out our floor.
        widgetState.update({ floorId: null, ordinal: null })
        app.bus.send('map/addMarker', { id: 'bluedot', ordinal, lat: latitude, lng: longitude, markerComponent: content, markerOptions: { anchor: 'center', rotationAlignment: 'map' } }) // create on same floor user is on.. set this state so we can turn dot gray...
        app.bus.send('user/physicalLocation', { latitude, longitude })
      }

      if (state.isFollowing)
        app.bus.send('map/animateToPoint', { lat: latitude, lng: longitude, zoom: 18, floorId: floor ? floor.id : undefined })
    } else {
      if (widgetState.getState().hasLocation) { // if we DID have location, we don't now.. hide marker and notify user
        widgetState.update({ isFollowing: false, hasLocation: false })
        app.bus.send('map/removeMarker', { id: 'bluedot' })
        followMeCaption(T('bluedot:My Location Lost'))
      }
    }

    lastReading = { time, latitude, longitude, accuracy, clFloor, floorId, ordinal, structureId }
  }

  return {
    getLastReading,
    refresh,
    updatePos
  }
}
