import * as R from 'ramda'
import { useEffect } from 'react'

const useOnUpdateSearchInputs = (previousSearchInputs, nextSearchInputs, ...callbacks) => {
  useEffect(() => {
    // strip id from previous and next, because this does not warrant an update
    const stripId = R.map(R.dissoc('id'))
    const previous = stripId(previousSearchInputs)
    const next = stripId(nextSearchInputs)
    if (!R.equals(previous, next)) {
      callbacks.forEach(cb => cb(next))
    }
  }, [nextSearchInputs])
}

export default useOnUpdateSearchInputs
