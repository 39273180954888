import PropTypes from 'prop-types'
import React, { useRef, useEffect } from 'react'

let hideTimerClearRef = null // holds the setTimeout return value used to clear timeouts

const hideTooltip = () => {
  document.getElementById('tooltip').setAttribute('style', 'display: none;')
  cancelHide() // clear any timers and dereference the timer clear ref
}

const handleKeyboardSelect = (callback) => (event) => {
  if (event.key === 'Enter' || event.key === ' ') {
    hideTooltip()
    callback(event)
  }
}

// Allow user to clear the tooltip instantly with Escape key
document.addEventListener('keydown', event => {
  if (event.key === 'Escape') {
    hideTooltip() // Insta-GONE!
  }
})

// Note: tooltip may not yet exist in the dom at first, but we're patient...
const tryAddingEventToTooltip = () => {
  const tooltip = document.getElementById('tooltip')
  if (tooltip) {
    // If user mouses over the tooltip, cancel the hide
    tooltip.addEventListener('mouseenter', () => {
      cancelHide()
    })

    // if user mouseleaves the tooltip, hide immediately
    tooltip.addEventListener('mouseleave', hideTooltip)
  } else
    setTimeout(tryAddingEventToTooltip, 100) // if we didn't find it, keep trying...
}

tryAddingEventToTooltip()

const cancelHide = () => {
  if (hideTimerClearRef)
    clearTimeout(hideTimerClearRef)
  hideTimerClearRef = null
}

const ToolTipComponent = ({ className, tooltipText, arrowPosition, children, isVisible = true }) => {
  const componentRef = useRef(null)

  useEffect(() => {
    if (!isVisible) hideTooltip() // be warned, this will hide ANY tooltip, not just "our's"...
  }, [isVisible])

  const calculateTooltipPosition = (position, arrowPosition) => {
    let topOffset = position.top // set default position of tooltip to the top of element
    let leftOffset = position.left // set default position of tooltip to the left of element

    if (arrowPosition === 'top' || arrowPosition === 'topright') {
      topOffset = position.bottom
    }
    if (arrowPosition === 'left' || arrowPosition === 'right') {
      topOffset += position.height / 2
    }
    if (arrowPosition === 'left' || arrowPosition === 'topright') {
      leftOffset = position.right
    }
    if (arrowPosition === 'bottom' || arrowPosition === 'top' || arrowPosition === 'topright') {
      leftOffset += position.width / 2
    }

    return { topOffset, leftOffset }
  }

  /* Return flipped position if element overflows the screen  */
  const checkArrowPosition = (arrowPosition, position, tooltip) => {
    const tooltipHeight = tooltip.getBoundingClientRect().height
    const tooltipWidth = tooltip.getBoundingClientRect().width

    if (arrowPosition === 'right' && Math.ceil(position.left - tooltipWidth) < 0) // if tooltip overflows left side of the screen
      return 'left'
    if (arrowPosition === 'left' && Math.ceil(position.right + tooltipWidth) > window.innerWidth) // if tooltip overflows right side of the screen
      return 'right'
    if (arrowPosition === 'bottom' && Math.ceil(position.top - tooltipHeight) < 0) // if tooltip overflows top of the screen
      return 'top'
    if (arrowPosition === 'top' && Math.ceil(position.bottom + tooltipHeight) > window.innerHeight) // if tooltip overflows bottom of the screen
      return 'bottom'

    return arrowPosition
  }

  const onMouseEnter = () => {
    if (!isVisible) return
    cancelHide() // if a previous tip hasn't timed out yet, cancel it so it doesn't hide THIS tip
    const position = componentRef.current.getBoundingClientRect()
    const tooltip = document.getElementById('tooltip')
    tooltip.innerText = tooltipText
    tooltip.setAttribute('style', `display: block; opacity: 0;`) // ensure element displays so its height can be detected

    const arrowPos = checkArrowPosition(arrowPosition, position, tooltip) // get correct position for arrow
    const { topOffset, leftOffset } = calculateTooltipPosition(position, arrowPos)

    tooltip.setAttribute('arrowPosition', arrowPos)
    tooltip.setAttribute('style', `display: block; opacity: 1; top: ${topOffset}px; left: ${leftOffset}px;`)
  }

  const onMouseLeave = () => {
    hideTimerClearRef = setTimeout(hideTooltip, 200) // give user time to hover over tooltip
  }

  const tooltipLayerExists = Boolean(document.getElementById('tooltip'))
  // Added a prop boolean here to be able to test ToolTip component. As it relies on document.getElementById
  // to find the tooltiplayer this is never true in the cypress test as getElementById fires before
  // the cypress component DOM is loaded. Or something like that.
  return (
    tooltipLayerExists || window.Cypress
      ? <div data-cy='tooltipLayer' className={className} ref={componentRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onFocus={onMouseEnter} onBlur={onMouseLeave} onClick={hideTooltip} onKeyDown={handleKeyboardSelect(hideTooltip)}>
        {children}
      </div>
      : children
  )
}

ToolTipComponent.propTypes = {
  tooltipText: PropTypes.string,
  arrowPosition: PropTypes.oneOf(['bottom', 'left', 'right', 'top', 'topright']),
  isVisible: PropTypes.bool
}

export default ToolTipComponent
