import * as R from 'ramda'

export default function BadgeController (app, mapInitialized) {
  app.bus.on('map/addCustomBadges', async ({ ids, badge }) => {
    const idsSet = new Set(ids)
    const functor = feature => {
      if (idsSet.has(feature.properties.id))
        feature.properties.customBadge = badge
      return feature
    }
    app.bus.send('map/mutateFeature', { functor })
  })

  app.bus.on('map/hideMapBadges', ({ ids }) => {
    if (ids.length === 0)
      return
    mapInitialized().then(map => {
      const allLayers = map.getStyle().layers
      const poiLayerIds = R.pluck('id', allLayers).filter(s => s.includes('poi') && !s.includes('selected') && !s.includes('notSelected'))
      const uniqIds = R.uniq(ids)
      poiLayerIds.forEach(layerId => {
        map.setPaintProperty(layerId, 'icon-opacity', ['case', ['match', ['get', 'id'], uniqIds, true, false], 0, 1])
      })
    })
  })
}
