import styled from 'styled-components'

const TextButton = styled.button`
  text-decoration: underline;
  background: none;
  border: none;
  color: ${({ fontColor, theme }) => fontColor || theme.colors.primary};
  ${({ theme }) => theme.fontSize('16px')};
  font-family: ${({ theme }) => theme.fonts.family};
  font-weight: 500;
  padding-left: 4px;
`

export default TextButton
