import * as R from 'ramda'
import Zousan from 'zousan'

/**
 * Limit the calls to specified function to 1 per timeslice.
 * i.e.  If x = 100ms, n = event, and this is called with 500ms
 *  xx1x2x3xx4xxx  - calls made
 *  xx1xxxx3xxxx4  - calls occured
 * Note: That last 4 call will not happen if you indicate noTailCall = true
 * @param  {function} fn The function to throttle
 * @param  {integer} ms The size of the timeslice (in milliseconds) (-1 indicates no throttling)
 * @param  {boolean} noTailCall If true, final call will be suppressed
 */
function throttle (fn, ms = 150, noTailCall = false) {
  if (ms === -1) { // no throttling
    return function () {
      fn.apply(null, arguments)
    }
  }

  let lastCall = Date.now()
  let lastArguments = null
  let lastcallHandle = null

  return function () {
    const now = Date.now()
    if ((now - lastCall) > ms) { // no call within timeframe, so call it ASAP (but keep it asynchronous! )
      lastCall = now
      fn.apply(null, arguments)
    } else { // we are being throttled out!
      lastArguments = arguments
      if (!lastcallHandle && !noTailCall) {
        lastcallHandle = setTimeout(function () {
          fn.apply(null, lastArguments)
          lastcallHandle = null
          lastCall = new Date().getTime()
        }, ms - (now - lastCall))
      }
    }
  }
}

// Ensures only a single asynchronous function wrapped in this function runs at a time.
let lastFn = null
const singleFile = fn => function () {
  if (lastFn)
    lastFn = lastFn.then(() => fn.apply(null, arguments))
  else
    lastFn = fn.apply(null, arguments)

  return lastFn
}

// call a function after a delay with an optional value specified. Can be used
// as HOF or directly as a promise. Cool eh? This allows you to use it in a
// function pipeline, or directly in an await delay, etc.
//
// Usage:
//
//  await delay(500) // waits for 500ms - returns undefined
//  await delay(500)(123) // waits for 500ms - returns 123
//
//  f1().then(delay(800)).then(f2) // delay 800ms, pass result on to f2
//  R.pipe(f1, delay(800), f2) // same as above
const delay = ms => {
  const r = v => new Zousan(y => setTimeout(y, ms, v))
  const z2 = new Zousan(y => setTimeout(y, ms))
  r.then = z2.then.bind(z2)
  return r
}

// Holds a value to be tested against a new value. returns true if value changed
// Usage:
//  const test = changeTest(1) // returns testing function
//  test(1) // returns false (no change)
//  test(0) // returns true (value changed)
//  test(0) // returns false (value same)
const changeTest = val => {
  let lastVal = val
  return val => {
    const change = val !== lastVal
    lastVal = val
    return change
  }
}

const changeTestForArray = (initialArray = []) => {
  let lastArray = [...initialArray]
  return (currentArray) => {
    const change = !R.equals(currentArray, lastArray)
    lastArray = [...currentArray]
    return change
  }
}

// simple helper function to watch objects and report if they change.
// this only does a shallow compare on each key/value pair.
function changeTestObject (initialObject = {}) {
  let lastEntry = initialObject

  return valueOb => {
    let changed = false
    for (const key in valueOb)
      changed = changed || !shallowEqual(valueOb[key], lastEntry[key])
    lastEntry = { ...valueOb }
    return changed
  }
}

// returns a function that proxies the passed in function but only executes it once
// and holds the value to return in subsequent calls. Note: This is different from
// memoization in the sense that the passed arguments are ignored - the proxied
// function is only ever called a single time.
const calcOnce = fn => {
  const NO_VALUE = {}
  let value = NO_VALUE
  return arg => value === NO_VALUE ? (value = fn(arg)) : value
}

// alias runOnce to calcOnce - they do the exact same thing, but runOnce is more clear
// for use cases it applies to.
const runOnce = calcOnce

// provides a promise-based value holder than can be replaced if the value updates.
// Initial and Replace value can specified explicitly (like in example below) or with
// a promise itself. Also, can initialize without a value to await a replacement.
// Usage:
//  const foo = replaceablePromise(123)
//  foo.valueProm().then(console.log) // 123
//  foo.valueProm().then(console.log) // 123
//  foo.replace(321)
//  foo.valueProm().then(console.log) // 321
const replaceablePromise = sourceVal => {
  let valueProm = sourceVal !== undefined ? Zousan.resolve(sourceVal) : new Zousan()
  function replace (newVal) {
    if (valueProm.v)
      valueProm = Zousan.resolve(newVal)
    else
      valueProm.resolve(newVal)
    return this
  }

  return {
    replace,
    valueProm: () => valueProm
  }
}

/**
 * returns a copy of an object with any (top-level) properties that do not
 * return truthy from the specified function stripped. So it works just like
 * the Array.filter, but for objects.
 * @param  {function} fn A filter predicate - will be passed (key,object)
 * @param  {object} ob The object to filter (top level properties only)
 * @returns (object) The newly created object with filtered properties
 */
function filterOb (fn, ob) {
  const ret = { }
  Object.keys(ob)
    .forEach(key => {
      if (fn(key, ob[key]))
        ret[key] = ob[key]
    })
  return ret
}

function shallowEqual (a, b) {
  // if they are the same object, we are done here..
  if (a === b)
    return true

  // for native types and functions (non-objects), we use ===
  if ((typeof a !== 'object') || (typeof b !== 'object'))
    return false

  // if the object contains an isEqual, use that
  if (a.isEqual)
    return a.isEqual(b)

  // if one is null, thats easy. (if both were null we would have already returned true by now)
  if (a === null || b === null)
    return false

  // ok, so they are both a non-null object/Array type - so check props on each of a
  for (const ak in a)
    if (!(ak in b) || (a[ak] !== b[ak]))
      return false

  // then ensure there are no "extra" types in b
  for (const bk in b)
    if (!(bk in a))
      return false

  // we passed all the object tests, so we are equal!
  return true
}

// This function copied from https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
// It supports the full UTF-8 character set UNLIKE simply using btoa / atob
const b64EncodeUnicode = str =>
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    function toSolidBytes (match, p1) {
      return String.fromCharCode('0x' + p1)
    }))

const b64DecodeUnicode = str =>
  // Going backwards: from bytestream, to percent-encoding, to original string.
  decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

export {
  b64DecodeUnicode,
  b64EncodeUnicode,
  calcOnce,
  changeTest,
  changeTestForArray,
  changeTestObject,
  delay,
  filterOb,
  replaceablePromise,
  runOnce,
  shallowEqual,
  singleFile,
  throttle
}
