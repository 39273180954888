import * as PropTypes from 'prop-types'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import PillLabel from './PillLabel.jsx'

const StyledPillLabel = styled(PillLabel)`
  padding: 2px 4px;
  text-transform: none;
`

const SecuritySmallLabel = withTheme(
  ({ dynamicData, T, className, theme, statusText }) => {
    if (!dynamicData) return null

    const { isTemporarilyClosed } = dynamicData

    const backgroundColor = isTemporarilyClosed
      ? theme.colors.statusClosedBackground
      : theme.colors.statusCancelledBackground

    const color = isTemporarilyClosed
      ? theme.colors.statusClosedText
      : theme.colors.statusCancelledText

    return (
      <StyledPillLabel
        backgroundColor={backgroundColor}
        color={color}
        className={className}
      >
        {statusText}
      </StyledPillLabel>
    )
  }
)

SecuritySmallLabel.propTypes = {
  dynamicData: PropTypes.shape({}),
  T: PropTypes.func.isRequired,
  statusText: PropTypes.string
}

export { SecuritySmallLabel }
