import React from 'react'
import styled from 'styled-components'

import { Button } from './Button.jsx'

const Wrapper = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;
  border: none;
  border-radius: unset;
`

const IconButton = ({ children, onClick, ...props }) => {
  return <Wrapper onClick={onClick} {...props} >
    {children}
  </Wrapper>
}

export { IconButton }
