import * as R from 'ramda'

import { SecurityLaneType } from './wayfinder.js'

const getEdgeTo = dst => node => R.find(e => e.dst === dst, node.edges)

/**
 * Returns the shortest route (or path) from start to end.
 * @param  {Object} start must include {floorId, lat, lng}
 * @param  {Object} end must include {floorId, lat, lng}
 * @param  {Object} options optional settings for choosing route
 * @param  {boolean} options.requiresAccessibility if true, only accessible routes are returned (no stairs or escalators)
 * @returns {[nodes]} array of NavGraph nodes OR null if no route exists
 * @throws if start or end is not defined
 */
const calculateRoute = (graph, start, end, options) => {
  if (!start || !end)
    throw Error('bad calculate Route request!')
  return graph.findShortestPath(start, end, options)
}

/**
 * @typedef Waypoint
 * @property {FullPosition} position
 * @property {number} distance
 * @property {number} eta
 * @property {boolean} isSecurityCheckpoint
 * @property {boolean} isPortal
 * @property {string} portalType
 * @property {number} levelDifference
 * @property {boolean} isDestination
 * @property {SecurityWaitTime|null} securityWaitTimes
 * @property {SecurityLane|null} securityLane
 * @property {CurvedPath|null} curvedPathForward
 *
 * @typedef SecurityLane
 * @property {string} id
 * @property {string} type
 *
 * @typedef FullPosition
 * @property {string} floorId
 * @property {string} lat
 * @property {number} lng
 * @property {number} ordinal
 * @property {string} structureId
 *
 * @typedef {Array.<CurvedPoint>} CurvedPath
 *
 * @typedef CurvedPoint
 * @property {ObjCoordinate} end
 * @property {ObjCoordinate} in
 * @property {ObjCoordinate} out
 * @property {ObjCoordinate} start
 *
 * @typedef {{lng: number, lat: number}} ObjCoordinate
 *
 * Returns the shortest route (or path) from start to end.
 * @param graph
 * @param {Endpoint} start
 * @param {Endpoint} destination
 * @param {RouteOptions} options
 * @return {{waypoints: Array.<Waypoint>}|null}
 */
// todo refactor
export const findRoute = (graph, start, destination, options = {}) => {
  const waypoints = []
  const queues = []

  let hasSecurity = false
  let hasImmigration = false

  const routeNodes = calculateRoute(graph, start, destination, options)

  if (routeNodes === null)
    return null

  let previousRouteNode = null
  for (let i = 0; i < routeNodes.length; i++) {
    const waypoint = {
      distance: 0,
      eta: 0
    }

    if (previousRouteNode) {
      const edgeUsed = getEdgeTo(routeNodes[i].id)(previousRouteNode)
      if (edgeUsed != null) {
        waypoint.distance = edgeUsed.distance
        waypoint.eta = edgeUsed.transitTime

        if (edgeUsed.type !== 'Ground') {
          waypoint.portalType = edgeUsed.type
          waypoint.isPortal = true
        }

        if (edgeUsed.o)
          waypoint.poiId = edgeUsed.o

        if (edgeUsed.path)
          waypoint.curvedPathForward = edgeUsed.path
        if (edgeUsed.securityWaitTimes) {
          waypoint.securityWaitTimes = edgeUsed.securityWaitTimes
          waypoint.eta = waypoint.securityWaitTimes.queueTime
        }

        if (edgeUsed.securityLane) {
          waypoint.securityLane = edgeUsed.securityLane
          waypoint.isSecurityCheckpoint = true
          if (edgeUsed.securityLane.type === SecurityLaneType.SECURITY)
            hasSecurity = true
          if (edgeUsed.securityLane.type === SecurityLaneType.IMMIGRATION)
            hasImmigration = true
          if (edgeUsed.o)
            queues.push(edgeUsed.o)
        }
      }
    }

    waypoint.levelDifference = previousRouteNode ? routeNodes[i].ordinal - previousRouteNode.ordinal : 0
    waypoint.position = R.omit(['edges', 'id'], { ...routeNodes[i] })

    waypoints.push(waypoint)

    previousRouteNode = routeNodes[i]
  }
  R.last(waypoints).isDestination = true

  return {
    waypoints,
    queues,
    hasSecurity,
    hasImmigration
  }
}
