import { prop } from 'ramda'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import HandlerContext from '../handlerContext.js'

const Wrapper = styled.div`
  display: flex;
  margin: 16px 0 0;
`

const TagsWrapper = styled.div`
  display: block;
`

const Tag = styled.button`&&&{
  ${({ theme }) => theme.fontSize('14px')};
  background: ${({ theme }) => theme.colors.background};
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0 8px;
  color: ${({ theme }) => theme.colors.primaryButton};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.primaryButton};
  ${({ theme }) => theme.borderRadius('2')};
  cursor: pointer;
  line-height: 28px;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryButton};
    color: ${({ theme }) => theme.colors.primaryButtonText};
  }
}
`

const PoiTags = withTheme(({ theme, poi, T }) => {
  if (!poi || !poi.keywords) return null
  const visibleKeywords = poi.keywords.filter(prop('isDisplayed'))
  return !!visibleKeywords.length && <HandlerContext.Consumer>
    {ctx => <Wrapper>
      <TagsWrapper data-cy='PoiTagsWrapper'>
        {visibleKeywords.map(keyword => <Tag aria-label={`${T('poiView:Search posts tagged with')} ${keyword.name}"`} role='button' key={keyword.name} onClick={(e) => {
          e.stopPropagation()
          ctx.onTagClicked(keyword.name)
        }}>{keyword.name}</Tag>)}
      </TagsWrapper>
    </Wrapper>}
  </HandlerContext.Consumer>
})

export default PoiTags
