import * as R from 'ramda'
import React from 'react'
import { debounce } from 'throttle-debounce'
import Zousan from 'zousan'

import { Icon } from '../../../../src/ui/icons/Icon.jsx'
import { throttle, runOnce, changeTest, changeTestForArray } from '../../../../src/utils/funcs.js'
import { distance } from '../../../../src/utils/geodesy.js'
import { getLocation } from '../../../../src/utils/location.js'
import { adjustBoundsToPois } from '../../../../src/utils/poi.js'
import { StateObjMultiple } from '../../../../src/utils/stateObj.js'

import {
  ANIMATION_DELAY,
  ANIMATION_TIME,
  DEBOUNCE_TIME,
  DIRECTIONS_RESULT_CONTROLS_ID,
  DIRECTIONS_RESULT_VIEW_ID_DESKTOP,
  DIRECTIONS_RESULT_VIEW_ID_MOBILE,
  DIRECTIONS_SEARCH_CONTROLS_ID,
  DIRECTIONS_SEARCH_VIEW_ID,
  DISPLAY_UNITS_METERS,
  DISPLAY_UNITS_YARDS,
  FROM_SEARCH_FIELD_ID,
  MAX_SEARCH_INPUT_LENGTH,
  MAX_UI_RESULTS_LENGTH,
  SHOW_DIRECTIONS_FROM_TO_CALLBACK_ID,
  TO_SEARCH_FIELD_ID
} from './constants.js'
import DirectionsResultControls from './DirectionsResultControls/DirectionsResultControls.js'
import DirectionsSearchControls from './DirectionsSearchControls/DirectionsSearchControls.js'
import DirectionsSearchView from './DirectionsSearchView/DirectionsSearchView.js'
import { getNextEmptySearchFieldIndex } from './inputs.js'
import { getPoisByTerm, getPoisWithTransitTimes, allEndPointsDefined, endPointDefined, definedEndpointCount } from './poiListingUtils.js'
import showDirectionsFromToCreate from './showDirectionsFromTo.js'
import { GhostMarker } from './styles.js'
import { getDefaultState, setDefaultRouteAccessibilityChoice } from './widgetState.js'

async function sendHandoffEvent (app, navFrom, navTo, accessible) {
  // example URL parm: navFrom=36.08160509870544,-115.13769845400965,las-concoursed-departures
  const [lat, lng, floorId] = navFrom.split(',')
  const venueId = await app.bus.get('venueData/getVenueId')

  /*
      action: qrCodeHandoff
      position:{"lat":36.08160509870544,"lng":-115.13769845400965,"venueId":"las","floorId":"las-concoursed-departures-2"}
      query: navTo=152&accessible=false&ho
    */

  app.bus.send('session/submitEvent', {
    type: 'userAction',
    action: 'qrCodeHandoff',
    query: `navTo=${navTo}&accessible=${accessible}`,
    position: { lat: parseFloat(lat), lng: parseFloat(lng), floorId, venueId }
  })
}

export const maybeShowDirectionsFromToAndCenter = (app, state) => {
  const callIfTargetsChange = callShowDirectionsIfSearchInputEntitiesChanged(state, app)

  return (_, opts) => callIfTargetsChange(opts)
}

export const getEntitiesReferencedBySearchInputs = ({ searchInputs = [] }) => R.map(searchInput => {
  if (endPointDefined(searchInput)) {
    if (searchInput.location) { // TODO better way to test for my location vs. general location
      return 'myLocation'
    } else {
      return `${poiOrLocation(searchInput)}` // TODO Just need to call poi here
    }
  } else return null
}, searchInputs)

export const callShowDirectionsIfSearchInputEntitiesChanged = (state, app) => {
  const getCurrentEntities = () => getEntitiesReferencedBySearchInputs(state.getState())
  let currentEntities = getCurrentEntities()

  return (opts = {}) => {
    const changed = !R.equals(currentEntities, getCurrentEntities())

    if (changed) {
      currentEntities = getCurrentEntities()

      if (!opts.noTrigger?.includes(SHOW_DIRECTIONS_FROM_TO_CALLBACK_ID))
        callShowDirectionsIfEndpointsDefined(state, app)
    }
  }
}

// takes search input
export const poiOrLocation = R.either(
  R.path(['chosenPoi', 'poiId']),
  R.prop('location')
)
const allSearchEndpoints = (searchInputs) => R.map(poiOrLocation, searchInputs)

export const callShowDirectionsIfEndpointsDefined = (state, app) => {
  const { searchInputs } = state.getState()

  if (allEndPointsDefined(searchInputs))
    app.bus.send('navigation/showDirectionsFromTo', { endpointIds: allSearchEndpoints(searchInputs) })
}

const _create = (widgetState) => function create (app, config) {
  const isLayerShowing = async (id) =>
    app.bus
      .send('layers/isShowing', { id })
      ?.then((res) => res.length > 0 && res[0]) ?? false // layerManager is not loaded for SDK

  const init = () => {
    app.bus
      .get('theme/getVenueLogo')
      .then((logoUrl) => widgetState.update({ logoUrl }))

    const handleWaypointsInput = (waypoints, minimumLen = 2) => { // if we enable bluedot then allow a min len of 1
      if (Array.isArray(waypoints) && waypoints.length >= minimumLen) return waypoints
      try {
        const parsedWaypoints = JSON.parse(waypoints)
        if (!Array.isArray(parsedWaypoints)) throw new Error('Deep linked waypoints not an array')
        if (parsedWaypoints.length < 2) throw new Error('Did not provide multiple waypoints')
        return parsedWaypoints
      } catch (error) { return [] }
    }

    const dlp = config.deepLinkProps
    if (dlp) {
      app.bus.on('map/mapReadyToShow', runOnce(() => {
        const requiresAccessibility = dlp.accessible === true || dlp.accessible === 'true'
        const multipointRouting = dlp.multipointRouting === true || dlp.multipointRouting === 'true'
        if (dlp.waypoints && dlp.multipointRouting) {
          const parsedWaypoints = handleWaypointsInput(dlp.waypoints).slice(0, 7)
          if (dlp.showNav && dlp.showNav === 'true') {
            app.bus.send('navigation/searchDirectionsToId', { waypoints: parsedWaypoints, requiresAccessibility, referrer: 'prog' })
          } else {
            app.bus.send('navigation/showDirectionsFromTo', { endpointIds: parsedWaypoints, requiresAccessibility, multipointRouting: multipointRouting, referrer: 'prog' })
          }
        } else if (dlp.navFrom || dlp.navTo) {
          if (dlp.navFrom && dlp.navTo)
            app.bus.send('navigation/showDirectionsFromTo', { endpointIds: [dlp.navFrom, dlp.navTo], requiresAccessibility, referrer: 'prog' })
          else {
            if (dlp.navFrom)
              app.bus.send('navigation/searchDirectionsFromId', { poiId: dlp.navFrom, requiresAccessibility, referrer: 'prog' })
            if (dlp.navTo)
              app.bus.send('navigation/searchDirectionsToId', { poiId: dlp.navTo, requiresAccessibility, referrer: 'prog' })
          }
          if (dlp.ho !== undefined) // indicates a "handoff" - link from another app. Used by MapsOnDigitalDisplay QR Codes, maybe others...
            sendHandoffEvent(app, dlp.navFrom, dlp.navTo, requiresAccessibility)
        } else if (dlp.showNav) {
          if (dlp.showNav === 'true')
            app.bus.send('navigation/searchDirectionsToId', { poiId: dlp.poiId, requiresAccessibility, referrer: 'prog' })
          else if (dlp.poiId)
            app.bus.send('navigation/showDirectionsFromTo', { endpointIds: [dlp.showNav, dlp.poiId], requiresAccessibility, referrer: 'prog' })
          else app.bus.send('navigation/searchDirectionsFromId', { poiId: dlp.showNav, referrer: 'prog' })
        }
      }))
    }

    const T = app.gt()
    const displayUnitLabels = { // when showing meters, menu label provides option to show yards (& vice versa)
      [DISPLAY_UNITS_METERS]: T('getDirectionsFromTo:Display Distance in Yards'),
      [DISPLAY_UNITS_YARDS]: T('getDirectionsFromTo:Display Distance in Meters')
    }

    app.bus.send('userMenu/addItem', {
      label: displayUnitLabels[preferredUnits],
      iconId: 'measure',
      id: 'displayUnits',
      onClick: () => {
        const { preferredUnits } = widgetState.getState()
        const newPreferredUnits = preferredUnits === DISPLAY_UNITS_METERS ? DISPLAY_UNITS_YARDS : DISPLAY_UNITS_METERS
        app.bus.send('appInsights/log', { name: 'toggle display units', properties: { preferredUnits } })
        widgetState.update({
          preferredUnits: newPreferredUnits
        })
        return {
          label: displayUnitLabels[newPreferredUnits]
        }
      }
    })
  }

  // default to showing "meters" as distance unit, but allow "yards" to be configured to be default as well
  const preferredUnits = config.displayUnits?.toLowerCase() === 'yards'
    ? DISPLAY_UNITS_YARDS
    : DISPLAY_UNITS_METERS

  // set modal visibility on widget state
  isLayerShowing('bluedot/altDialog').then((modalIsShowing) => { widgetState.update({ modalIsShowing }) })

  const { Loading } = app.themePack
  widgetState.update({
    ...getDefaultState(),
    defaultSearch: [],
    logoUrl: null,
    preferredUnits,
    multipointRouting: config?.multipointRouting || config?.deepLinkProps?.multipointRouting || false
  })

  let levelPopupTimeout = null

  const {
    displayDirections,
    animateToPoint,
    toggleIsNavigating,
    getInputNewData,
    ...showDirectionsFromToTestMethods
  } = showDirectionsFromToCreate(app, config, widgetState)

  app.bus.send('layers/register', {
    id: DIRECTIONS_SEARCH_VIEW_ID,
    widget: () => (
      <DirectionsSearchView
        widgetState={widgetState}
        isDesktop={app.env.isDesktop}
        Loading={Loading}
        handleChooseMapLocationClicked={handleChooseMapLocationClicked}
        handlePoiClicked={setInputChosenPoi}
        handleEscapeKey={onBackButtonClicked}
        T={app.gt()}
      />
    ),
    layoutId: 'content',
    layoutName: 'fullscreen'
  })

  const handleChooseMapLocationClicked = () =>
    toggleSearchResults({ toggleState: false })

  const onBackButtonClicked = () => {
    app.bus.send('headerOnline/show')
    app.bus.send('search/customAction', { name: null, category: null })
    widgetState.update({ ...getDefaultState() })
    resetSearchInputs()
  }
  app.bus.send('layers/register', {
    id: DIRECTIONS_SEARCH_CONTROLS_ID,
    widget: () => (
      <DirectionsSearchControls
        handleInputSelect={setCurrentInputIndex}
        onInputClearButtonClick={handleSearchControlsInputClearButtonClick}
        handleSwitchInputsButtonClicked={switchDirections}
        handleKeyPress={handleKeyPress}
        widgetState={widgetState}
        logoUrl={widgetState.getState().logoUrl}
        logoLinkUrl={app.config.logoLinkUrl}
        clientName={app.config.name}
        isDesktop={() => app.env.isDesktop()}
        handleInputValueChange={handleInputValueChange}
        onBackButtonClicked={onBackButtonClicked}
        handleSearchInputsChange={handleSearchInputsChange}
        multipointRouting={
          config?.multipointRouting ||
            config?.deepLinkProps?.multipointRouting ||
            false}
        T={app.gt()}
      />
    ),
    layoutId: 'headerOnline'
  })

  const handleSearchInputsChange = (newSearchInputs) => {
    widgetState.update({ searchInputs: [...newSearchInputs] })
  }

  const handleSearchControlsInputClearButtonClick = (index = null) => {
    resetUnavailableRouteInfo()
    const multipointRouting = config?.multipointRouting || config?.deepLinkProps?.multipointRouting

    if (multipointRouting || index !== null) {
      resetInputData({ index })
    } else {
      resetInputData({ index: FROM_SEARCH_FIELD_ID })
      resetInputData({ index: TO_SEARCH_FIELD_ID })
    }
  }

  const handleKeyPress = (event, term, index) => {
    if (event.key === 'Enter') {
      widgetState.update({ currentInputIndex: index }) // set the currentIndex when we click enter in the input field
      if (term.length >= 3) {
        const { multipointRouting } = widgetState.getState()
        performSearch(term, true, multipointRouting)
      }
    }
  }

  const getSuggestionsDebounced = debounce(DEBOUNCE_TIME, performSearch)

  function handleInputValueChange (term) {
    resetUnavailableRouteInfo()
    setInputData({ term })
    getSuggestionsDebounced(term)
  }

  const resetUnavailableRouteInfo = () => {
    const { isRouteUnavailable } = widgetState.getState()
    if (isRouteUnavailable) widgetState.update({ isRouteUnavailable: false })
  }

  app.bus.send('layers/register', {
    id: DIRECTIONS_RESULT_CONTROLS_ID,
    widget: () => (
      <DirectionsResultControls
        onBackFromNavigationClicked={toggleIsNavigating}
        onBackButtonClicked={onResultControlBackButtonClicked}
        onBackToSearchButtonClicked={onBackToSearchButtonClicked}
        onSwitchDirectionsButtonClicked={switchDirections}
        onSwitchRouteTypeButtonClicked={switchRouteType}
        onInputClearButtonClick={handleInputCloseButtonClick }
        handleChange={handleChange}
        widgetState={widgetState}
        logoUrl={widgetState.getState().logoUrl}
        logoLinkUrl={app.config.logoLinkUrl}
        clientName={app.config.name}
        isBackButtonDisabled={config.isBackButtonDisabled}
        isDesktop={() => app.env.isDesktop()}
        handleInputSelect={handleInputSelect}
        preferredUnits={DISPLAY_UNITS_METERS}
        handleAddStopSelect={handleAddStopSelect}
        T={app.gt()}
        multipointRouting={
          config?.multipointRouting ||
            config?.deepLinkProps?.multipointRouting ||
            false
        }
        handleSearchInputsChange={handleSearchInputsChange}
      />
    ),
    layoutId: 'headerOnline'
  })

  const switchDirections = () => {
    const { searchInputs, currentInputIndex } = widgetState.getState()
    const updatedSearchInputs = [
      { ...searchInputs[TO_SEARCH_FIELD_ID] },
      { ...searchInputs[FROM_SEARCH_FIELD_ID] }
    ]
    const nextFocusedInput = getNextEmptySearchFieldIndex(searchInputs, currentInputIndex) // switch between focused inputs just returns the other field Id right now.....

    const { endpoints, endpointIds } = widgetState.getState()
    widgetState.update({
      endpointIds: endpointIds.reverse(),
      endpoints: endpoints.reverse(),
      searchInputs: updatedSearchInputs,
      currentInputIndex: nextFocusedInput
    })
  }

  const switchRouteType = async (routeType) => {
    widgetState.update({ routeAccessibiltyChoice: routeType })
    const { endpoints } = widgetState.getState()
    setDefaultRouteAccessibilityChoice(routeType)
    return displayDirections({ endpoints, wasRoutePreviouslyDisplayed: true })
  }

  // When back button is clicked in searchResultControls
  const onResultControlBackButtonClicked = async () => {
    app.bus.send('headerOnline/show')
    app.bus.send('search/customAction', { name: null, category: null })
    app.bus.send('layers/hideMultiple', [DIRECTIONS_RESULT_VIEW_ID_DESKTOP, DIRECTIONS_RESULT_VIEW_ID_MOBILE])
    app.bus.send('deepLinking/notifyState', {
      id: DIRECTIONS_SEARCH_VIEW_ID,
      widgetState: undefined
    })

    // There is a bug here that needs to be fixed. If both of the below are not here/awaited,
    // in both noral blueDot mode, and pinnedLocation the nav lines are not removed from the map
    // when the back botton from direction result controls is clicked.
    await resetSearchInputs()
    await app.bus.send('map/cleanMap')
  }

  const handleChange = (term) => {
    navigateBack()

    app.bus.send('online/handleSearchInputChange', { term })
  }
  const handleInputSelect = (id) => {
    widgetState.update({ currentInputIndex: id })
  }

  const handleAddStopSelect = (id) => {
    navigateBack()

    app.bus.send('online/setCurrentSearchInput', { id })
  }

  const handleInputCloseButtonClick = (id) => {
    navigateBack()

    app.bus.send('online/resetSearchInputData', { index: id })
  }

  const navigateBack = () => {
    app.bus.send('layers/hideMultiple', [DIRECTIONS_RESULT_CONTROLS_ID, DIRECTIONS_RESULT_VIEW_ID_DESKTOP, DIRECTIONS_RESULT_VIEW_ID_MOBILE])
    app.bus.send('navigation/show', { reset: false })
    app.bus.send('deepLinking/notifyState', {
      id: DIRECTIONS_RESULT_CONTROLS_ID,
      widgetState: undefined
    })
  }

  const onBackToSearchButtonClicked = () => {
    app.bus.send('layers/hideMultiple', [DIRECTIONS_RESULT_VIEW_ID_DESKTOP, DIRECTIONS_RESULT_VIEW_ID_MOBILE])
    app.bus.send('navigation/show', { reset: false })
  }

  async function performSearch (
    term,
    isSearchConfirmed = false,
    multipoint = false
  ) {
    let searchResults = { placesNearby: undefined, pois: [] }
    if (term) {
      const previousLocation = multipoint
        ? getPreviousStop()
        : await getLocation(app.bus)
      const nextLocation = multipoint ? getNextStop() : null

      const poisByTerm = await getPoisByTerm(app.bus, term, previousLocation, nextLocation, multipoint)
      const navigablePois = poisByTerm.filter(poi => poi.isNavigable)
      const pois = await getPoisWithTransitTimes(app.bus, navigablePois, previousLocation, nextLocation, multipoint)

      const placesNearby = pois.slice(0, MAX_UI_RESULTS_LENGTH)
      searchResults = { placesNearby, pois }
      setSearchSideEffects(pois)

      if (isSearchConfirmed) adjustBoundsToPois(pois, app)
    } else {
      setSearchSideEffects(null)
    }

    const { searchInputs, currentInputIndex } = widgetState.getState()
    const updatedSearchInputs = [...searchInputs]
    updatedSearchInputs[currentInputIndex] = {
      ...updatedSearchInputs[currentInputIndex],
      ...searchResults,
      isSearchConfirmed
    }
    widgetState.update({ searchInputs: updatedSearchInputs })
  }

  // When user moves the map, re-sort the current search results, if any
  app.bus.on('map/moveEnd', async () => {
    const { searchInputs, currentInputIndex } = widgetState.getState()
    if (searchInputs[currentInputIndex]?.pois) {
      const updatedInputs = [...searchInputs]
      const pois = updatedInputs[currentInputIndex].pois
      const previousLocation = await getLocation(app.bus)
      const sortedPois = await getPoisWithTransitTimes(
        app.bus,
        pois,
        previousLocation
      )
      updatedInputs[currentInputIndex].pois = sortedPois
      updatedInputs[currentInputIndex].placesNearby = sortedPois.slice(
        0,
        MAX_UI_RESULTS_LENGTH
      )
      widgetState.update({ searchInputs: updatedInputs })
    }
  })

  const setSearchSideEffects = (pois) => {
    if (R.length(pois)) {
      app.bus.send('map/selectEntities', { ids: R.pluck('poiId', pois) }) // display map markers
      app.bus.send('searchResults/showPOIs', { pois })
    } else {
      app.bus.send('map/cleanMap')
      app.bus.send('searchResults/showPOIs', { pois: [] })
    }
  }

  const getPreviousStop = () => {
    const { searchInputs, currentInputIndex } = widgetState.getState()
    const previousIndex = currentInputIndex - 1
    return (previousIndex >= 0 && searchInputs[previousIndex]?.chosenPoi) ? searchInputs[previousIndex].chosenPoi : null
  }

  function getNextStop () {
    const { searchInputs, currentInputIndex } = widgetState.getState()
    const nextIndex = currentInputIndex + 1
    return (nextIndex < searchInputs.length && searchInputs[nextIndex]?.chosenPoi) ? searchInputs[nextIndex].chosenPoi : null
  }

  const notifyState = () => {
    const { searchInputs: inputs, multipointRouting, routeAccessibiltyChoice } = widgetState.getState()
    const sendState = {
      id: 'online/getDirectionsFromTo',
      multipointRouting: multipointRouting,
      accessible: routeAccessibiltyChoice === 'accessible'
    }
    // if we have multipoint and > 2 endpoints
    if (inputs.length > 2 && allEndPointsDefined(inputs.slice(1)) && multipointRouting) {
      if (inputs[0] === null || inputs[0] === undefined || inputs[0]?.location?.title === 'My Location') { // no start poi or bluedot type poi means showNav
        const endpoints = allSearchEndpoints(inputs.slice(1))
        sendState.waypoints = endpoints
        sendState.showNav = 'true'
      } else {
        const endpoints = allSearchEndpoints(inputs)
        sendState.waypoints = endpoints
      }
    } else if (inputs && inputs[1] && inputs[1].chosenPoi) { // if we have a destination POI
      if (inputs[0] && inputs[0].chosenPoi) { // and a starting POI
        sendState.navFrom = inputs[0].chosenPoi.poiId
        sendState.navTo = inputs[1].chosenPoi.poiId
      } else { // no start POI
        sendState.showNav = 'true'
        sendState.poiId = inputs[1].chosenPoi.poiId
      }
    } else { // if no POI endpoints defined, no state needed (so clear any existing state)
      delete sendState.accessible
    }
    app.bus.send('deepLinking/notifyState', sendState)
  }
  widgetState.addCallback(notifyState)

  let oldProm = Zousan.resolve()
  const showMarkersIfNeeded = (state) => {
    oldProm = oldProm.then(() => showMarkersAsync(state))
  }
  const arrayChange = changeTestForArray()
  const visibilityChange = changeTest({})
  const currentInputIndexChange = changeTest({})

  const showMarkersAsync = async (state) => {
    const isVisible = await isGetDirectionsVisible()
    const visibilityChanged = visibilityChange(isVisible)
    const inputIndexChanged = currentInputIndexChange(state.currentInputIndex)

    if (!isVisible) {
      return Promise.all([hideStartMarker(), hideAllEndMarkers()])
    }

    const inputs = state.searchInputs
    const rawEndpoints = inputs.map((input) => poiOrLocation(input))
    const endpointsChanged = arrayChange(rawEndpoints)
    if (!endpointsChanged && !visibilityChanged && !inputIndexChanged) {
      return
    }

    const resolvedEndpoints = await Promise.all(
      rawEndpoints.map((ep) =>
        ep && app.bus.get('wayfinder/getNavigationEndpoint', { ep })
      )
    )

    // animate the map only if the set of endpoints is incomplete
    const endpointsAreComplete = R.all((e) => !!e)(resolvedEndpoints)
    const markerPromises = resolvedEndpoints.map((endpoint, index) => {
      if (index === 0) {
        if (!endpoint) {
          return Promise.all([hideStartMarker()])
        }

        return Promise.all([
          addStartMarker(endpoint),
          !endpointsAreComplete && animateToPoint(endpoint.lat, endpoint.lng, endpoint.floorId)
        ])
      } else {
        if (!endpoint) {
          return Promise.all([hideEndMarker(index)])
        }
        return Promise.all([
          addEndMarker(endpoint, index),
          !endpointsAreComplete && animateToPoint(endpoint.lat, endpoint.lng, endpoint.floorId)
        ])
      }
    })

    return Promise.all(markerPromises).then(() => {
      if (resolvedEndpoints.every(ep => !ep)) {
        return Zousan.all([hideStartMarker(), hideAllEndMarkers()])
      }
    })
  }

  const widgets = [
    DIRECTIONS_RESULT_VIEW_ID_DESKTOP,
    DIRECTIONS_RESULT_VIEW_ID_MOBILE,
    DIRECTIONS_RESULT_CONTROLS_ID,
    DIRECTIONS_SEARCH_CONTROLS_ID,
    DIRECTIONS_SEARCH_VIEW_ID
  ]
  const isGetDirectionsVisible = () =>
    Zousan.all(widgets.map((id) => isLayerShowing(id))).then(
      R.any(R.equals(true))
    )

  widgetState.addCallback(showMarkersIfNeeded)

  const showNavigation = async ({ reset = true, referrer }) => {
    // By default reset the state when showing this plugin
    if (reset) {
      widgetState.update({ ...getDefaultState() })
      await resetInputData({
        index: FROM_SEARCH_FIELD_ID,
        usePhysicalLocation: true
      })
      if (
        Object.keys(widgetState.getState().searchInputs[FROM_SEARCH_FIELD_ID])
          .length
      )
      // if first search input has any data (e.g. from bluedot) switch active input to TO
        widgetState.update({ currentInputIndex: TO_SEARCH_FIELD_ID })
    }
    if (referrer) widgetState.update({ referrer }) // used in analytics

    const isDesktop = app.env.isDesktop()

    resetUnavailableRouteInfo()
    const { defaultSearch } = widgetState.getState()
    if (!defaultSearch.length)
      await app.bus
        .get('search/getDefaultSearchPois', { limit: 6 })
        .then((defaultSearch) => widgetState.update({ defaultSearch }))
    app.bus.send('map/resetNavlineFeatures')
    app.bus.send('layers/show', { id: DIRECTIONS_SEARCH_CONTROLS_ID })
    app.bus.send('layers/show', { id: DIRECTIONS_SEARCH_VIEW_ID })
    app.bus.send('history/register', {
      viewId: isDesktop ? DIRECTIONS_RESULT_VIEW_ID_DESKTOP : DIRECTIONS_RESULT_VIEW_ID_MOBILE,
      event: 'navigation/show',
      params: { reset }
    })
  }

  app.bus.on('navigation/show', showNavigation)
  app.bus.on('online/handleSearchInputChange', ({ term }) => {
    handleInputValueChange(term)
  })

  app.bus.on('online/setCurrentSearchInput', ({ id }) => {
    setCurrentInputIndex(id) // But this is really an index
  })

  app.bus.on('online/resetSearchInputData', ({ index, usePhysicalLocation }) => {
    resetInputData({ index, usePhysicalLocation })
  })

  const addStartMarker = async (endpoint) => {
    // If app.bus.on('user/physicalLocation') has returned a pinnedLocation, don't add the normal start marker
    if (endpoint.isPinned) return null
    const markerComponent = (
      <Icon id="wayfinding.start" width={22} height={54} />
    )
    const { floorName, structureName } = await getMarkerStructurePosition(
      endpoint
    )
    const T = app.gt()
    const text = T(
      'getDirectionsFromTo:Start at _structureName_ on _floorName_',
      { structureName, floorName }
    )
    const ghostMarkerComponent = (
      <GhostMarker>
        <Icon id="wayfinding.start.ghost" width={22} height={54} />
        <span>{text}</span>
      </GhostMarker>
    )
    app.bus.send('map/addGhostedMarker', {
      id: 'startMarker',
      ordinal: endpoint.ordinal,
      lat: endpoint.lat,
      lng: endpoint.lng,
      markerComponent,
      ghostMarkerComponent,
      ghostMarkerOptions: { anchor: 'bottom-left', offset: [-17, 0] }
    })
  }

  const addEndMarker = async (endpoint, index) => {
    const markerComponent = <Icon id="wayfinding.end" width={34} height={47} />
    const { floorName, structureName } = await getMarkerStructurePosition(endpoint)
    const T = app.gt()
    const text = T('getDirectionsFromTo:Arrive at _structureName_ on _floorName_', { structureName, floorName })
    const ghostMarkerComponent = (
      <GhostMarker>
        <Icon id="wayfinding.end.ghost" width={34} height={47} />
        <span>{text}</span>
      </GhostMarker>
    )
    const markerId = `endMarker-${index}`
    app.bus.send('map/addGhostedMarker', {
      id: markerId,
      ordinal: endpoint.ordinal,
      lat: endpoint.lat,
      lng: endpoint.lng,
      markerComponent,
      ghostMarkerComponent,
      ghostMarkerOptions: { anchor: 'bottom-left', offset: [-25, 0] }
    })
  }

  const getMarkerStructurePosition = async (endpoint) => {
    const { lat, lng, floorId, ordinal: originalOrdinal } = endpoint
    const translatedFloorId = await app.bus.get(
      'venueData/getTranslatedFloorId',
      { floorId }
    )
    const { floorName, structureName } = await app.bus.get(
      'venueData/getFloorIdName',
      { floorId: translatedFloorId }
    )
    const ordinalFilter = (ordinal) => ordinal !== originalOrdinal

    return { lat, lng, floorName, structureName, ordinalFilter }
  }

  const hideStartMarker = () =>
    app.bus.send('map/removeMarker', { id: 'startMarker' })

  const hideAllEndMarkers = () => {
    for (let i = 1; i < MAX_SEARCH_INPUT_LENGTH; i++) { // start from 1, because the first is the start marker
      app.bus.send('map/removeMarker', { id: `endMarker-${i}` })
    }
  }

  const hideEndMarker = (index) => {
    const markerId = `endMarker-${index}`
    app.bus.send('map/removeMarker', { id: markerId })
  }

  // HEADER
  app.bus.on('venueData/mapDataLoaded', ({ structures: buildings }) => {
    widgetState.update({ buildings })
  })

  const setLevelPopupTimeout = (newLevelPopupTimeout) => {
    if (levelPopupTimeout) clearTimeout(levelPopupTimeout)
    widgetState.update({ showLevelName: false })
    levelPopupTimeout = newLevelPopupTimeout
  }

  app.bus.on('map/floorChanged', ({ structure, floor }) => {
    const { isNavigating, currentLevelName } = widgetState.getState()

    if (!isNavigating) return

    if (!floor) return widgetState.update({ currentLevelName: null })

    const newLevelName = floor.name

    if (currentLevelName !== newLevelName) {
      const newLevelPopupTimeout = setTimeout(() => {
        widgetState.update({ showLevelName: false })
      }, (ANIMATION_TIME + ANIMATION_DELAY) * 1000)

      setLevelPopupTimeout(newLevelPopupTimeout)

      widgetState.update({
        currentLevelName: newLevelName,
        showLevelName: true
      })
    }
  })

  const setCurrentInputIndex = (index) => {
    const { searchInputs, currentInputIndex } = widgetState.getState()
    toggleSearchResults({ toggleState: true })

    if (currentInputIndex !== index) {
      // take action only if focused other input than previously
      widgetState.update({ currentInputIndex: index })
      const pois = searchInputs[index]?.pois || []
      setSearchSideEffects(pois)
    }
  }

  const resetSearchInputs = async () => {
    const { searchInputs } = widgetState.getState()
    for (let i = 0; i < searchInputs.length; ++i) {
      if (i === 0)
        await resetInputData({ index: i, usePhysicalLocation: true })
      else
        await resetInputData({ index: i })
    }
    const { searchInputs: clearedInputs } = widgetState.getState()
    widgetState.update({ searchInputs: clearedInputs.slice(0, 2) })
  }
  app.bus.on('app/reset', resetSearchInputs)

  const setInputData = (data) => {
    const { searchInputs, currentInputIndex } = widgetState.getState()
    const updatedSearchInputs = [...searchInputs]
    updatedSearchInputs[currentInputIndex] = {
      ...updatedSearchInputs[currentInputIndex],
      ...data
    }
    updatedSearchInputs[currentInputIndex].chosenPoi = null
    widgetState.update({ searchInputs: [...updatedSearchInputs] })
  }

  const setInputChosenPoi = (chosenPoi) => {
    const { searchInputs, currentInputIndex } = widgetState.getState()

    const updatedSearchInputs = [...searchInputs]
    updatedSearchInputs[currentInputIndex] = {
      ...updatedSearchInputs[currentInputIndex],
      chosenPoi
    }
    updatedSearchInputs[currentInputIndex].term = chosenPoi.name
    widgetState.update({
      searchInputs: [...updatedSearchInputs],
      currentInputIndex: getNextEmptySearchFieldIndex(updatedSearchInputs, currentInputIndex)
    })
    toggleSearchResults({ toggleState: true })
    app.bus.send('map/cleanMap')
  }

  const toggleSearchResults = ({ toggleState = true }) => {
    if (toggleState) {
      return app.bus.send('layers/show', { id: DIRECTIONS_SEARCH_VIEW_ID })
    } else {
      return app.bus.send('layers/hide', { id: DIRECTIONS_SEARCH_VIEW_ID })
    }
  }

  // Updates the physical location of the user. If we are showing navigation and the starting point
  // is the user's location, update the navigation to reflect the user's new position
  async function updatePhysicalLocation (latitude, longitude, floorId) {
    const isDesktop = app.env.isDesktop()
    // note: floorId may be null/undefined
    const isNavigationShowing = await isLayerShowing(isDesktop ? DIRECTIONS_RESULT_VIEW_ID_DESKTOP : DIRECTIONS_RESULT_VIEW_ID_MOBILE)
    if (isNavigationShowing && floorId) {
      const { searchInputs, routeAccessibiltyChoice } = widgetState.getState()
      if (
        searchInputs &&
          searchInputs[FROM_SEARCH_FIELD_ID] &&
          searchInputs[FROM_SEARCH_FIELD_ID].location
      ) {
        searchInputs[FROM_SEARCH_FIELD_ID] = {
          ...searchInputs[FROM_SEARCH_FIELD_ID],
          location: {
            title: searchInputs[FROM_SEARCH_FIELD_ID].term,
            latitude,
            longitude,
            floorId
          }
        }
        widgetState.update({ searchInputs })
        if (allEndPointsDefined(searchInputs))
          app.bus.send('navigation/showDirectionsFromTo', { endpointIds: allSearchEndpoints(searchInputs), requiresAccessibility: routeAccessibiltyChoice, doNotMoveMap: true })
      }
    }
  }

  const MIN_RENAV_METERS = 5 // minimum number of meters the user's physical location should change before we reroute navigation
  const updatePhysicalLocationThrottled = throttle(updatePhysicalLocation, 20) // every 2 seconds is plenty fast
  const maybeUpdatePhysicalLocation = (() => {
    let lastUpdate = {}
    return (lat, lng, floorId) => {
      // floorId may be null/undefined
      if (
        !lastUpdate ||
          floorId !== lastUpdate.floorId ||
          distance(lat, lng, lastUpdate.lat, lastUpdate.lng) > MIN_RENAV_METERS
      ) {
        updatePhysicalLocationThrottled(lat, lng, floorId)
        lastUpdate = { lat, lng, floorId }
      }
    }
  })()

  app.bus.on('user/physicalLocation',
    (
      { latitude, longitude, floorId } // Note: floorId may be null/undefined
    ) => maybeUpdatePhysicalLocation(latitude, longitude, floorId)
  )

  const searchDirectionsToId = async (poiId, waypoints) => {
    let inputData = {}
    let currentInputIndex = FROM_SEARCH_FIELD_ID

    await resetInputData({
      index: FROM_SEARCH_FIELD_ID,
      usePhysicalLocation: true
    })

    const { searchInputs } = widgetState.getState()
    let updatedSearchInputs
    if (waypoints) {
      try {
        const navEndpoints = await Promise.all(waypoints.map(async id => await getInputNewData(id)))
        updatedSearchInputs = [searchInputs[0], ...navEndpoints]
      } catch (error) {
        updatedSearchInputs = [...searchInputs]
      }
    } else {
      const poi = await app.bus
        .get('poi/getById', { id: poiId })
        .catch((error) => console.error(error))

      if (poi && poi.poiId)
      // if the poi object was sent update input with real poi data
        inputData = { chosenPoi: poi, term: poi.name }
      else {
        inputData = { chosenPoi: null, term: null }
        currentInputIndex = TO_SEARCH_FIELD_ID
      }

      updatedSearchInputs = [...searchInputs]
      updatedSearchInputs[TO_SEARCH_FIELD_ID] = {
        ...updatedSearchInputs[TO_SEARCH_FIELD_ID],
        ...inputData
      }
    }
    return { updatedSearchInputs, currentInputIndex }
  }

  const addNewPOIStop = async (poiId, searchInputs) => {
    const poi = await app.bus
      .get('poi/getById', { id: poiId })
      .catch((error) => console.error(error))

    if (poi && poi.poiId) { // if the poi object was sent update input with real poi data
      // if last input is blank then overwrite it
      if (R.isEmpty(searchInputs[searchInputs.length - 1])) return [...searchInputs.slice(0, -1), { chosenPoi: poi, term: poi.name }]
      else if (searchInputs.length < 7) return [...searchInputs, { chosenPoi: poi, term: poi.name }]
    } else return searchInputs
  }

  app.bus.on('navigation/searchDirectionsToId', async ({ poiId, waypoints, referrer, keepExistingStops }) => {
    const { searchInputs, multipointRouting } = widgetState.getState()
    let { currentInputIndex } = widgetState.getState()
    let updatedSearchInputs

    if (searchInputs.length === 7) return

    if (keepExistingStops && multipointRouting) {
      updatedSearchInputs = await addNewPOIStop(poiId, searchInputs)
      currentInputIndex = getNextEmptySearchFieldIndex(updatedSearchInputs, currentInputIndex)
    } else {
      ({ updatedSearchInputs, currentInputIndex } = await searchDirectionsToId(poiId, waypoints))
    }

    widgetState.update({
      searchInputs: updatedSearchInputs,
      currentInputIndex: currentInputIndex,
      referrer
    })

    app.bus.send('navigation/show', { reset: false })
  })

  app.bus.on('navigation/searchDirectionsFromId',
    async ({ poiId, referrer }) => {
      resetInputData({ index: FROM_SEARCH_FIELD_ID, usePhysicalLocation: false })
      resetInputData({ index: TO_SEARCH_FIELD_ID })

      const poi = await app.bus
        .get('poi/getById', { id: poiId })
        .catch((error) => console.error(error))
      const inputData = {
        chosenPoi: poi,
        term: R.prop('name', poi)
      }

      const { searchInputs } = widgetState.getState()
      const newInputs = [...searchInputs]
      newInputs[FROM_SEARCH_FIELD_ID] = R.mergeRight(
        searchInputs[FROM_SEARCH_FIELD_ID],
        inputData
      )
      // newInputs[FROM_SEARCH_FIELD_ID] = {...searchInputs[FROM_SEARCH_FIELD_ID], ...inputData}
      widgetState.update({
        searchInputs: newInputs,
        currentInputIndex: TO_SEARCH_FIELD_ID,
        referrer
      })
      app.bus.send('navigation/show', { reset: false })
    }
  )

  // The id param below is actual the index of the input field
  const resetInputData = async ({ index = null, usePhysicalLocation = false }) => {
    const { searchInputs, currentInputIndex } = widgetState.getState()
    const inputIndex = index !== null ? index : currentInputIndex
    const updatedSearchInputs = [...searchInputs]
    updatedSearchInputs[inputIndex] = {}
    if (usePhysicalLocation) {
      // if there is physical location available set current input with it
      const location = await app.bus.getFirst('user/getPhysicalLocation')
      if (location) {
        if (!location.floorId) {
          const floor = await app.bus.get('map/getFloorAt', location) // if we don't have a floorId, use current level
          location.floorId = floor ? floor.id : null // TODO: this isn't right!
        }
        updatedSearchInputs[inputIndex] = { term: location.title, location }
      }
    }

    widgetState.update({
      searchInputs: updatedSearchInputs,
      currentInputIndex: inputIndex
    })

    app.bus.send('map/cleanMap')
    app.bus.send('searchResults/showPOIs', { pois: [] })
  }

  app.bus.on('navigation/getSearchInputsLength', () => {
    const { searchInputs } = widgetState.getState()
    return definedEndpointCount(searchInputs)
  })

  app.bus.on('map/poiClicked', async ({ poi }) => {
    const isNavigationSearchView = await isLayerShowing(
      'DirectionsSearchControls'
    )
    if (isNavigationSearchView && poi.isNavigable) setInputChosenPoi(poi)
  })

  const runTest = async (initialState, testRoutine) => {
    widgetState.update(getDefaultState())
    widgetState.update(initialState)
    await testRoutine()
    const resultState = widgetState.getState()
    return resultState
  }

  return {
    init,
    getState: () => widgetState.getState(),
    runTest,
    internal: {
      handleInputValueChange,
      toggleIsNavigating,
      ...showDirectionsFromToTestMethods,
      setCurrentInputIndex,
      toggleSearchResults,
      switchRouteType,
      performSearch,
      setInputData,
      switchDirections,
      resetInputData,
      setInputChosenPoi,
      getPreviousStop,
      getNextStop,
      setShowDirectionsCallback: callShowDirectionsIfEndpointsDefined,
      notifyState,
      resetSearchInputs,
      showMarkersAsync,
      updatePhysicalLocation,
      onResultControlBackButtonClicked,
      addStartMarker
    }
  }
}

const create = (app, ...args) => {
  const state = new StateObjMultiple()

  const createFn = _create(state)(app, ...args)

  state.addCallbackImmutableUpdates({ fn: maybeShowDirectionsFromToAndCenter(app, state), id: SHOW_DIRECTIONS_FROM_TO_CALLBACK_ID })

  return createFn
}
export { create, _create }
