import Logo from '@locus-labs/mol-desktop-logo'
import React from 'react'
import styled, { withTheme } from 'styled-components'

import { ColoredIcon } from '../../../../../src/ui/icons/Icon.jsx'
import SearchInputLabelled from '../../../../../src/ui/inputs/SearchInputLabelled.js'
import ToolTipComponent from '../../../../../src/ui/tooltips/ToolTipComponent.jsx'
import { handleKeyboardSelect } from '../../../../utils/keyboardUtils.js'
import { FROM_SEARCH_FIELD_ID, TO_SEARCH_FIELD_ID } from '../constants.js'
import { TopButtons } from '../DirectionsResultControls/styles.js'

const LogoWrapper = styled.div`
  padding: 0 0 20px 0;
`

const WidgetWrapper = styled.div`
  padding: ${({ hasLogoUrl, theme }) =>
  hasLogoUrl && theme.header.hasLogo
    ? theme.header.paddingWithLogo
    : theme.header.padding};
`

const DirectionsSearchControlsView = ({
  currentInputIndex,
  searchInputs,
  logoUrl,
  clientName,
  theme,
  onInputSelect,
  onInputCloseButtonClick,
  onInputChange,
  onBackButtonClicked,
  onSwitchInputsButtonClicked,
  handleKeyPress,
  T
}) => {
  return (
    <WidgetWrapper
      data-cy={'DirectionsSearchControlsView'}
      hasLogoUrl={typeof logoUrl !== 'undefined'}
    >
      {logoUrl && theme.header.hasLogo && (
        <LogoWrapper>
          <Logo
            logoHeight={theme.logo.height}
            alt={clientName}
            url={logoUrl}
            width="auto"
            position={theme.logo.centered ? 'center' : 'left'}
            padding={theme.logo.padding}
          />
        </LogoWrapper>
      )}
      <TopButtons>
        <button
          onClick={onBackButtonClicked}
          tabIndex={'1'}
          data-cy={'DirectionsSearchControlsViewHomeButton'}
          onKeyDown={handleKeyboardSelect(() => onBackButtonClicked())}>
          <ColoredIcon
            className="icon"
            width={24}
            height={24}
            id="arrow-left"
            fillColor={theme.colors.widgetIconFill}
          />
          <u>{T('getDirectionsFromTo:Home')}</u>
        </button>

        <ToolTipComponent
          tooltipText={T(
            'getDirectionsFromTo:Swap start and end points around'
          )}
          arrowPosition="left"
        >
          <button onClick={onSwitchInputsButtonClicked} tabIndex={'2'}
            onKeyDown={handleKeyboardSelect(() => onSwitchInputsButtonClicked())}>
            <u>{T('getDirectionsFromTo:Swap')}</u>
            <ColoredIcon
              className="icon"
              width={24}
              height={24}
              id="swap"
              fillColor={theme.colors.widgetIconFill}
            />
          </button>
        </ToolTipComponent>
      </TopButtons>

      <SearchInputLabelled
        id={FROM_SEARCH_FIELD_ID}
        autoFocus={currentInputIndex === FROM_SEARCH_FIELD_ID}
        value={
          searchInputs[FROM_SEARCH_FIELD_ID] &&
          searchInputs[FROM_SEARCH_FIELD_ID].term
            ? searchInputs[FROM_SEARCH_FIELD_ID].term
            : ''
        }
        label={T('getDirectionsFromTo:From') + ':'}
        onInputChange={onInputChange}
        onClick={() => onInputSelect(FROM_SEARCH_FIELD_ID)}
        onInputCloseButtonClick={onInputCloseButtonClick}
        handleKeyPress={handleKeyPress}
        T={T}
        data-cy={'SearchInputFrom'}
      ></SearchInputLabelled>

      <SearchInputLabelled
        id={TO_SEARCH_FIELD_ID}
        autoFocus={currentInputIndex === TO_SEARCH_FIELD_ID}
        value={
          searchInputs[TO_SEARCH_FIELD_ID] &&
          searchInputs[TO_SEARCH_FIELD_ID].term
            ? searchInputs[TO_SEARCH_FIELD_ID].term
            : ''
        }
        label={T('getDirectionsFromTo:To') + ':'}
        onInputChange={onInputChange}
        onClick={() => onInputSelect(TO_SEARCH_FIELD_ID)}
        onInputCloseButtonClick={onInputCloseButtonClick}
        handleKeyPress={handleKeyPress}
        T={T}
        data-cy={'SearchInputTo'}
      ></SearchInputLabelled>
    </WidgetWrapper>
  )
}

export default withTheme(DirectionsSearchControlsView)
