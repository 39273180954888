import { rgba } from 'polished'
import { length, path } from 'ramda'
import React, { useContext } from 'react'
import styled from 'styled-components'

import { Icon } from '../../../../../src/ui/icons/Icon.jsx'
import HandlerContext from '../handlerContext.js'

const Wrapper = styled.div`
  background: ${({ theme }) => rgba(theme.colors.primary, 0.05)};
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => rgba(theme.colors.primaryText, 0.15)};
  margin-left: -12px;
  margin-right: -12px;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
  font-weight: 500;
  line-height: 18px;
  ${({ theme }) => theme.fontSize('14px')};
  text-transform: uppercase;

  align-self: center;
  margin-top: 8px;
  margin-bottom: 14px;
`

const HorizontalContainer = styled.div`
  margin-left: 11px;
  margin-right: 11px;
  margin-bottom: 21px;

  display: flex;
  flex-direction: row;
  overflow-x: auto;
`

const SecurityLane = styled.button`
  cursor: pointer;
  outline: none;

  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.secondaryButton};
  box-sizing: border-box;
  border-radius: 8px;

  width: auto;
  height: 52px;
  padding: 12px 16px;

  margin: 0 6px;

  color: ${({ theme }) => theme.colors.primaryButton};
  ${({ theme }) => theme.fontSize('16px')};
  line-height: 24px;
  font-weight: 500;

  &:hover {
    background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
  }

  &:active {
    background-color: ${({ theme }) => rgba(theme.colors.primaryText, 0.1)};
  }
`

const IconWrapper = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const OtherSecurityLanes = ({ poi, T }) => {
  const otherQueues = path(['queue', 'otherQueues'], poi)
  if (!length(otherQueues)) return false
  else return otherSecurityLanesView(otherQueues, 'Other lanes', T)
}

const otherSecurityLanesView = (securityLanes, title, T) => {
  const { onSecurityLanePoiClick } = useContext(HandlerContext)
  return <Wrapper>
    <Title>{T(`poiView:${title}`)}</Title>
    <HorizontalContainer>
      {securityLanes.map(getSecurityLaneView(onSecurityLanePoiClick))}
    </HorizontalContainer>
  </Wrapper>
}

const getSecurityLaneView = onClick => ({ poiId, displayText, imageId }) => {
  const content = imageId
    ? <IconWrapper><Icon id={imageId} height={26} /></IconWrapper>
    : displayText

  return <SecurityLane key={`security_lane_${poiId}`} onClick={() => onClick(poiId)}>
    {content}
  </SecurityLane>
}

export default OtherSecurityLanes
