import { rgba } from 'polished'
import { css } from 'styled-components'

const mapNavHoverCss = css`
  background: ${({ theme }) => rgba(theme.colors.mapNavBackground, 1)};
`

const listElementHoverCss = css`
  background: ${({ theme }) => rgba(theme.colors.primary, 0.05)};
`

export { listElementHoverCss, mapNavHoverCss }
