import * as R from 'ramda'

import { setSource } from './utils.js'

const temporaryDefaultThemes = {
  'nav.multipoint': {
    darkFillColor: '#2DD9FF',
    fillColor: '#387AFF',
    fillColorOpacity: 0.5,
    strokeAlign: 'center',
    strokeColor: '#FFFFFF',
    strokeWidth: 1
  },
  'nav.alternativemultipoint': {
    darkFillColor: '#737DAF',
    fillColor: '#8692B1',
    fillColorOpacity: 0.5,
    strokeAlign: 'center',
    strokeColor: '#8692B1',
    strokeWidth: 1
  }
}

const mergeWithProp = (prop, toAdd, o) => R.over(R.lensProp(prop), old => R.mergeRight(old || {}, toAdd), o)

const addAttributesFromTheme = (theme, feature, attributes, darkAttributes, isDarkMode) => {
  const category = R.path(['properties', 'category'], feature)
  // if the feature doesn't have a category or has an empty one (because !'' === true) - don't apply the theme
  if (!category) {
    return feature
  }
  if (!theme[category]) {
    console.warn(`Unable to find category '${category}' in theme.`)
  }
  // if the category doesn't have fillColor or strokeColor - don't apply the theme
  if (theme[category] && (!theme[category].fillColor || !theme[category].strokeColor)) {
    return feature
  }
  const themeCategory = R.mergeAll([theme.default, theme.selected, theme.desaturation, theme[category]])
  const darkModeProperties = isDarkMode
    ? R.filter(R.identity, R.zipObj(attributes, R.props(darkAttributes, themeCategory)))
    : {}

  const updates = R.mergeWith(R.or, darkModeProperties, R.pick(attributes, themeCategory))
  return mergeWithProp('properties', updates, feature)
}

const baseThemeableAttributes = ['fillColor', 'fillColorOpacity', 'haloColor', 'strokeColor', 'textColor']
const baseFeatureAttributePrefixes = ['desaturation', 'selected']

const capitalizeFirstLetter = s => s[0].toUpperCase() + s.slice(1)

const getThemeableAttributes = R.memoizeWith(R.identity, () => {
  const addPrefixes = R.pipe(
    R.map(capitalizeFirstLetter),
    R.xprod(baseFeatureAttributePrefixes),
    R.map(R.join(''))
  )
  const prefixedAttributes = addPrefixes(baseThemeableAttributes)
  return baseThemeableAttributes.concat(prefixedAttributes)
})

const getDarkThemeAttributes = R.memoizeWith(
  R.identity,
  R.map(attribute => 'dark' + capitalizeFirstLetter(attribute))
)

const updateFeatureFromTheme = (feature, theme, isDarkMode = false) => {
  const attributes = getThemeableAttributes()
  const darkAttributes = getDarkThemeAttributes(attributes)
  return addAttributesFromTheme(theme, feature, attributes, darkAttributes, isDarkMode)
}

export default function MapThemeController (app, mapInitialized) {
  let state = {
    mapTheme: null,
    isDarkThemeOn: false
  }

  const updateMapBackgroundColor = (map) => {
    const { mapTheme, isDarkThemeOn } = state
    const colorPropertyName = isDarkThemeOn ? 'darkFillColor' : 'fillColor'
    const artBoardColor = R.path(['basemap.venue', colorPropertyName], mapTheme)
    if (R.length(artBoardColor)) {
      map.setPaintProperty('background', 'background-color', artBoardColor)
    }
  }

  const augmentTheme = theme => {
    // By default, use the nav.primary.fillColor for the nav.multipoint.fillColor - just at 50% opacity
    if (theme['nav.primary']) {
      temporaryDefaultThemes['nav.multipoint'].fillColor = theme['nav.primary'].fillColor
      temporaryDefaultThemes['nav.multipoint'].darkFillColor = theme['nav.primary'].darkFillColor
    }
    // // By default, use the nav.alternative.fillColor for the nav.alternativemultipoint.fillColor - just at 50% opacity
    if (theme['nav.alternative']) {
      temporaryDefaultThemes['nav.alternativemultipoint'].fillColor = theme['nav.alternative'].fillColor
      temporaryDefaultThemes['nav.alternativemultipoint'].darkFillColor = theme['nav.alternative'].darkFillColor
    }

    return { ...temporaryDefaultThemes, ...theme }
  }

  mapInitialized().then(updateMapBackgroundColor)

  app.bus.on('venueData/mapDataLoaded', async ({ mapTheme }) => {
    mapTheme = augmentTheme(mapTheme)
    state = { ...state, mapTheme }
  })

  app.bus.on('map/getMapFeatureThemer', () => {
    return (feature) => {
      return updateFeatureFromTheme(feature, state.mapTheme, state.isDarkThemeOn)
    }
  })

  const updateTheming = () => {
    mapInitialized().then(map => {
      R.forEachObjIndexed((source, sourceId) => {
        const newSource = source.data.features.map(feature => updateFeatureFromTheme(feature, state.mapTheme, state.isDarkThemeOn))
        setSource(sourceId, newSource, map)
      }, map.getStyle().sources)
      updateMapBackgroundColor(map)
    })
  }

  app.bus.on('map/toggleDarkMode', async () => {
    state = { ...state, isDarkThemeOn: !state.isDarkThemeOn }
    updateTheming()
  })

  app.bus.on('map/replaceTheme', ({ theme }) => {
    theme = augmentTheme(theme)
    state = { ...state, mapTheme: theme }
    updateTheming()
  })

  const runTest = async (initialState, testRoutine) => {
    state = R.mergeRight(state, initialState)
    await testRoutine()
    return state
  }

  return { runTest }
}
