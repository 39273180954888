import createAPI from '../../../src/extModules/flexapi/src/index.js'

function create (app, config) {
  const api = createAPI()
  app.bus.on('clientAPI/registerCommand', ob => api.registerCommand(ob, cob => app.bus.get(`clientAPI/${cob.command}`, cob)))
  app.bus.on('clientAPI/registerCustomType', ({ name, spec }) => api.registerCustomType(name, spec))
  app.bus.on('clientAPI/execute', cob => api.execute(cob))

  return {
    init: () => {} // nothing more to do
  }
}

export {
  create
}
